import { createRouter, createWebHistory } from 'vue-router'
import { usePreferredLanguages } from '@vueuse/core'
import { useUserStore } from '../stores/useUserStore';
import { storeToRefs } from 'pinia';
import axios from 'axios';
import createI18n from './../system/i18n';
import { getMessages } from './../system/helpers/i18n';
import { useLanguageStore } from '../stores/useLanguageStore';

const i18n = createI18n();
const { locale, t, setLocaleMessage } = i18n.global;
const localesTranslation = [];
const requestLanguage = await axios.get('https://pl.driverbook.net/api/language'); // TODO API URL
const { data } = requestLanguage;


data.map(e => {
	localesTranslation.push({locale: e.code});
});
let locale_path;

const languages = usePreferredLanguages();

let path = window.location.pathname;
if (path.substr(0, 1) === "/") {
	path = path.substr(1, path.length);
}
const paths = path.split("/");
if (paths) {
	languages.value = paths;
}

Object.entries(languages.value).map(lang => {
	if (lang[1].length === 2 && localesTranslation.find(e => e.locale === lang[1])) {
		if (locale_path === undefined) {
			locale_path = lang[1];
		}
	}
});

if (locale_path === '') {
	locale_path = 'pl';
}

localStorage.setItem('_lang', locale_path);
if (localStorage.getItem('_lang') !== undefined && localStorage.getItem('_lang') !== null) {
	locale_path = localStorage.getItem('_lang');
}

if (locale_path === 'undefined' || locale_path === undefined) {
	const languages = usePreferredLanguages();
	Object.entries(languages.value).map(lang => {
		const _lang = lang[1].substr(0,2);
		if (_lang.length === 2 && localesTranslation.find(e => e.locale === _lang)) {
			if (locale_path === undefined || locale_path === 'undefined') {
				locale_path = _lang;
			}
		}
	});
}

locale.value = locale_path;
i18n.locale = locale_path;

axios.defaults.headers.common['Locale'] = locale_path;

const messages = await getMessages();
setLocaleMessage(locale.value, messages);

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: "/",
			redirect: `/${locale_path}`,
		},
		{
			path: `/:lang`,
			beforeEnter(to, from, next) {
				let lang = to.params.lang;
				let find = localesTranslation.find(i => i.locale == lang);
				if (find) {
					return next();
				}
				return next ({ path: locale_path });
			},
			component: () => import('../layout/AppLayout.vue'),
			children: [
				{
					path: "",
					name: "home",
					component: () => import('@/views/HomeView.vue')
				},
				{
					path: "regula",
					name: "regula",
					component: () => import('@/views/RegulaView.vue')
				},
				{
					path: "icons",
					name: "icons",
					component: () => import('@/views/IconsView.vue')
				},
				{
					path: "video",
					name: "videoChat",
					component: () => import('@/views/chat/VideoView.vue')
				},
				{
					path: `${t('path.ad')}/${t('path.create')}`,
     				name: 'createItem',
      				component: () => import('../views/ad/CreateView.vue'),
      				meta: {
						requiresAuth: true,
						roleAccount: 1
					},
				},
				{
					path: ":slug_category/:slug_subcategory/:slug_subcategory2/:slug_subcategory3/l-:location",
					name: "adSubcategory3location",
					component: () => import('../views/category/IndexView.vue'),
				},
				{
					path: ":slug_category/:slug_subcategory/:slug_subcategory2/:slug_subcategory3",
					name: "adSubcategory3",
					component: () => import('../views/category/IndexView.vue'),
				},
				{
					path: ":slug_category/:slug_subcategory/:slug_subcategory2/l-:location",
					name: "adSubcategory2location",
					component: () => import('../views/category/IndexView.vue'),
				},
				{
					path: ":slug_category/:slug_subcategory/:slug_subcategory2",
					name: "adSubcategory2",
					component: () => import('../views/category/IndexView.vue'),
				},
				{
					path: ":slug_category/:slug_subcategory/l-:location",
					name: "adCategorylocation",
					component: () => import('../views/category/IndexView.vue'),
				},
				{
					path: ":slug_category/:slug_subcategory",
					name: "adCategory",
					component: () => import('../views/category/IndexView.vue'),
				},
				{
					path: `${t('path.ad')}/:id,:slug`,
					name: 'item',
					component: () => import('../views/item/job/IndexView.vue')
				},
				{
					path: `:slug_category/:slug_subcategory/:slug_subcategory2/${t('path.ad')}/:hash`,
					name: 'itemHash',
					component: () => import('../views/item/job/IndexView.vue')
				},
				{
					path: `${t('path.ads')}/:id,:slug`,
					name: 'companyProfile',
					component: () => import('../views/item/profile/IndexView.vue')
				},
				{
					path: `${t('path.account')}/${t('path.ads')}/:id,:slug`,
					name: 'companyProfileAds',
					component: () => import('../views/item/profile/IndexView.vue')
				},
				{
					path: 'logout',
					name: 'accountLogout',
					component: () => import('../views/auth/LogoutView.vue'),
				},
				{
					path: "auth",
					component: () => import('@/views/auth/IndexView.vue'),
					children: [
						{
							path: "login",
							component: () => import('@/views/auth/LoginView.vue'),
							name: 'auth.login',
						},
						{
							path: "reset",
							name: 'authReset',
							component: () => import('@/views/auth/ResetView.vue')
						},
						{
							path: 'reset/:hash',
							name: 'authResetHash',
							component: () => import('../views/auth/ResetPasswordView.vue'),
						},
						{
							path: 'activate/:hash',
							name: 'authActivateHash',
							component: () => import('../views/auth/ActivateView.vue'),
						},
						{
							path: "register",
							name: 'authRegister',
							component: () => import('@/views/auth/RegisterView.vue')
						}
					]
				},
				{
					path: `${t('path.contact')}`,
					name: 'contact',
					component: () => import('../views/pages/ContactView.vue'),
				},
				{
					path: `${t('path.help')}`,
					name: 'help',
					component: () => import('../views/pages/HelpView.vue'),
				},
				{
					path: `${t('path.help')}/:slug`,
					name: 'help.group',
					component: () => import('../views/pages/HelpView.vue'),
				},
				{
					path: `${t('path.pricelist')}`,
					name: 'pricelist',
					component: () => import('../views/pages/PricelistView.vue'),
				},
				{
					path: `${t('path.account')}`,
					component: () => import('../layout/AccountLayout.vue'),
					meta: {
						requiresAuth: true,
						roleAccount: 1
					},
					children: [
						{
							path: '',
							redirect: `/${locale_path}/${t('path.account')}/${t('path.ads')}`
						},
						{
							path: `${t('path.ads')}`,
          					component: () => import('../views/account/ad/IndexView.vue'),
							children: [
								{
									path: '',
									name: 'AccountDashboard',
									redirect: `/${locale_path}/${t('path.account')}/${t('path.ads')}/praca/kierowcy`
								},
								{
									path: `${t('path.edit')}/:hash`,
									name: 'AccountAdEdit',
									component: () => import('../views/account/ad/edit/JobView.vue')
								},
								{
									path: 'cv/:hash',
									name: 'AccountAdCv',
									component: () => import('../views/account/ad/cv/IndexView.vue')
								},
								{
									path: 'cv/status/:hash',
									name: 'AccountAdCvStatus',
									component: () => import('../views/account/ad/cv/StatusView.vue')
								},
								{
									path: `cv/${t('path.ads')}/:hash`,
									name: 'AccountAdCvPreview',
									component: () => import('../views/account/ad/cv/PreviewView.vue')
								},
								{
									path: `cv/${t('path.payment')}/:hash`,
									name: 'AccountAdCvPayment',
									component: () => import('../views/account/ad/cv/PaymentView.vue')
								},
								{
									path: `cv/${t('recruitment-meeting')}/:hash`,
									name: 'AccountAdCvPayment',
									component: () => import('../views/account/ad/cv/RecruitmentMeetingView.vue')
								},
								{
									path: 'remove/:hash',
									name: 'AccountAdDelete',
									component: () => import('../views/account/ad/remove/JobView.vue')
								},
								{
									path: ':category/:subcategory',
									name: 'accountAd',
									component: () => import('../views/account/ad/Index.vue')
								}
							]
						},
						{
							path: 'cv',
							redirect: '/account/cv/create/driver',
							component: () => import('../views/account/ad/IndexView.vue'),
							children: [
								{
									path: 'create/driver',
									name: 'accountCvCreateDriver',
									component: () => import('../views/account/cv/driver/IndexView.vue')
								},
								{
									path: 'create/operator',
									name: 'accountCvCreateOperator',
									component: () => import('../views/account/cv/operator/IndexView.vue')
								},
								{
									path: 'create/instructor',
									name: 'accountCvCreateInstructor',
									component: () => import('../views/account/cv/instructor/IndexView.vue')
								},
								{
									path: 'edit/personal/:type',
									name: 'accountCvEditPersonal',
									component: () => import('../views/account/cv/common/DataView.vue')
								},
								{
									path: 'edit/contact/:type',
									name: 'accountCvEditContact',
									component: () => import('../views/account/cv/common/ContactView.vue')
								},
								/*{
									path: 'create/:type',
									name: 'accountCvCreate',
									component: () => import('../views/account/cv/Index.vue')
								},*/
								{
									path: 'driver/experience/create',
									name: 'accountCvExperienceDriverCreate',
							    		component: () => import('../views/account/cv/experience/driver.vue')
							  	},
							  	{
							    		path: 'driver/experience/:hash',
							    		name: 'accountCvExperienceDriverUpdate',
							    		component: () => import('../views/account/cv/experience/driver.vue')
							  	},
								{
									path: 'operator/experience/create',
									name: 'accountCvExperienceOperatorCreate',
							    		component: () => import('../views/account/cv/experience/operator.vue')
							  	},
							  	{
							    		path: 'edit',
							    		name: 'accountCvGeneral',
									component: () => import('../views/account/cv/edit/GeneralView.vue')
								},
								{
									path: 'contact',
									name: 'accountCvContact',
									component: () => import('../views/account/cv/edit/ContactView.vue')
								},
							  	{
									path: 'edit/code95',
									name: 'accountCvCode95',
									component: () => import('../views/account/cv/edit/Code95View.vue')
								},
								{
									path: 'edit/tachograph',
									name: 'accountCvTachograph',
									component: () => import('../views/account/cv/edit/TachographView.vue')
								},
								{
									path: 'edit/medical',
									name: 'accountCvMedical',
									component: () => import('../views/account/cv/edit/MedicalView.vue')
							  	},
								{
							    		path: 'edit/adr',
							    		name: 'accountCvAdr',
									component: () => import('../views/account/cv/edit/AdrView.vue')
								},
								{
							    		path: 'experience/create',
							    		name: 'accountCvExperienceCreate',
							    		component: () => import('../views/account/cv/edit/ExperienceView.vue')
								},
								{
							    		path: 'verified-driver',
							    		name: 'accountCvVerifiedDriver',
									component: () => import('../views/account/cv/VerifiedDriverView.vue')
								},
								{
									path: 'verified-driver/activate',
									name: 'accountCvVerifiedDriverActivate',
									component: () => import('../views/account/cv/VerifiedDriverActivateView.vue')
							  	},
							]
						},
						{
							path: `${t('path.companies')}`,
							name: 'accountCompanies',
							component: () => import('../views/account/company/List.vue')
						},
						{
							path: `${t('path.company')}`,
							name: 'accountCompany',
							component: () => import('../views/account/company/Index.vue')
						},
						{
							path: `${t('path.payment')}`,
							name: 'accountPayment',
							component: () => import('../views/account/payment/Index.vue')
						},
						{
							path: `${t('path.favorite')}`,
							component: () => import('../views/account/favorite/Index.vue'),
							children: [
								{
									path: '',
									name: 'accountFavorite',
									redirect: `/${locale_path}/${t('path.account')}/${t('path.favorite')}/job/kierowcy`,
								},
								{
									path: ':category/:subcategory',
									name: 'accountFavoriteTab',
									component: () => import('../views/account/favorite/List.vue')
								},
							]
						},
						{
							path: `${t('path.notification')}`,
							name: 'accountNotification',
							component: () => import('../views/account/notification/Index.vue')
						},
						{
							path: `${t('path.notification')}/:slug`,
							name: 'accountNotificationView',
							component: () => import('../views/account/notification/View.vue')
						},
						{
							path: 'setting',
							name: 'accountSetting',
							component: () => import('../views/account/setting/Index.vue')
						},
						{
							path: 'setting/password',
							name: 'accountSettingPassword',
							component: () => import('../views/account/setting/PasswordView.vue')
						},
						{
							path: 'remove',
							name: 'accountRemove',
							component: () => import('../views/account/setting/RemoveView.vue')
						}, 
					]
				},
				{
					path: 'admin',
					component: () => import('../layout/AdminLayout.vue'),
					meta: {
						requiresAuth: true,
						roleAdmin: 3,
						breadcrumbs: {
							label: 'account.admin'
						}
					},
					children: [
						{
							path: 'account',
							component: () => import('../views/admin/accounts/IndexView.vue'),
							children: [
								{
									path: '',
									name: 'adminAccount',
									component: () => import('../views/admin/accounts/ListView.vue'),
									meta: {
										breadcrumbs: {
											label: 'admin.account'
										}
									}
								},
								{
									path: 'moderator',
									name: 'adminAccountModerator',
									component: () => import('../views/admin/user/ModeratorView.vue'),
									meta: {
										breadcrumbs: {
											label: 'admin.account'
										}
									},
									children: [
										{
											path: 'create',
											name: 'adminAccountModeratorCreate',
											component: () => import('../views/admin/user/moderator/CreateView.vue'),
											meta: {
												breadcrumbs: {
													label: 'admin.account.moderator.create'
												}
											}
										}
									]
								}
							]
						},
						{
							path: 'dictionary',
							component: () => import('../views/admin/dictionary/IndexView.vue'),
							children: [
								{
									path: '',
									name: 'adminDictionary',
									component: () => import('../views/admin/adCategory/ListView.vue'),
									meta: {
										breadcrumbs: {
											label: 'admin.dictionary'
										}
									}
								}
							]
						},
						{
							path: 'push',
							component: () => import('../views/admin/push/IndexView.vue'),
							children: [
								{
									path: '',
									name: 'adminPush',
									component: () => import('../views/admin/push/ListView.vue'),
									meta: {
										breadcrumbs: {
											label: 'admin.push'
										}
									}
								}
							]
						},
						{
							path: 'sms',
							component: () => import('../views/admin/sms/IndexView.vue'),
							children: [
								{
									path: '',
									name: 'adminSms',
									component: () => import('../views/admin/sms/ListView.vue'),
									meta: {
										breadcrumbs: {
											label: 'admin.sms'
										}
									}
								},
								{
									path: 'message',
									name: 'adminSmsMessage',
									component: () => import('../views/admin/sms/MessageView.vue'),
									meta: {
										breadcrumbs: {
											label: 'admin.sms',
											label: 'admin.sms.message'
										}
									}
								}
							]
						},
						{
							path: 'integration',
							component: () => import('../views/admin/integration/IndexView.vue'),
							children: [
								{
									path: '',
									name: 'adminIntegration',
									component: () => import('../views/admin/integration/ListView.vue'),
									meta: {
										breadcrumbs: {
											label: 'admin.integration'
										}
									}
								}
							]
						},
						{
							path: 'setting',
							name: 'adminSetting',
							meta: {
								breadcrumbs: {
									label: 'admin.setting'
								}
							},
							children: [
								{
									path: 'location',
									name: 'adminLocation',
									component: () => import('../views/admin/location/IndexView.vue'),
									meta: {
										breadcrumbs: {
											label: 'admin.location.create'
										}
									}
								},
								{
									path: 'language',
									name: 'adminLanguage',
									component: () => import('../views/admin/setting/language/ItemsView.vue'),
									meta: {
										breadcrumbs: {
											label: 'admin.language.create'
										}
									}
								}
							]
						},
						{
							path: 'parser',
							component: () => import('../views/admin/parser/DefaultView.vue'),
							children: [
								{
									path: '',
									name: 'adminParser',
									component: () => import('../views/admin/parser/IndexView.vue'),
									meta: {
										breadcrumbs: {
											label: 'admin.parser'
										}
									}
								}
							]
						},
						{
							path: 'support',
							component: () => import('../views/admin/support/IndexView.vue'),
							children: [
								{
									path: '',
									name: 'adminSupport',
									component: () => import('../views/admin/support/ListView.vue'),
									meta: {
										breadcrumbs: {
											label: 'admin.support'
										}
									}
								}
							]
						},
					]
				},
				{
					path: 'regulamin',
					name: 'rules',
					component: () => import('../views/pages/RulesView.vue'),
				},
				{
					path: ':slug',
					name: 'lorem',
					component: () => import('../views/pages/PageView.vue'),
				},
			]
		}
	]
});

function checkAccess(to) {
	const user_store = useUserStore();
	const { isAdmin, isModerator, isCompany, isEmployee } = storeToRefs(user_store);

	let roleAccount = to.matched.some(record => record.meta.roleAccount);
	let roleAdmin = to.matched.some(record => record.meta.roleAdmin);

	if (roleAccount && (isCompany || isEmployee)) {
		return true;
	}
	else if (roleAdmin && (isAdmin || isModerator)) {
		return true;
	}
	else if (roleAccount || roleAdmin) {
		return false;
	}
	else {
		return true;
	}
}

router.beforeEach(async(to, from, next) => {
	if (localStorage.getItem('auth._token') !== undefined && localStorage.getItem('auth._token') !== null) {
		const userStore = useUserStore();
		const { setToken, setUser, setRoles, setAuthenticated } = userStore;
		const { authenticated } = storeToRefs(userStore);

		if (authenticated.value === false) {
			try {
				let request = await axios.get("/user");
				const { data } = request;

				setToken(localStorage.getItem('auth._token'));
				setUser(data.user);
				setRoles(data.roles);
				setAuthenticated(true);
				
				if (to.matched.some(record => record.meta.isAuth)) {
					window.scrollTo(0, 0);
					next({ path: '/' });
				}

				window.scrollTo(0, 0);
				if (checkAccess(to)) {
					next();
				}
				else {
					next({path: '/access-blocked'})
				}
			} catch(e) {
				//localStorage.removeItem("auth._token");
          		//window.location.reload();
			}
		} else {
			window.scrollTo(0, 0);
			if (checkAccess(to)) {
				next();
			}
			else {
				next({path: '/access-blocked'})
			}
		}
	}
	else if (to.matched.some(record => record.meta.requiresAuth)) {
		window.scrollTo(0, 0);
		next({
			path: '/pl/auth/login',
			params: { nextUrl: to.fullPath }
		})
	}

	window.scrollTo(0, 0);
	next();
})

export default router