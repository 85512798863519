<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="mb-30">
                    <div class="height-43 justify-content-between d-flex align-items-center">
						<div>
							<h3 v-if="title" class="mb-0"><Text :i="title" /></h3>
                        	<UiBreadcrumb />
						</div>
                        <div>
                            <slot name="buttons" />
						</div>
                    </div>
                </div>
            </div>

			<div class="col-md-12" v-if="hasSlot('afterheader')">
				<slot name="afterheader" />
			</div>

			<div v-if="hasSlot('sidemenu')" class="col flex-360">
				<slot name="sidemenu" />
			</div>
			<div class="col">
				<slot />
			</div>
		</div>
        <Toast position="bottom-center" />
    </div>
</template>

<script setup>
import { useSlots } from 'vue';
import Toast from 'primevue/toast';

defineProps({
	title: String
});

const slots = useSlots();
const hasSlot = (name) => !!slots[name];
</script>