<template>
	<UiTile icon="/icon/driver.png" :class="{active: active == 'driver'}" title="Kierowca">
		<template v-slot:additional>
			<router-link v-if="form.driver.status" class="d-flex justify-content-between w-100 text-primary fs-14" :to="{name: 'accountCvCreateDriver'}">
				<span class="text-black font-regular"><Text i="ad.invitations" />:</span>
				<span class="badge">{{ form.driver.invitations.length }}</span>
			</router-link>
			<router-link v-else class="d-flex align-items-center text-primary fs-14" :to="{name: 'accountCvCreateDriver'}">
				<i class="fs-14 mr-10 pi pi-plus"></i>
				<span><Text i="cv.create"/></span>
			</router-link>
		</template>
	</UiTile>
	<UiTile icon="/icon/operator.png" :class="{active: active == 'operator'}" title="Operator">
		<template v-slot:additional>
			<router-link v-if="form.operator.statusr" class="d-flex justify-content-between w-100 text-primary fs-14" :to="{name: 'accountCvCreateOperator'}">
				<span class="text-black font-regular"><Text i="ad.invitations" />:</span>
				<span class="badge">{{ form.operator.invitations.length }}</span>
			</router-link>
			<router-link v-else class="d-flex align-items-center text-primary fs-14" :to="{name: 'accountCvCreateOperator'}">
				<i class="fs-14 mr-10 pi pi-plus"></i>
				<span><Text i="cv.create"/></span>
			</router-link>
		</template>
	</UiTile>
</template>

<script setup>
import { useCv } from '@/composables/useCv';
const { cvForm } = useCv();

const form = {
	driver: {
		status: false,
		invitations: []
	},
	operator: {
		status: false,
		invitations: []
	}
}

defineProps({
	active: String
})
</script>
