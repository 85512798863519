import { breakpointsBootstrapV5, useBreakpoints } from "@vueuse/core";
import {computed} from "vue";

export function useBreakpoint() {
	const breakpoints = useBreakpoints(breakpointsBootstrapV5);

	const sizes = {
		sm: breakpoints.smaller("sm"),
		md: breakpoints.between('sm', 'md'),
		lg: breakpoints.between('md', 'lg'),
		xl: breakpoints.between('lg', 'xl'),
		xxl: breakpoints.greater('xl')
	};

	const breakpoint = computed(() => {
		if (sizes.sm.value === true) { return 'sm'; }
		if (sizes.md.value === true) { return 'md'; }
		if (sizes.lg.value === true) { return 'lg'; }
		if (sizes.xl.value === true) { return 'xl'; }
		if (sizes.xxl.value === true) { return 'xxl'; }
	});

	return { breakpoint }
}