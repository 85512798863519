<template>
	<div class="ui__collapse" :class="{show: show}">
		<div v-if="header || hasHeaderSlot" class="ui__collapse__header">
			<slot name="header">
				<h4><Text :i="header" /></h4>
			</slot>
			<i @click="onChangeShow" class="pi pi-chevron-down"></i>
		</div>
		
		<Transition name="slide">
			<div v-if="show" class="ui__collapse__body">
				<slot />
			</div>
		</Transition>
	</div>
</template>

<script>
import {computed, ref, watch } from "vue";
	
	export default {
		props: {
			header: String,
			show: {
				type: Boolean,
				default: true
			},
			command: Function
		},
		setup(props, {slots}) {
			const show = ref(props.show);
			
			const onChangeShow = () => {
				show.value = !show.value;
				if (props.command !== undefined) {
					props.command(show.value);
				}
			}
			
			watch(() => props.show, () => {
				show.value = props.show;
			})
			
			let hasHeaderSlot = computed(() => {
				return slots.header && slots.header().length;
			});
			
			return {
				hasHeaderSlot,
				show,
				onChangeShow
			}
		}
	}
</script>