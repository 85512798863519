<template>
	<InputDropdown :error="error" :label="label" v-bind="$attrs" v-model="value" :options="countries">
		<template #value="slotProps">
			<div class="p-dropdown-car-value" v-if="value">
				<div class="d-flex">
					<span :class="`fi fi-${value.toLowerCase()}`" style="flex:0 0 20px;"></span>
					<span class="ml-10">{{ country.name }}</span>
				</div>
			</div>
			<span v-else>&nbsp;</span>
		</template>
		<template #option="slotProps">
			<div class="d-flex">
				<span :class="`fi fi-${slotProps.slotProps.option.id.toLowerCase()}`"></span>
				<span class="ml-10">{{ slotProps.slotProps.option.name }}</span>
			</div>
		</template>
	</InputDropdown>
</template>

<script setup>
	import { ref, computed, onMounted, watch } from "vue";
	import { useApi } from "../../composables/useApi";
	
	const props = defineProps({
		modelValue: String,
		all: {
			type: Boolean,
			default: true
		},
		error: [Array, Object, String],
		label: {
			type: String,
			default: 'cv.citizenship'
		}
	});

	const value = ref('');
	const { makeApiRequest } = useApi();

	const countries = ref([]);
	onMounted(async() => {
		try {
			const request = await makeApiRequest('option/countries', 'get');
			if (request && request.data) {
				countries.value = request.data;
				value.value = props.modelValue;
			}
		} catch(e) {
			console.error(e);
		}
	})

	watch(() => props.modelValue, () => {
		if (countries.value.length > 0) {
			value.value = props.modelValue;
		}
	});

	watch(() => countries.value, () => {
		if (value.value && countries.value.length > 0) {
			value.value = props.modelValue;
		}
	}, { deep: true});
			
	const country = computed(() => {
		return countries.value.find(i => i.id == value.value);
	});
</script>