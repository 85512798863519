import axios from "axios";
import { defineStore } from "pinia";

export const useLanguageStore = defineStore("language_store", {
	state: () => ({
          lang: 'pl',
          language: [],
	}),
	actions: {
          setLanguage(lang) {
               this.lang = lang;
          },
          setLanguages(languages) {
               this.language = languages;
          }
	}
});