<template>
     <InputDropdown v-bind="$attrs">
		<template v-slot:option="{slotProps}">
			<div class="d-flex align-item-center">
				<div class="form__dropdown__icon" :class="`flex-${width}`">
					<ScaleSvg :src="slotProps.option.icon" base="18" />
				</div>
				<span><Text :i="slotProps.option.name" /></span>
			</div>
		</template>
	</InputDropdown>
</template>

<script>
    import { ref } from 'vue';
    import ScaleSvg from '../Ui/ScaleSvg.vue';

    export default {
        props: {
            width: Number
        },
        setup(props) {
            const width = ref(110);
            if (props.width) {
                width.value = props.width;
            }
            return { width };
        },
        components: { ScaleSvg }
}
</script>