<template>
	<div class="ui__headertabs">
		<div class="container">
			<ul>
				<li v-for="({label, route}, index) in items" :key="index">
					<router-link :to="route">
						<Text :i="label" />
					</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			items: Array
		}
	}
</script>