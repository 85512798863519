<template>
	<div class="ui__accountmenu">
		<div class="ui__accountmenu__header">
			<div class="d-flex justify-content-between w-100 align-items-center">
				<div class="d-flex align-items-center">
					<div class="flex-44 mr-10">
						<PrimeAvatar shape="circle" size="large" label="KN" />
					</div>
					<div>
						<span>{{ userInfo.name }}</span><br>
						<span class="fs-12 text-muted lh-18">{{ userInfo.email }}</span>
					</div>
				</div>
				<ui-href :to="{name: 'accountSetting'}">
					<InlineSvg src="/icon/cog.svg" height="24" />
				</ui-href>
			</div>
		</div>
		<perfect-scrollbar class="ui__accountmenu__ps">
			<ul class="ui__accountmenu__navigation">
				<template v-for="(item, index) in userNavigation" :key="index">
					<template v-if="item.component !== null && item.component !== undefined">
						<li :class="item.className">
							<UiCollapse :show="false">
								<template #header>
									<h4><Text :i="item.label" /></h4>
								</template>
								<component :is="item.component" />
							</UiCollapse>
						</li>
					</template>
					<template v-if="item.component === null && item.component !== undefined">
						<li :class="item.className">
							<router-link :to="item.route">
								<div class="d-flex flex-column">
									<Text :i="item.label" />
									<span v-if="item.subtext" class="subtext fs-11">
										<span v-for="subitem, sindex in item.subtext" :key="sindex" :class="`${sindex > 0 ? 'ml-15' : ''} text-${subitem.class}`">{{ subitem.text }}</span>
									</span>
								</div>
								<i class="pi pi-chevron-right"></i>
							</router-link>
						</li>
					</template>
				</template>
				<li>
					<router-link :to="{name: 'accountLogout'}" class="font-regular fs-16"><Text i="account.nav.logout" /></router-link>
				</li>
			</ul>
		</perfect-scrollbar>
	</div>
</template>

<script>
	import { useUser } from "../../composables/useUser";
	import { useBreakpoint } from "../../composables/useBreakpoint";
	
	export default {
		setup() {
			const { userNavigation, userInfo } =  useUser();
			const { breakpoint } = useBreakpoint();
			
			return { userNavigation, userInfo }
		}
	}
</script>
