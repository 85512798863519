<template>
	<div class="ui__loader" v-if="loader">
		<ProgressSpinner strokeWidth="5" style="width:50px; height:50px" />
	</div>
</template>

<script>
	import ProgressSpinner from 'primevue/progressspinner';
	import { useCoreStore } from "../../stores/useCoreStore";
	import { storeToRefs } from "pinia";
	
	export default {
		components: {
			ProgressSpinner
		},
		setup() {
			const core_store = useCoreStore();
			const { loader } = storeToRefs(core_store);
			
			return { loader }
		}
	}
</script>