import { defineStore } from "pinia";
import axios from "axios";

export const useUserStore = defineStore("user_store", {
	state: () => ({
		authenticated: false,
		token: null,
		user: {},
		userProxyId: null,
		userProxy: {},
		roles: [],
		isAdmin: false,
		isModerator: false,
		isCompany: false,
		isEmployee: false
	}),
	actions: {
		async setUserProxyId(id) {
			if (id == null) {
				this.userProxyId = id;
			}
			else {
				try {
					this.userProxyId = id;
					axios.defaults.headers.common['X-USER-PROXY-ID'] = id;
					let request = await axios.get("/user");
					let { data } = request;
					this.userProxy = data;
				}
				catch(error) {
					console.log(error)
				}
			}
		},
		setToken(token) {
			this.token = token;
		},
		setUser(user) {
			this.user = user;
		},
		setAuthenticated(value) {
			this.authenticated = value;
		},
		setRoles(roles) {
			this.roles = roles;
			if (roles.find(e => e.name === 'admin')) { this.isAdmin = true; }
			if (roles.find(e => e.name === 'moderator')) { this.isModerator = true; }
			if (roles.find(e => e.name === 'employee')) { this.isEmployee = true; }
			if (roles.find(e => e.name === 'company')) { this.isCompany = true; }
		},
		setAdmin(status) {
			this.isAdmin = status
		},
		setModerator(status) {
			this.isModerator = status
		},
		setCompany(status) {
			this.isCompany = status;
		},
		setEmployee(status) {
			this.isEmployee = status;
		},
	}
});