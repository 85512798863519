<template>
    <input-dropdown label="Państwo" :options="options" placeholder="Wybierz" />
</template>

<script setup>
const currentCountry = 'pl';
const countries = [
    {id: 'pl', name: 'Polska (100)', counter: 100},
    {id: 'de', name: 'Niemcy (100)', counter: 100},
    {id: 'nl', name: 'Holandia (100)', counter: 100},
];

const options = countries.map(country => {
    return {
        id: country.id,
        name: country.name,
        selected: country.id === currentCountry,
    };
});
</script>