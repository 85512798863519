<template>
    <div class="input__field input__field__inputtext input__field__phone">
		<vue-tel-input
            :autoFormat="true"
            :inputOptions="{
                placeholder: ''
            }"
            :dropdownOptions="{
                showFlags: showFlags
            }"
            :onlyCountries="['PL','DE','NL','BE','LU']"
            :class="{error : error?true:false}"
            mode="international"
            @validate="validatePhone($event)"
            @blur="onSetValue($event)"
            v-model="phone.value"
        >
        <template #arrow-icon> &nbsp; </template>
        </vue-tel-input>
        <InputError :error="error" />
	</div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { VueTelInput } from 'vue-tel-input';

const emit = defineEmits(['update:modelValue']);
const phone = ref({ value: '', country_code: ''});
const value = ref('');
const validated = ref(false);

const props = defineProps({
    error: [Array, Object],
    modelValue: [String],
    showFlags: {
        type: Boolean,
        default: true
    }
});

onMounted(() => {
    phone.value = props.modelValue;
});

watch(() => props.modelValue, () => {
    phone.value = props.modelValue;
    value.value = props.modelValue;
}, { deep: true });

const validatePhone = (e) => {
    validated.value = e.valid === true ?? false;
    if (e.valid) {
        value.value = e.number;
        emit('update:modelValue', {value: e.number, country_code: e.countryCode });
    } else {
        value.value = '';
    }
}

const onSetValue = (e) => {
    if (validated.value === false) {
        emit('update:modelValue', { value: '', country_code: ''});
        value.value = '';
        phone.value = { value: '', country_code: ''};
    }
}

</script>
