<template>
	<div class="ui__tooltip">
		<div class="ui__tooltip__icon"><i :class="className" class="pi pi-chevron-down"></i></div>
		<div class="ui__tooltip__area">
			<div class="ui__tooltip__trangle">▲</div>
			<slot />
		</div>
	</div>
</template>

<script>
	export default {
		name: "",
		props: {
			className: String
		},
		setup() {
		
		}
	}
</script>