import axios from 'axios';
import { useApi } from "./useApi";
import { useCategoryStore } from "../stores/useCategoryStore";
import { useI18n } from 'vue-i18n';
import { getMessages } from '../system/helpers/i18n';
import {usePreferredLanguages} from "@vueuse/core";
export default function() {
    const { makeApiRequest } = useApi();
    const categoryStore = useCategoryStore();
    const { locale, setLocaleMessage } = useI18n();
    const { setCategories } = categoryStore;
    const onLoadLocale = async(newLocale) => {
        if (newLocale === 'undefined' || newLocale === undefined) {
            newLocale = 'pl';
        }

        // zmiana ustawien
        axios.defaults.headers.common['Locale'] = newLocale;
        locale.value = newLocale;

        // wczytanie tlumaczen
        const messages = await getMessages();
        setLocaleMessage(newLocale, messages);

        // wczytanie kategorii
        const categories = await makeApiRequest('ad/category', 'get');
        setCategories(categories.category);
        // zmiana URLa
    }

    return {
        onLoadLocale
    }
}