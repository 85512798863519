import { nextTick } from 'vue'
import axios from 'axios';
import { useApi } from '../../composables/useApi';

export async function loadLocaleMessages(i18n, locale) {
    axios.defaults.headers.common['Locale'] = locale;
    const messages = await getMessages();
    i18n.global.setLocaleMessage(locale, messages);
    return nextTick()
}
export async function getMessages() {
    const { makeApiRequest } = useApi();
    const response = await makeApiRequest('translations', 'get');
    return response;
}