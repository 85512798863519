<template>
	<div class="input__field input__checkbox d-flex" :class="{'input__checkbox__error': error}">
		<PrimeCheckbox v-bind="$attrs" />
		<span class="input__checkbox__label">
			<Text  v-if="label" :i="label" />
			<slot v-else />
			<div v-if="error" :class="{'ml-40' : error && icon}">
				<InputError :error="error" />
			</div>
		</span>
	</div>
</template>

<script>
	export default {
		props: {
			label: String,
			error: Object
		},
		setup() {
		}
	}
</script>