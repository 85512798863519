<template>
	<v-layout>
		<v-main>
			<router-view />
		</v-main>
		<ui-loader />

		<button @click="subscribeToPush" class="d-none"></button>

		<Dialog v-model:visible="cookie" :breakpoints="{'500px': '100%'}" :style="{width: '500px'}" position="bottomright" :closeOnEscape="false" :closable="false">
            <p class="m-0">{{ t('ui.cookie.dialog') }}</p>
			<template #footer>
				<UiLink :to="{name: 'lorem', params: {lang: 'pl', slug: 'polityka-plikow-cookies'}}" class="p-button p-component p-button-text mr-16"><span class="p-button-label text-uppercase">{{ t('ui.cookie.detail') }}</span></UiLink>
				<Button :label="t('ui.cookie.accept')" @click="onCloseCookie" class="p-button-outlined text-uppercase" />
			</template>
        </Dialog>
	</v-layout>
</template>

<script setup>
	import { RouterView } from 'vue-router';
	import { computed, onMounted, provide, reactive, ref, watch } from "vue";
	import UiLoader from './components/Ui/Loader.vue';
	import useMeta from '@/composables/useMeta';
	import Dialog from 'primevue/dialog';
	import Button from 'primevue/button';
	import UiLink from './components/Ui/Link.vue';
	import { useI18n } from 'vue-i18n';
	import useLocale from "@/composables/useLocale";
	import { useApi } from './composables/useApi';
	import { useRoute } from 'vue-router';
	import { useCookies } from "vue3-cookies";

	const { makeApiRequest } = useApi();
	const router = useRoute();
	const { cookies } = useCookies();

	const cookie = ref(false);

	const { locale, t } = useI18n();
	const { onLoadLocale } = useLocale();
	const updateBreadcrumbs = (breadcrumbs) => {
		state.breadcrumbs = breadcrumbs;
	};

	const onCloseCookie = () => {
		cookie.value = false;
		cookies.set('_saveCookie', true);
	}

	const generateKey = (length) => {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < length; i++ ) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	let key = '';
	let visit = 0;
	if (router && router.query && router.query.t) {
		key = router.query.t;
		localStorage.setItem('_r', key);
	}
	else if (localStorage.getItem('_r') !== undefined && localStorage.getItem('_r') !== null) {
		key = localStorage.getItem('_r');
	} else {
		key = generateKey(16);
		localStorage.setItem('_r', key);
	}
	
	watch(() => router.fullPath, () => {
		makeApiRequest('route', 'post', { route: `${window.location.origin}${router.fullPath}`, key, visit: visit == 0 ? true : false, referrer: document.referrer });
		visit++;
	});

	const subscribeToPush = async() => {
		try {
			const registration = await navigator.serviceWorker.ready;
			const subscription = await registration.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey: 'BDOFeEGFBC2tpf7NJQzoAcORcEaAEdXbf42dZMIZZ-xuYeB2-lkjeqxWn9rsVFlAvAJfWbvSSaasQ-BLC23cOwI',
			});
			console.log(subscription.toJSON().contentEncoding);
			makeApiRequest('/subscription', 'post', JSON.parse(JSON.stringify(subscription)));
		} catch(e) {
			console.error('Error during subscription:', error);
		}
	}

	onMounted(async() => {
		if (cookies.get("_saveCookie") === null) {
			cookie.value = true;
		}

		const domain = window.location.hostname;
		const subdomain = domain.replace(`.${domain}`, "");
		let lang = 'pl';

		if (router && router.params && router.params.lang) {
			lang = router.params.lang;
		}
		else if (localStorage.getItem('_lang') !== undefined && localStorage.getItem('_lang') !== null) {
			lang = localStorage.getItem('_lang');
		}
		else if (!subdomain || subdomain.length !== 2) {
			lang = navigator.language;
			lang = navigator.language.split("-");
			lang = lang[0];
		} else if (subdomain.length === 2) {
			lang = subdomain;
		}

		locale.value = lang;
		localStorage.setItem('_lang', lang);

		await onLoadLocale(lang);
	});

	useMeta();
			
			const onChangeTranslationMode = (value) => {
				state.translation_mode = value;
			}
			
			const onSetTranslationKey = (value) => {
				if (value == null) {
					onChangeTranslationMode(false);
				}
				else {
					onChangeTranslationMode(true);
				}
				
				state.translation_key = value;
			}

			const state = reactive({
				translation_key: null,
				translation_mode: false,
				breadcrumbs: []
			});
			
			provide("breadcrumbs", computed(() => state.breadcrumbs));
			provide("translation_key", computed(() => state.translation_key));
			provide("translation_mode", computed(() => state.translation_mode));
			
			provide("onChangeTranslationMode", onChangeTranslationMode);
			provide("updateBreadcrumbs", updateBreadcrumbs);
			provide("onSetTranslationKey", onSetTranslationKey);
</script>

<style lang="scss">
	@import "./src/assets/scss/app.scss";
</style>