<template>
	<div class="ad__item__tiles__cv">
		<AdItemTilesCv />
	</div>
</template>

<script>
	import AdItemTilesCv from '../../components/Ad/Item/Tiles/Cv.vue';
	
	export default {
		components: {
			AdItemTilesCv
		}
	}
</script>