<template>
	<footer class="ui__footer">
		<div class="container">
			<p class="text-center font-medium text-black"><Text i="footer.text" /></p>
			<ul class="ui__footer__nav" style="max-width:500px; margin:auto">
				<li><ui-href :to="{name: 'createItem'}"><Text i="ad.new" /></ui-href></li><li class="pause"><span></span></li>
				<li><ui-href :to="{name: 'pricelist'}"><Text i="ui.pricelist" /></ui-href></li><li class="pause"><span></span></li>
				<li><ui-href :to="{name: 'help'}"><Text i="ui.help" /></ui-href></li><li class="pause"><span></span></li>
				<li><ui-href :to="{name: 'contact'}"><Text i="ui.contact" /></ui-href></li>
				<GateArea tag="li" :gate="['admin', 'moderator']">+ strona</GateArea>
			</ul>
			<div class="ui__footer__bottom d-flex flex-wrap flex-lg-nowrap justify-content-between">
				<ul class="ui__footer__rules justify-content-center">
					<li><ui-href :to="{name: 'rules'}"><Text i="ui.rule" /></ui-href></li>
					<li><ui-href :to="{name: 'lorem', params: {slug: 'polityka-ochrony-prywatnosci-i-plikow-cookies'}}"><Text i="ui.policy" /></ui-href></li>
					<GateArea tag="li" :gate="['admin', 'moderator']">+ strona</GateArea>
				</ul>
				<div class="ui__footer__logo align-items-center">
					<div><InlineSvg src="/icon/driverbook.svg" width="32" /></div>
					<div class="ml-15">Since 20122022 <br />Copyright &copy; 2022-2024 DriverBook. All rights reserved. 
						
					</div>
				</div>
				<ul class="ui__footer__society d-flex align-items-center" v-if="facebook || linkedin || youtube">
					<li v-if="facebook"><a href=""><InlineSvg src="/icon/society/facebook.svg" width="22" /></a></li>
					<li v-if="linkedin" class="mx-15"><a href=""><InlineSvg src="/icon/society/linkedin.svg" width="22" /></a></li>
					<li v-if="youtube"><a href=""><InlineSvg src="/icon/society/youtube.svg" width="22" /></a></li>
				</ul>
			</div>
		</div>
	</footer>
</template>

<script setup>
import { ref } from 'vue';

const facebook = ref('');
const linkedin = ref('');
const youtube = ref('');
</script>