<template>
    <InputField :label="label" :error="error">
        <PrimeEditor v-model="value" @text-change="onUpdate" editorStyle="height: 320px">
            <template #toolbar>
                <span class="ql-formats">
                    <button class="ql-bold"><span class="material-icons-outlined">format_bold</span></button>
                    <button class="ql-italic"><span class="material-icons-outlined">format_italic</span></button>
                    <button class="ql-underline"><span class="material-icons-outlined">format_underlined</span></button>
                    <button value="ordered" class="ql-list"><span class="material-icons-outlined">format_list_bulleted</span></button>
                    <button value="bullet" class="ql-list"><span class="material-icons-outlined">format_list_numbered</span></button>
                </span>
            </template>
        </PrimeEditor>
    </InputField>
    <div class="mb-16 text-end fs-12 text-muted" v-if="max > -1">
		<span>{{ letters }}/{{ max }}</span>
	</div>
</template>

<script>
    import { computed, ref, watch } from "vue";

    export default {
        props: {
            label: String,
            error: Object,
            modelValue: [String, Object],
            max: {
                type: Number,
                default: 1000
            }
        },
        emits: ["modelValue"],
        setup(props, { emit }) {
            const value = ref(null);
            value.value = props.modelValue;

            const letters = computed(() => {
                let text = value.value;

                if (text == undefined) {
                    return 0;
                }
                text = text.replace(/(<([^>]+)>)/ig, '');
                text = text.trim();

                let length = text.length;
                return length > props.max ? props.max : length;
            })

            watch(() => props.modelValue, () => {
                value.value = props.modelValue;
            });

            const onUpdate = (e) => {
                let instance = e.instance;
                instance.deleteText(props.max, letters.value);
                value.value = instance.root.innerHTML;
                emit("update:modelValue", value.value);
            }

            return { value, letters, onUpdate }
        }
    }
</script>