<template>
    <div class="py-6">
        <input-text :label="t('admin.language.name')" :error="errorAdminLanguage['name']" v-model="formAdminLanguage.name" />
        <input-text :label="t('admin.language.locale')" :error="errorAdminLanguage['locale']" v-model="formAdminLanguage.locale" class="mb-0" />
        <span class="d-block mb-16 fs-12 text-muted">{{ t('admin.language.explanation') }}</span>
        <input-text :label="t('admin.language.code')" :error="errorAdminLanguage['code']" v-model="formAdminLanguage.code" />
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useLanguage } from '../../../composables/useLanguage';

const { t } = useI18n();
const { formAdminLanguage, errorAdminLanguage } = useLanguage();
</script>