<template>
	
	<UiHeaderTabs :items="navigation" class="d-none d-lg-block account" />

	<div class="py-lg-30">
		<div class="container">
			<div class="alert alert-warning" v-if="user.status_id === 1">
				<div class="d-flex justify-content-center">
					<span>{{ t('account.noactivealert') }}, <a href="javascript:;" @click="onResendActivationMail">{{ t('account.sendagain') }}</a>.</span>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<router-view />
				</div>
			</div>
			
		</div>
	</div>
</template>

<script setup>
import { ref, computed, inject } from "vue";
import { useWindowScroll, useBrowserLocation } from '@vueuse/core'
import { useUser } from "../composables/useUser";
import { useUserStore } from "../stores/useUserStore";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import useAuth from "../composables/useAuth";

const { y } = useWindowScroll()
const location = useBrowserLocation();
const { userNavigation } = useUser();
const main = useUserStore();
const { setUserProxyId } = main;
const { userProxy, userProxyId, user } = storeToRefs(main);
const router = useRouter();
const { t } = useI18n();
const { onResendActivationMail } = useAuth();

const navigation = ref(userNavigation);
const userInfo = ref(user);

const path = location.value.pathname;
if (path.search("/account/ad") == 0) {
	navigation.value[0].active = true;
}
else if (path.search("/account/cv") == 0) {
	navigation.value[1].active = true;
}
else if (path.search("/account/company") == 0) {
	navigation.value[2].active = true;
}
else if (path.search("/account/payment") == 0) {
	navigation.value[3].active = true;
}
else if (path.search("/account/favorite") == 0) {
	navigation.value[4].active = true;
}
else if (path.search("/account/notification") == 0) {
	navigation.value[5].active = true;
}

const headerClass = computed(() => {
	return y.value > 5 ? true : false;
})

const translation_mode = inject("translation_mode");

const onLeaveAccount = () => {
	setUserProxyId(null);
	router.push('/pl/admin');
}
</script>