<template>
	<UiSlideMenu :class="{show : show}" @click="onHideMenu" v-model:show="show" />
	<UiAccountMenu v-if="show_account && allow_breakpoints.includes(breakpoint)" />
	<div class="ui__tabmenu">
		<ul>
			<li :class="{active: show}">
				<a @click.prevent="onToogleMenu" href="javascript:;">
					<div class="db db-category"></div>
					<span><Text i="ui.categories" /></span>
				</a>
			</li>
			<li>
				<router-link @click="onHideMenu" :to="{ name: 'createItem' }">
					<div class="db db-plus-circle"></div>
					<span><Text i="ad.new" /></span>
				</router-link>
			</li>
			<li>
				<a href="javascript:;" @click.prevent="onToogleAccountMenu">
					<div class="db db-account"></div>
					<span><Text i="ui.account" /></span>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
	import UiSlideMenu from '@/components/Ui/SlideMenu.vue';
	import UiAccountMenu from '@/components/Ui/AccountMenu.vue';
	import { useBreakpoint } from "../../composables/useBreakpoint";
	import { ref, inject } from "vue";
	import { useRouter } from "vue-router";
	import { useUserStore } from "../../stores/useUserStore";
	import { storeToRefs } from 'pinia';

	export default {
		components: { UiSlideMenu, UiAccountMenu },
		setup() {
			let show = ref(false);
			let show_account = ref(false);
			let { breakpoint } = useBreakpoint();
			const userStore = useUserStore();
			const router = useRouter();
			const { authenticated } = storeToRefs(userStore);
			
			const onToogleMenu = () => {
				show_account.value = false;
				show.value = !show.value;
			}
			const onHideMenu = () => {
				show.value = false;
			}
			
			let allow_breakpoints = ['sm', 'md'];
			
			const onToogleAccountMenu = () => {
				if (authenticated.value) {
					let size = breakpoint.value;
					if (size == 'sm' || size == 'md') {
						show.value = false;
						show_account.value = !show_account.value;
					}
				}
				else {
					router.push("/pl/auth/login");
				}
			}

			return {
				allow_breakpoints,
				show,
				show_account,
				breakpoint,
				onHideMenu,
				onToogleMenu,
				onToogleAccountMenu
			}
		}
	}

</script>
