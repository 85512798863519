<template>
	<InlineSvg :src="src" v-if="height > 0" :height="height" :color="false" />
</template>

<script>
	import { onMounted, ref, watch } from "vue";
	
	export default {
		props: {
			src: String,
			base: Number
		},
		setup(props) {

			const height = ref(0);

			onMounted(() => {
				height.value = scale('height');
			}),

			watch(() => props.src, () => {
				height.value = scale('height');
			});

			watch(() => props.base, () => {
				height.value = scale('height');
			});
			
			function getNameFromSrc() {
				if (props.src == "" || props.src == undefined) {
					return ;
				}
				let file = props.src.split("/");
				file = file[file.length-1];
				file = file.split(".");
				file = file[0];
				
				return file;
			}
			
			function getPropertyFromFile() {
				let name = getNameFromSrc();
				
				switch(name) {
					case "a": return { size: { width:	21.94, height:13.14 }, scale: {height:0.82125} }; break;
					case "a1": return { size: { width:	20.73, height:13.93 }, scale: {height:0.870625} }; break;
					case "agricultural": return { size: { width:	20.31, height:15.27 }, scale: {height:0.954375} }; break;
					case "agriculturals": return { size: { width:	53.07, height:28.38 }, scale: {height:1.77375} }; break;
					case "auto": return { size: { width:	36.12, height:16.3 }, scale: {height:1.01875} }; break;
					case "autotransporter_c": return { size: { width:	39.25, height:18.36 }, scale: {height:1.1475} }; break;
					case "autotransporter_set1": return { size: { width:	81.6, height:18.36 }, scale: {height:1.1475} }; break;
					case "autotransporter_set2": return { size: { width:	67.5, height:18.36 }, scale: {height:1.1475} }; break;
					case "autotransporter_set3": return { size: { width:	96, height:18.36 }, scale: {height:1.1475} }; break;
					case "b": return { size: { width:	38.62, height:12.99 }, scale: {height:0.811875} }; break;
					case "be": return { size: { width:	53.7, height:11.69 }, scale: {height:0.811875} }; break;
					case "bike": return { size: { width:	30.22, height:16.91 }, scale: {height:1.056875} }; break;
					case "bulldozer": return { size: { width:	28.55, height:15.27 }, scale: {height:0.954375} }; break;
					case "burta_c": return { size: { width:	39.25, height:16 }, scale: {height:1} }; break;
					case "burta_set": return { size: { width:	55.5, height:16 }, scale: {height:1} }; break;
					case "burta_set1": return { size: { width:	55.5, height:16 }, scale: {height:1} }; break;
					case "burta_set2": return { size: { width:	67.5, height:16 }, scale: {height:1} }; break;
					case "burta_set3": return { size: { width:	95.7, height:16 }, scale: {height:1} }; break;
					case "burta": return { size: { width:	37.95, height:14.02 }, scale: {height:0.87625} }; break;
					case "c": return { size: { width:	39.25, height:18.03 }, scale: {height:1.126875} }; break;
					case "c1": return { size: { width:	39.25, height:18.03 }, scale: {height:1.126875} }; break;
					case "c1e": return { size: { width:	60.5, height:18.03 }, scale: {height:1.126875} }; break;
					case "car_transporter": return { size: { width:	45.03, height:15.8 }, scale: {height:0.9875} }; break;
					case "cattle_c": return { size: { width:	39.09, height:18.03 }, scale: {height:1.126875} }; break;
					case "cattle_set1": return { size: { width:	55.5, height:18.04 }, scale: {height:1.1275} }; break;
					case "cattle_set2": return { size: { width:	67.16, height:18.03 }, scale: {height:1.126875} }; break;
					case "cattle_set3": return { size: { width:	95.25, height:18.03 }, scale: {height:1.126875} }; break;
					case "ce": return { size: { width:	55.5, height:18.04 }, scale: {height:1.1275} }; break;
					case "cement_mixer": return { size: { width:	39.25, height:21.19 }, scale: {height:1.324375} }; break;
					case "city": return { size: { width:	55.48, height:16 }, scale: {height:1} }; break;
					case "construction": return { size: { width:	38.38, height:19.03 }, scale: {height:1.189375} }; break;
					case "container_set1": return { size: { width:	55.5, height:18.04 }, scale: {height:1.1275} }; break;
					case "container_set2": return { size: { width:	67.16, height:18.03 }, scale: {height:1.126875} }; break;
					case "container_set3": return { size: { width:	95.32, height:18.03 }, scale: {height:1.126875} }; break;
					case "crane": return { size: { width:	44.13, height:20.44 }, scale: {height:1.2775} }; break;
					case "crane_blast": return { size: { width:	31.62, height:23.1 }, scale: {height:1.44375} }; break;
					case "crane_tracked": return { size: { width:	32.02, height:23.26 }, scale: {height:1.45375} }; break;
					case "crane_bridge": return { size: { width:	43.32, height:23.29 }, scale: {height:1.455625} }; break;
					case "cranes": return { size: { width:	79.3, height: 23.1 }, scale: {height:1.44375} }; break;
					case "constructions": return { size: { width:	19.03, height:70.27 }, scale: {height:1.189375} }; break;
					case "combine": return { size: { width:	33.28, height:23.85 }, scale: {height:1.490625} }; break;
					case "d": return { size: { width:	53.2, height:16 }, scale: {height:1} }; break;
					case "d1": return { size: { width:	36.48, height:16 }, scale: {height:1} }; break;
					case "d1e": return { size: { width:	55.56, height:16 }, scale: {height:1} }; break;
					case "de": return { size: { width:	74.56, height:16 }, scale: {height:1} }; break;
					case "delivery": return { size: { width:	36.27, height:14.02 }, scale: {height:0.87625} }; break;
					case "dozer_loader": return { size: { width:	39.48, height:14.57 }, scale: {height:0.910625} }; break;
					case "driver": return { size: { width:	63.94, height:30.29 }, scale: {height:1.893125} }; break;
					case "e": return { size: { width:	42.32, height:18.03 }, scale: {height:1.126875} }; break;
					case "excavator": return { size: { width:	31.6, height:14.57 }, scale: {height:0.910625} }; break;
					case "forklift": return { size: { width:	26.6, height:17.59 }, scale: {height:1.099375} }; break;
					case "freezer_c": return { size: { width:	39.25, height:18.03 }, scale: {height:1.126875} }; break;
					case "freezer_set1": return { size: { width:	55.5, height:18.04 }, scale: {height:1.1275} }; break;
					case "freezer_set2": return { size: { width:	67.56, height:18.03 }, scale: {height:1.126875} }; break;
					case "freezer_set3": return { size: { width:	95.64, height:18 }, scale: {height:1.125} }; break;
					case "freezer": return { size: { width:	37.95, height:16.23 }, scale: {height:1.014375} }; break;
					case "gabaryt_set1": return { size: { width:	66.6, height:16 }, scale: {height:1} }; break;
					case "hak": return { size: { width:	38.21, height:18.41 }, scale: {height:1.150625} }; break;
					case "hds_c": return { size: { width:	38.34, height:18.95 }, scale: {height:1.184375} }; break;
					case "hds_set1": return { size: { width:	55.5, height:18.96 }, scale: {height:1.185} }; break;
					case "hds_set2": return { size: { width:	65.7, height:18.95 }, scale: {height:1.184375} }; break;
					case "hds_set3": return { size: { width:	92.81, height:18.95 }, scale: {height:1.184375} }; break;
					case "hds": return { size: { width:	37.95, height:18.41 }, scale: {height:1.150625} }; break;
					case "hooklift": return { size: { width:	37.95, height:16.4 }, scale: {height:1.025} }; break;
					case "izotherm_c": return { size: { width:	39.25, height:18.03 }, scale: {height:1.126875} }; break;
					case "izotherm_set1": return { size: { width:	55.5, height:18.04 }, scale: {height:1.1275} }; break;
					case "izotherm_set2": return { size: { width:	67.5, height:18.03 }, scale: {height:1.126875} }; break;
					case "izotherm_set3": return { size: { width:	95.57, height:18 }, scale: {height:1.125} }; break;
					case "izotherm": return { size: { width:	37.95, height:16.23 }, scale: {height:1.014375} }; break;
					case "jumbo_set1": return { size: { width:	55.25, height:18.03 }, scale: {height:1.126875} }; break;
					case "lifts": return { size: { width:	21.71, height:25.39 }, scale: {height:1.586875} }; break;
					case "laweta_c": return { size: { width:	48.6, height:16 }, scale: {height:1} }; break;
					case "limousine": return { size: { width:	49.51, height:12.99 }, scale: {height:0.811875} }; break;
					case "loader": return { size: { width:	30.58, height:15 }, scale: {height:0.9375} }; break;
					case "mega_c": return { size: { width:	39.25, height:18.03 }, scale: {height:1.126875} }; break;
					case "mega_set1": return { size: { width:	55.32, height:18 }, scale: {height:1.125} }; break;
					case "mega_set2": return { size: { width:	67.5, height:18.03 }, scale: {height:1.126875} }; break;
					case "mega_set3": return { size: { width:	95.75, height:18.03 }, scale: {height:1.126875} }; break;
					case "miniexcavator": return { size: { width:	30.57, height:20.52 }, scale: {height:1.2825} }; break;
					case "minivan": return { size: { width:	37.8, height:16.3 }, scale: {height:0.85} }; break;
					case "moving_floor_c": return { size: { width:	39.25, height:18.03 }, scale: {height:1.126875} }; break;
					case "operators": return { size: { width:	74.19, height:30.13 }, scale: {height:1.883125} }; break;
					case "party": return { size: { width:	57, height:16 }, scale: {height:1} }; break;
					case "personal": return { size: { width:	37.62, height:12.99 }, scale: {height:0.811875} }; break;
					case "platforma_c": return { size: { width:	39.25, height:16 }, scale: {height:1} }; break;
					case "platforma_set1": return { size: { width:	55.5, height:16 }, scale: {height:1} }; break;
					case "platforma_set2": return { size: { width:	67.5, height:16 }, scale: {height:1} }; break;
					case "platforma_set3": return { size: { width:	95.92, height:16 }, scale: {height:1} }; break;
					case "platforma": return { size: { width:	37.95, height:14.02 }, scale: {height:0.87625} }; break;
					case "sanitation_c": return { size: { width:	40.27, height:21.65 }, scale: {height:1.353125} }; break;
					case "school": return { size: { width:	57.32, height:16 }, scale: {height:1} }; break;
					case "scooter": return { size: { width:	28.79, height:19.35 }, scale: {height:1.209375} }; break;
					case "set1": return { size: { width:	55.5, height:18.04 }, scale: {height:1.1275} }; break;
					case "set2": return { size: { width:	67.5, height:18.03 }, scale: {height:1.126875} }; break;
					case "set3": return { size: { width:	95.57, height:18 }, scale: {height:1.125} }; break;
					case "side_tipper_c": return { size: { width:	39.25, height:18.03 }, scale: {height:1.126875} }; break;
					case "side_tipper_set1": return { size: { width:	55.5, height:18.04 }, scale: {height:1.1275} }; break;
					case "side_tipper_set2": return { size: { width:	67.5, height:18.03 }, scale: {height:1.126875} }; break;
					case "side_tipper_set3": return { size: { width:	95.7, height:18.03 }, scale: {height:1.126875} }; break;
					case "silos_set1": return { size: { width:	55.07, height:19.43 }, scale: {height:1.214375} }; break;
					case "tank_c": return { size: { width:	40.27, height:19.05 }, scale: {height:1.190625} }; break;
					case "tank_set1": return { size: { width:	55.3, height:19.06 }, scale: {height:1.19125} }; break;
					case "tank_set2": return { size: { width:	69.58, height:19.05 }, scale: {height:1.190625} }; break;
					case "tank_set3": return { size: { width:	98.88, height:19.05 }, scale: {height:1.190625} }; break;
					case "taxi": return { size: { width:	37.62, height:16.18 }, scale: {height:1.01125} }; break;
					case "tilt_c": return { size: { width:	39.25, height:18.03 }, scale: {height:1.126875} }; break;
					case "tilt_set1": return { size: { width:	55.5, height:18.04 }, scale: {height:1.1275} }; break;
					case "tilt_set2": return { size: { width:	67.5, height:18.03 }, scale: {height:1.126875} }; break;
					case "tilt_set3": return { size: { width:	95.75, height:18.03 }, scale: {height:1.126875} }; break;
					case "tilt": return { size: { width:	37.95, height:16.23 }, scale: {height:1.014375} }; break;
					case "tourist": return { size: { width:	55.08, height:20 }, scale: {height:1.25} }; break;
					case "track_excavator": return { size: { width:	38.38, height:19.03 }, scale: {height:1.189375} }; break;
					case "tractor": return { size: { width:	20.31, height:15.27 }, scale: {height:0.954375} }; break;
					case "trolley_c": return { size: { width:	39.25, height:18.37 }, scale: {height:1.148125} }; break;
					case "trolley_set1": return { size: { width:	55.46, height:18.72 }, scale: {height:1.17} }; break;
					case "warehouse": return { size: { width:	26.6, height:17.59 }, scale: {height:1.099375} }; break;
					case "window_c": return { size: { width:	39.25, height:20 }, scale: {height:1.25} }; break;
					case "window_set1": return { size: { width:	55.5, height:18.04 }, scale: {height:1.1275} }; break;
					case "window": return { size: { width:	37.95, height:17.02 }, scale: {height:1.06375} }; break;
					case "wood_c": return { size: { width:	39.09, height:18.03 }, scale: {height:1.126875} }; break;
					case "wood_set1": return { size: { width:	56.74, height:18.99 }, scale: {height:1.186875} }; break;
					case "wood_set2": return { size: { width:	68.2, height:18.99 }, scale: {height:1.186875} }; break;
					case "wood_set3": return { size: { width:	97.42, height:18.99 }, scale: {height:1.186875} }; break;
					case "Zasób 13": return { size: { width:	55.5, height:18.04 }, scale: {height:1.1275} }; break;
					default: return {
						size: { width: 38.62, height: 12.99 }, scale: {height: 1}
					};
				}
			}
			
			function scale(type) {
				let params = getPropertyFromFile();
				let scale = (params.scale === undefined && params.scale[type] === undefined && params.scale[type] === null) ? props.base : (params.scale[type] * props.base);
				
				return scale;
			}
			
			return { height }
		}
	}
</script>