import {ref} from "vue";
import axios from "axios";
import { h } from 'vue';
import { useConfirm } from 'primevue/useconfirm';
import { useDialog } from 'primevue/usedialog';
import { useUserStore } from './../stores/useUserStore'
import Button from 'primevue/button';
import ActivateDriverError from '@/components/Ad/Cv/Blocks/ActivateDriverError.vue';
import ActivateOperatorError from '@/components/Ad/Cv/Blocks/ActivateOperatorError.vue';
import { storeToRefs } from "pinia";

const cvForm = ref();
const step = ref(-1);
const box = ref();
const type = ref('');
const formPermit = ref({
	type: '',
	expired_at: null,
	country: '',
	attachment: []
});
const editPermit = ref(false);

export function useCv(typeCv) {
	const confirm = useConfirm();
	const dialog = useDialog();
	const userStore = useUserStore();
	const { authenticated } = storeToRefs(userStore);
	type.value = typeCv;

	const onAddPermit = async () => {
          cvForm.value.permits.push(JSON.parse(JSON.stringify(formPermit.value)));
          formPermit.value = {
			type: '',
			expired_at: null,
			country: '',
			attachment: []
		};
          editPermit.value = false;
		await onLoadCvForm(false);
		onEditBox('');
     }

     const onEditPermit = (type) => {
          const item = cvForm.value.permits.find((e) => e.type === type);
          formPermit.value = JSON.parse(JSON.stringify(item));
     }

	const onDeleteCv = (type) => {
		confirm.require({
			message: 'Pamiętaj, ze CV mozesz dezaktywować i nie będzie wyświetlane w serwisie DriverBook',
			header: 'Czy chcesz usunąć CV?',
			acceptClass: 'p-button-outlined',
			acceptLabel: 'Tak',
			rejectLabel: 'Nie',
			accept: async () => {
			    await axios.delete(`account/cv/${type}`);
			    await onLoadCvForm(false);
			},
		});
	}

     const onRemovePermit = async (type) => {
		await axios.delete(`account/cv/permits/${type}`)
          const index = cvForm.value.permits.findIndex((e) => e.type === type);
          cvForm.value.permits.splice(index, 1);
     }

	const onEditBox = (value) => {
          box.value = value;
     }

	const getAttachments = (type) => {
		if (type == '') {
			return [];
		}
		const attachments = cvForm.value.documents[type].attachment;
		let array = {};
		const front = attachments.find(e => e.side === 'front');
		const back = attachments.find(e => e.side === 'back');
	
		if (front) {
			array.front = front.path;
		}
		if (back) {
			array.back = back.path;
		}
	
		return array;
	}

	const onLoadCvForm = async (setStep) => {
		const changeStep = setStep === undefined ? true : setStep;
		if (authenticated.value) {
			try {
				const request = await axios.get('account/cv');
				const { data } = request;
				cvForm.value = data;

				if (changeStep && type.value) {
					onChangeStep(cvForm.value.step[type.value]);
				}
			} catch (e) {
				console.error(e);
			}
		}
	}

	const onApplyCv = async (hash) => {
		try {
			const request = await axios.get(`account/cv/apply/${hash}`);
			const { data } = request;
			return data;
		} catch(e) {
			return e.response.data;
		}
	}

	const onChangeStatus = async (type) => {
		try {
			let request = await axios.post('account/cv/status', { status: cvForm.value.status[type], type });
			if (request.data.errors) {
				alert(request.data.errors);
			}
		} catch(e) {
			if (e.response.status === 422) {
				cvForm.value.status[type] = false;
				if (e.response.data.errors === 'drivingLicense') {
					const dialogRef = dialog.open(ActivateDriverError, {
						props: {
							header: 'Błąd aktywacji CV',
							style: {
							    width: '50vw',
							},
							breakpoints:{
							    '960px': '75vw',
							    '640px': '90vw'
							},
							modal: true
						},
						templates: {
							footer: () => {
							    return [
								   h(Button, { label: "OK", onClick: () => dialogRef.close(), autofocus: true})
							    ]
							}
						},
					})
				} else if (e.response.data.errors === 'certificateOfQualification') {
					const dialogRef = dialog.open(ActivateOperatorError, {
						props: {
							header: 'Błąd aktywacji CV',
							style: {
							    width: '50vw',
							},
							breakpoints:{
							    '960px': '75vw',
							    '640px': '90vw'
							},
							modal: true
						},
						templates: {
							footer: () => {
							    return [
								   h(Button, { label: "OK", onClick: () => dialogRef.close(), autofocus: true})
							    ]
							}
						},
					})
				}
			}
		}
	}

	const onChangeStep = (stepValue) => {
		step.value = stepValue;
	}

	return {
		box,
		cvForm,
		type,
		step,
		editPermit,
          formPermit,
		getAttachments,
		onEditBox,
		onAddPermit,
		onApplyCv,
		onChangeStatus,
          onEditPermit,
          onRemovePermit,
		onLoadCvForm,
		onDeleteCv,
		onChangeStep
	}
}