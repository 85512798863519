import { ref } from 'vue';
const show_usercontentmenu = ref(false);

export function useUi() {

	function addBodyClass(className) {
		if (className !== null) {
			document.body.classList.add(className);
		}

		document.body.classList.remove('bg-gray-200', 'null');
	}

	const hideContentMenu = () => {
		show_usercontentmenu.value = false;
	}

	function scaleSvg(unit, base) {
		return unit * base;
	}

	return {
		addBodyClass,
		scaleSvg,
		hideContentMenu,
		show_usercontentmenu
	}
}