<template>
	<InputField :label="label">
		<template v-if="hasSlotLabel" #label>
			<slot name="label" />
		</template>
		<PrimeAutoComplete v-bind="$attrs" v-model="model" :suggestions="filtered" @item-select="onUpdateModel" field="name" @complete="search($event)">
			<template #item="slotProps">
				<slot name="item" :slotProps="slotProps"></slot>
			</template>
		</PrimeAutoComplete>
	</InputField>
</template>

<script setup>
	import { ref, useSlots } from 'vue';
	import axios from "axios";
	
	const slots = useSlots();

	const props = defineProps({
		path: String,
		label: String,
		params: Object
	});

	const emit = defineEmits(['update:modelValue']);

	const filtered = ref([]);
	const model = ref('');
	let hasSlotLabel = ref(false);

	const objectToUrlParams = () => {
		const query = Object.entries(props.params).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
    	return `?${query}`;
	}

	const onUpdateModel = (e) => {
		model.value = e.value.name;
		emit('update:modelValue', model.value);
	}
			
	const search = (e) => {
		let url = `${props.path}${e.query}${objectToUrlParams()}`;
		axios.get(url).then(({data}) => {
			filtered.value = data;
		});
	}
			
	if (slots.label) {
		hasSlotLabel.value = true;
	}
			
</script>