<template>
	<div>
		<h4 v-if="title" class="fs-14 mb-3"><Text :i="title" /></h4>
		<p v-if="subtitle" class="fs-12 text-muted mb-20 d-block"><Text :i="subtitle" /></p>

		<div class="row">
			<div v-if="country_code" :class="cols.country">
				<InputDropdownCountry :error="errors['country']" :all="false" optionDisabled="status" name="country" :required="true" label="ui.location.country" @change="onSelectCountry" v-model="form.country_code" />
			</div>
			<div :class="cols.region">
				<InputDropdown @click.prevent="isSelectedCountry" :error="errors['region']" name="region" label="ui.location.region" :required="required['region']" @change="onSelectRegion" :disabled="states.length == 0" :options="states" v-model="form.region" />
			</div>
			<div v-if="region_pause" class="col-md-6"></div>
			<div :class="cols.city">
				<div class="row">
					<div class="col flex-145">
						<InputText @click.prevent="isSelectedState" :error="errors['post_code']" name="post_code" label="ui.location.postcode" :required="required['post_code']" :disabled="form.country_code == null || form.region == null" v-model="form.post_code" />
					</div>
					<div class="col">
						<InputAutocomplete @click.prevent="isSelectedState" :error="errors['city']" name="city" :forceSelection="true" :required="required['city']" :disabled="form.country_code == null || form.region == null" :path="path_cities" v-model="form.city">
							<template #label>
								<Text i="ui.location.city" />
							</template>
						</InputAutocomplete>
					</div>
				</div>
			</div>

			<div v-if="show_address" :class="cols.street">
				<div class="row">
					<div class="col">
						<InputText @click.prevent="isSelectedState" label="ui.location.street" :error="errors['street']" :required="required['street']" :disabled="form.country_code == null || form.region == null" v-model="form.street" />
					</div>
					<div class="col flex-110">
						<InputText @click.prevent="isSelectedState" label="ui.location.street_no" :error="errors['street_no']" :required="required['street_no']" :disabled="form.country_code == null || form.region == null" v-model="form.street_no" />
					</div>
					<div class="col flex-110">
						<InputText @click.prevent="isSelectedState" label="ui.location.local_no" :error="errors['local_no']" :disabled="form.country_code == null || form.region == null" v-model="form.local_no" />
					</div>
				</div>
			</div>
		</div>

		<Dialog :header="t('ui.alert')" v-model:visible="dialog.status" modal>
			<div style="width:300px;">{{ dialog.text }}</div>
			<template #footer>
				<ui-button label="OK" icon="pi pi-check" @click="dialog.status = false" />
			</template>
		</Dialog>
	</div>
</template>

<script>
	import Dialog from "primevue/dialog";
	import { useLibrary } from "../../composables/useLibrary";
	import {computed, ref, watch} from 'vue';
	import { useI18n } from 'vue-i18n';
	
	export default {
		props: {
			errors: {
				type: Array,
				default: []
			},
			form: Object,
			title: String,
			subtitle: String,
			region_pause: {
				type: Boolean,
				default: false
			},
			show_address: {
				type: Boolean,
				default: true
			},
			required: {
				type: Object,
				default: {
					country: true,
					region: true,
					zip_code: true,
					city: true,
					street: true,
					street_no: true
				}
			},
			country: {
				type: Boolean,
				default: true
			},
			cols: {
				type: Object,
				default: {
					country: 'col-md-12',
					region: 'col-md-12',
					city: 'col-md-12',
					street: 'col-md-12'
				}
			},
			load: {
				type: Boolean,
				default: false
			}
		},
		components: {
			Dialog,
		},
		setup(props) {
			const { StatesGet } = useLibrary();
			const { t } = useI18n();
			const states = ref([]);
			const dialog = ref({
				status: false,
				text: ''
			});
			
			const path_cities = computed(() => {
				return `library/cities/${props.form.country_code}/${props.form.region}/`;
			});
			
			if (props.form.country_code) {
				StatesGet(props.form.country_code).then(({data}) => {
					states.value = data.data;
				});
			}

			const onLoadStates = (code, clear) => {				
				StatesGet(code).then(({data}) => {
					states.value = data.data;

					let find = states.value.find(s => s.id == props.form.region);
					if (!find) {
						props.form.region = '';
						props.form.zip_code = '';
						props.form.city= '';
						props.form.street = '';
						props.form.street_no = '';
						props.form.local_no = '';
					}
				});
			}
			
			const onSelectCountry = (e) => {
				let code = e.value;
				onLoadStates(code);
			}
			
			const onSelectRegion = (e) => {
				props.form.city = null;
				props.form.zip_code = null;
				props.form.street = null;
				props.form.street_no = null;
				props.form.local_no = null;
			}
			
			const isSelectedCountry = () => {
				if (props.form.country_code == null) {
					dialog.value.status = true;
					dialog.value.text = t('ui.location.select.country');
				}
			}
			
			const isSelectedState = () => {
				if (props.form.country_code == null) {
					dialog.value.status = true;
					dialog.value.text = t('ui.location.select.country');
				}
				else if (props.form.region == null) {
					dialog.value.status = true;
					dialog.value.text = t('ui.location.select.region');
				}
			}

			watch(() => props.form.country_code, (before, after) => {
				console.log(before, after);
				if (props.form.country_code !== null && before !== null && after !== null) {
					let reset = props.load == true ? false : true;
					onLoadStates(props.form.country_code, reset);
				}
				if (after == null || before == null) {
					StatesGet(props.form.country_code).then(({data}) => {
						states.value = data.data;
					});
				}
			});

			watch(() => props.form, (after, before) => {

				if (before.country_code != after.country_code) {
					onLoadStates(after.country_code);
				}

			}, { deep: true} );
			
			return {
				t,
				path_cities,
				states,
				dialog,
				isSelectedCountry,
				isSelectedState,
				onSelectRegion,
				onSelectCountry
			}
		}
	}
</script>