<template>
	<ul class="ui__breadcrumb" v-if="breadcrumbs">
		<li v-for="breadcrumb, index in breadcrumbs" :key="index">
			<span v-if="index > 0" class="separator">/</span>
			<router-link v-if="breadcrumb.url != '' && breadcrumb.url !== undefined" :to="breadcrumb.url">
				<Text :i="breadcrumb.label" />
			</router-link>
			<Text v-else :i="breadcrumb.label" />
		</li>
	</ul>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const breadcrumbs = ref([]);

const createBreadcrumbs = () => {
	const array = [];
	route.matched.map(e => {
		const meta = e.meta;
		if (meta.breadcrumbs) {
			array.push(meta.breadcrumbs)
		}
	});
	breadcrumbs.value = array;
}

watch(() => route, () => { createBreadcrumbs(); }, { deep: true });

onMounted(() => createBreadcrumbs());

</script>