<template>
     <router-link :to="href"><slot></slot></router-link>
</template>

<script lang="ts">
export default {
     name: "UiHref"
}
</script>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const router = useRouter();
const props = defineProps({
     to: [Object, String],
});

const { locale } = useI18n();

const href = computed(() => {
     return typeof(props.to) == 'object' ? {name: props.to.name, params: {...props.to.params, lang: locale.value}} : props.to;
});
</script>