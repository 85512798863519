<template>
	<div class="admin__dropdown" ref="target">
		<div class="d-flex justify-content-between mb-16">
			<h3 class="fs-18 mb-0">{{ label }}</h3>
			<button @click="onCloseLeftPanelHandler" class="btn-clear btn-clear-close"></button>
		</div>
		<perfect-scrollbar style="height:calc(100vh - 130px)">
			<draggable :list="options" item-key="id">
				<template #item="{element}">
					<div class="py-5">
						<div class="d-flex align-items-center">
							<div class="flex-fill">
								<input-text v-model="element.name" @blur="onUpdateOption(element.hash)" class="mb-0" />
							</div>
							<div class="ml-12">
								<InputSwitch v-model="element.status" @change="onUpdateOption(element.hash)" />
							</div>
							<button @click.prevent="onRemoveOption(element.hash)" class="btn-clear"></button>
						</div>
						<span v-if="source && getSourceText(element.hash)" class="fs-11 text-muted">{{ t('ui.source') }}: {{ getSourceText(element.hash).name }}</span>
					</div>
				</template>
			</draggable>
		</perfect-scrollbar>

		<div class="row">
			<div class="col-6">
				<input-dropdown optionValue="code" v-model="source" :label="t('ui.source')" @change="onLoadSourceHandler" :showClear="true" :options="languages"></input-dropdown>
			</div>
			<div class="col-6">
				<PrimeButton class="w-100" @click="addOption" :label="t('admin.addposition')"></PrimeButton>
			</div>
		</div>
	</div>
</template>

<script setup>
import draggable from 'vuedraggable';
import { useApi } from '@/composables/useApi';
import { onClickOutside } from '@vueuse/core';
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTranslation } from '@/composables/useTranslation';

const props = defineProps({
	options: Array,
	label: String,
	table: String,
	parent: Object
});

const emit = defineEmits(['update:open']);
const { t } = useI18n();
const source = ref(null);
const languages = ref([]);
const sorceRequest = ref([]);

const { makeApiRequest } = useApi();
const target = ref(null);
const { onLoadLanguagesList } = useTranslation();

onClickOutside(target, event => {
	onCloseLeftPanelHandler()
});

const onCloseLeftPanelHandler = () => {
	emit('update:open', false);
}

onMounted(async() => {
	const request = await onLoadLanguagesList();
	languages.value = request.items === undefined ? request : request.items;
})

const onLoadSourceHandler = async() => {
	const url = `/admin/option/${props.table}?lang=${source.value}`;
	try {
		const data = await makeApiRequest(url, 'post', props.parent);
		sorceRequest.value = data;
	} catch (e) {
		console.log(e);
	}
}

const addOption = async () => {
	const id = props.options.length;

	const url = `/admin/option/${props.table}`;
	try {
		const data = await makeApiRequest(url, 'put', {...props.parent, ...{name: ''}});
		props.options.push(data);
	} catch(e) {
		console.log(e);
	}
}

const onRemoveOption = async (hash) => {
	const url = `/admin/option/${props.table}/${hash}`;
	try {
		await makeApiRequest(url, 'delete');
		const index = props.options.findIndex(e => e.hash === hash);
		props.options.splice(index, 1);
	} catch(e) {
		console.log(e);
	}
}

const onUpdateOption = async (hash) => {
	const url = `/admin/option/${props.table}/${hash}`;
	try {
		const item = props.options.find(e => e.hash === hash);
		await makeApiRequest(url, 'post', item);
	} catch(e) {
		console.log(e);
	}
}

const getSourceText = (hash) => {
	return sorceRequest.value.find(e => e.hash === hash);
}

</script>

<style lang="scss">
.admin__dropdown {
	position: fixed;
	right:0;
	top:0;
	bottom:0;
	width:400px;
	z-index:100;
	background: #fff;
	box-shadow: 0 0 25px rgba(0,0,0,.2);
	padding:1.5rem;
}
</style>