<template>
	<span :class="{'admin__text' : (translateMode && isAdmin)}">
		<span v-html="text"></span>
		<span v-if="!text && (translateMode && isAdmin)">...</span>
		<span v-if="isAdmin && translateMode" @click="onPressCallback($event)" @keypress="onPressCallback($event)" class="admin__text__edit"><i class="pi pi-pencil"></i></span>
	</span>
</template>

<script setup>
	import { inject, computed } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { useUserStore } from "../../stores/useUserStore";
	import { storeToRefs } from 'pinia';

	const props = defineProps({
		i: String,
		params: Object,
		lower: {
			type: Boolean,
			default: false
		},
		upper: {
			type: Boolean,
			default: false
		}
	});

	const user_store = useUserStore();
	const { user, isAdmin, isModerator } = storeToRefs(user_store);
	const onSetTranslationKey = inject("onSetTranslationKey");
	const { t } = useI18n();
	const translateMode = inject('translateMode');
			
	function onPressCallback(e) {
		setTimeout(() => { onSetTranslationKey(props.i); }, 100);
		if (e.stopPropagation) {
			e.stopPropagation();
		}
		e.preventDefault();
	}

			const text = computed(() => {
				if (props.i !== null && props.i !== undefined) {
					let text = t(props.i, props.params);
					if (text) {
						if (props.lower) {
							return text.toLowerCase();
						}
						if (props.upper) {
							return text.toUpperCase();
						}
						return text;
					}
				}
				return null;
			});
</script>