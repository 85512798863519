<template>
	<ul class="ad__list">
		<slot />
	</ul>
</template>

<script>
import {onBeforeMount, reactive, ref} from 'vue';
export default {
	setup(props, { slots }) {
		const elements = ref([]);
		
		onBeforeMount(() => {
			if (slots.default) {
				elements.value = slots.default().filter((child) => child.type.name === "AdItemDetailListItem");
			}
		});
		
		return { elements }
	}
}
</script>