<template>
	<div>
		<div class="input__label" v-if="label && !hide_label"><Text :i="label" /></div>
		<div class="input__label d-block mb-8" v-if="tooltip"><Text :i="tooltip" /></div>
		<div class="row">
			<div class="pl-45 mb-10 font-medium" v-if="header.length > 0">
				<div class="d-flex border-bottom">
					<div class="col mb-5" v-for="item, index in header" :key="index">
						<span v-if="index > 0" :class="{'pl-20': index > 0}" class="text-muted" style="margin-left: -20px; margin-right: 20px;">/</span>
						<Text :i="item" />
					</div>
				</div>
			</div>
			<div :class="classRow" v-for="item in options" :key="item.id" :style="item.styleItem">
				<InputCheckbox v-model="checked" :class="item.classInput" @input="onChecked($event)" :value="item.id">
					<div class="d-flex" :class="item.className">
						<div class="col"><Text :i="item.name" /></div>
						<div class="col pl-40" v-if="item.option">
							<span class="text-muted" style="margin-left: -20px; margin-right: 20px;">/</span>
							<Text :i="item.option" />
						</div>
					</div>
				</InputCheckbox>
			</div>
		</div>
	</div>
</template>

<script>
import {computed, ref, watch} from "vue";

	export default {
		props: {
			col: {
				type: [Object, String, Number],
				default: 1
			},
			hide_label: {
				type: Boolean,
				default: false,
			},
			tooltip: String,
			modelValue: Array,
			label: String,
			options: Array,
			header: {
				type: Array,
				default: [],
			}
		},
		emits:["modelValue"],
		setup(props, { emit }) {
			const checked = ref([]);

			const classRow = computed(() => {
				if (props.col == 1) {
					return 'col-12';
				}
				else if (props.col == 2) {
					return 'col-6';
				}
				else if (props.col == 3) {
					return 'col-4';
				}
			});
			
			const onChecked = (e) => {
				emit("update:modelValue", e);
			}
			
			const updateModelValue = () => {
				if (props.modelValue == undefined) {
					checked.value = props.modelValue;
				}
			}
			
			watch(() => props.modelValue, () => { updateModelValue() }, {deep: true});

			return {
				checked,
				onChecked,
				classRow
			}
		}
	}
</script>