<template>
	<div v-if="error">
		<ul class="m-0 p-0 list-unstyled" v-if="typeof error == 'object'" >
			<template v-for="(e, index) in error">
				<li class="pt-2 fs-12 text-danger"  v-if="e != 'required'" :key="index">{{ e }}</li>
			</template>
		</ul>
		<span class="fs-12 d-block text-danger" v-else>{{ error }}</span>
	</div>
</template>

<script>
	export default {
		props: {
			error: [String, Object]
		}
	}
</script>