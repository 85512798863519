<template>
	<div class="content__main__app">
			<UiHeader id="header" :class="{'sticky': headerClass}" />
			<div ref="footer" class="content__main">
				<div class="content__main__ps">
					<UiTitle id="title" :title="title" />
					<div id="title" v-if="userProxyId !== null" class="admin__impersonate__alert">
						<div class="container">
							<div class="d-flex justify-content-between">
								<span>Jesteś zalogowany jako: {{ userProxy.email }}</span>
								<span @click="onLeaveAccount">Opuść gabinet</span>
							</div>
						</div>
					</div>
					<div class="content__main__body" :style="`min-height:${size}px`">
						<router-view></router-view>
					</div>
					<UiFooter id="footer" />
				</div>
			</div>
			<UiTabmenu />
			<UiPrompt />
			<PrimeConfirmDialog />
			<PrimeToast position="bottom-center" />
			<AdminTranslation />
			<DynamicDialog />
		<DialogMeta />
	</div>
</template>

<script setup>
import UiHeader from '@/components/Ui/Header.vue';
import UiTabmenu from '@/components/Ui/Tabmenu.vue';
import UiTitle from '@/components/Ui/Title.vue';
import DynamicDialog from 'primevue/dynamicdialog';
import { ref, computed, inject, provide, onMounted, watch } from "vue";
import { useUi } from "../composables/useUi";
import { useWindowSize } from '@vueuse/core';
import { useUserStore } from '../stores/useUserStore';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import DialogMeta from '@/components/Dialog/Meta.vue';
import { useI18n } from 'vue-i18n';
import useDomain from '../composables/useDomain';

const y = ref(0);
const { addBodyClass } = useUi();
const size = ref(0);
const { height } = useWindowSize();
const main = useUserStore();
const { setUserProxyId } = main;
const { userProxy, userProxyId } = storeToRefs(main);
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const { init } = useDomain();

const title = ref('ui.title');
const translateMode = ref(false);

const setTitle = (newTitle) => title.value = newTitle;
const setTranslateMode = (mode) => translateMode.value = mode;

provide("setTitle", setTitle);
provide("setTransalteMode", setTranslateMode);
provide("translateMode", translateMode);

watch(() => route.path, () => {
	title.value = 'ui.title';
})

onMounted(() => {
	addBodyClass(null);
	init();
	let footer = document.getElementById('footer');
	let title = document.getElementById('title');
	let header = document.getElementById('header');
			
	size.value = height.value - (footer.offsetHeight + title.offsetHeight + header.offsetHeight + 10);
});
const updateScroll = () => y.value = window.scrollY;

const headerClass = computed(() => y.value > 5 ? true : false);
window.addEventListener('scroll', updateScroll);
		
const translation_mode = inject("translation_mode");

const onLeaveAccount = () => {
	setUserProxyId(null);
	router.push('/pl/admin');
}
</script>