import axios from "axios";
import { useLanguageStore } from "@/stores/useLanguageStore";
import { ref } from "vue";

const domainsItems = ref([]);

export default function() {

    const { setLanguages } = useLanguageStore();

    const onLoadLanguage = (items) => {
        const array = [];
        if (items) {
            items.map(e => {
                array.push({
                    name: e.name,
                    locale: e.locale,
                    code: e.code
                });
            });
        }
        setLanguages(array);
    }

    const onLoadDomains = async() => {
        const request = await axios.get('/admin/setting/domain');
        const { data } = request;
        return data;
    }

    const init = async() => {
        const request = await axios.get('init');
        const { data } = request;
        onLoadLanguage(data.available_languages);
    }

    return {
        init,
        onLoadDomains
    }
}