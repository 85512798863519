<template>
	<div>
		<div class="d-flex flex-column" v-if="!find_mode">
			<div class="mb-10 fs-12 text-muted">
				<InlineSvg src="/icon/pin.svg" height="18" class="mr-10" />
				<span>Lokalizacja bazy transportowej</span>
			</div>
			<div class="d-flex">
				<UiTextFlag :flag="flag">
					<span @click="onChangeFindMode(true)" class="text-primary text-pointer">
						<span v-html="location.replace('(', '(')"></span>
					</span>
				</UiTextFlag>
			</div>
		</div>
		<div v-if="find_mode" class="w-100">
			<InputAutocomplete class="mb-0" @item-select="onSelected" placeholder="Wybierz" :forceSelection="true" path="/option/cities/" :params="{search_in_state:true, search_in_country:true}" label="ad.city.region.country" @keyup.enter="onChangeFindMode(false)" ref="target" v-model="find">
				<template v-slot:item="{slotProps}">
					<div>
						<UiTextFlag class="" :flag="slotProps.item.country.toLowerCase()">{{ slotProps.item.name }}</UiTextFlag>
					</div>
				</template>
				<template #label>
					<div class="d-flex justify-content-between w-100">
						<span><Text i="ad.filter.location" /></span>
						<span @click="getMyLocation" class="text-primary text-pointer"><Text i="ad.filter.getLocation" /></span>
					</div>
				</template>
			</InputAutocomplete>
		</div>
	</div>
</template>

<script setup>
	import { ref, watch } from "vue";
	import { useGeolocation, onClickOutside } from '@vueuse/core';

	const find_mode = ref(true);
	const location = ref('Polska');
	const find = ref(null);
	const target = ref(null);
	const flag = ref('pl');
	const country_code = ref('');
	const state_id = ref('');
	const type = ref('');
	const id = ref('');

	const emit = defineEmits(['update:modelValue']);

	find.value = JSON.parse(JSON.stringify(location.value));

	const onChangeFindMode = (mode) => {
		find_mode.value = mode;
	}

	const props = defineProps({
		modelValue: String
	})

	watch(() => find.value, () => {
		emit('update:modelValue', find.value);
	})

	watch(() => props.modelValue, () => {
		find.value = props.modelValue;
	});
			
			const onSelected = (e) => {
				location.value = e.value.name;
				type.value = e.value.type;
				country_code.value = e.value.country;
				id.value = e.value.id;
				flag.value = e.value.country.toLowerCase();
			}

			onClickOutside(target, (event) => {
				//onChangeFindMode(false);
			})
			
			const getMyLocation = () => {
				const { coords, locatedAt, error } = useGeolocation();
				let lat = coords.value.latitude;
				let lon = coords.value.longitude;

				if (lat && lon) {
					let url = `https://nominatim.openstreetmap.org/reverse?format=json&accept-language=fr&lat=${lat}&lon=${lon}`;
				}
			}
</script>
