<template>
	<div class="d-flex">
		<div class="flex-fill">
			<InputField :error="error" :class="className" :label="label" :help="help" :helpText="helpText">
				<PrimeInputDropdown v-bind="$attrs" v-model="value" :options="values" optionLabel="name" :optionValue="optionValue" appendTo="self" dataKey="id">
					<template #option="slotProps">
						<slot name="option" :slotProps="slotProps">
							<Text :i="slotProps.option.name" />
						</slot>
					</template>
					<template #value="slotProps">
						<slot name="value" :slotProps="slotProps">
							<Text :i="labelText" />
						</slot>
					</template>
				</PrimeInputDropdown>
			</InputField>
		</div>
		<div v-if="admin && isAdmin" class="ml-10">
			<InlineSvg src="/icon/primary/pencil-thin.svg" @click="onOpenLeftPanelHandler" width="18" class="mt-15 cursor-pointer" />
		</div>
		<AdminDropdown v-if="isAdmin && admin && openLeftPanel" v-model:open="openLeftPanel" :label="label" :options="values" :table="table" :parent="parent" />
	</div>
</template>

<script setup>
	import { computed, ref, onMounted, watch } from 'vue';
	import {useUserStore} from "@/stores/useUserStore";
	import {storeToRefs} from "pinia";
	import AdminDropdown from "@/components/Admin/Dropdown/index.vue";
	import { useApi } from "@/composables/useApi";
	import { useI18n } from "vue-i18n";

	const userStore = useUserStore();
	const { isAdmin } = storeToRefs(userStore);
	const { makeApiRequest } = useApi();
	const { locale } = useI18n();

	const props = defineProps({
		table: String,
		label: String,
		parent: Object,
		options: Array,
		className: String,
		help: {
			type: Boolean,
			default: false
		},
		helpText: String,
		modelValue: [Number, String],
		admin: {
			type: Boolean,
			default: false
		},
		optionValue: {
			type: String,
			default: 'id'
		},
		error: [String, Array, Object]
	});

	const value = ref();
	const values = ref([]);
	const openLeftPanel = ref(false);

	onMounted(() => {
		value.value = props.modelValue;
		values.value = props.options;
		if (props.table && props.parent) {
			onLoadOptionsItems();
		}
	});

	watch(() => props.options, () => {
		values.value = props.options;
	}, { deep: true });

	watch(() => props, () => {
		value.value = props.modelValue;
	}, { deep: true });

	watch(() => locale.value, () => {
		if (props.table && props.parent) {
			onLoadOptionsItems();
		}
	}, );
	
	const onOpenLeftPanelHandler = () => {
		openLeftPanel.value = true;
	}

	const onLoadOptionsItems = async() => {
		if (props.table && props.parent) {
			let url = `/option/${props.table}`;
			if (isAdmin.value) {
				url = `/admin/option/${props.table}`;
			}
			try {
				const data = await makeApiRequest(url, 'post', props.parent);
				values.value = data;
			} catch(e) {
				console.log(e);
			}
		}
	}
			
	const labelText = computed(() => {
		if (props.modelValue == null) {
			return '&nbsp;';
		}
		else if (values && values.value) {
			let item = values.value.find(i => i[props.optionValue] === props.modelValue);
			if (item) {
				return item.name;
			}
		}
		return '&nbsp;';
	});

</script>