import {ref} from "vue";
import axios from "axios";
import { storeToRefs } from "pinia";
import { useCategoryStore } from "../stores/useCategoryStore";
import { useRoute } from 'vue-router';

export function useCategory() {

	const Category = ref({});
	const route = useRoute();

	const categoryStore = useCategoryStore();
	const { list } = storeToRefs(categoryStore);
	const { findCategory } = categoryStore;

	async function CategoryGet(id) {
		return await findCategory(id);
		//return await axios.get(`ad/category${id ? '/'+id : ''}`);
	}

	async function CategoryGetById(id) {
		return await axios.get(`ad/category${id ? '/'+id : ''}`);
	}

	async function CategoryHash(hash) {
		return await axios.get(`ad/category/hash/${hash}`);
	}

	/**
	 * Generuje obiekt na podstawie obiektu params z useRoute
	 * @returns object
	 */
	const CategoryFromSlug = () => {
		const { params } = route;
		let lang = null;
		let category = null;
		let subcategory = null;
		let subcategory2 = null;

		if (params.lang) { category = params.lang }
		if (params.slug_category) { category = params.slug_category }
		if (params.slug_subcategory) { subcategory = params.slug_subcategory }
		if (params.slug_subcategory2) { subcategory2 = params.slug_subcategory2 }

		return {
			lang,
			category,
			subcategory,
			subcategory2
		}
	}

	const CategroryAccess = ref([
		{activity_type: 2, subcategory: [2,3,18,39,44,92,93,98,107,110,113,114,117,121,122,123,124,125,126]},
		{activity_type: 3, subcategory: [2,3,18,39,44,123,124,125,126]},
		{activity_type: 4, subcategory: [2,3,18,113,114,117,121,122]},
		{activity_type: 5, subcategory: [2,3,18,113,114,117,121,122]},
		{activity_type: 6, subcategory: [2,3,18,44,92,93,98,107,110]},
		{activity_type: 7, subcategory: [2,3,18,39,44,123,124,125,126]},
		{activity_type: 8, subcategory: [2,3,18,44]},
		{activity_type: 9, subcategory: [2,3,18,44,92,93,98,107,110]},
		{activity_type: 10, subcategory: [2,3,18,39,44,92,93,98,107,110]},
		{activity_type: 11, subcategory: [2,3,18,39,44,92,93,98,107,110]},
		{activity_type: 12, subcategory: [2,3,18,39,92,93,98,107,110]},
		{activity_type: 13, subcategory: []},
		{activity_type: 14, subcategory: [2,3]}
	]);

	return { Category, CategoryGet, CategoryGetById, CategoryFromSlug, CategoryHash, CategroryAccess }
}