<template>
	<div class="row">
		<div class="col-md-6" v-for="(item, index) in model" :key="index">
			<div class="d-flex">
				<div class="col flex-38" v-if="findSocial(item.society)">
					<InlineSvg class="mt-14" :src="findSocial(item.society).icon" width="22" />
				</div>
				<div class="col">
					<InputText label="" v-model="item.url" :placeholder="`${t('account.company.social.placeholder')} ${findSocial(item.society).name}`" />
				</div>
				<div class="col flex-24">
					<div class="mt-10 ml-10">
						<button @click="onRemove(index)" class="btn-clear"></button>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div class="row">
		<div class="col-md-6">
			<div v-if="show">
				<div class="d-flex">
					<div class="col">
						<InputDropdown label="account.company.social.title" @change="onChange" :options="items" />
					</div>
					<div class="col flex-24">
						<div class="mt-10 ml-10">
							<button @click="onHideSociety" class="btn-clear"></button>
						</div>
					</div>
				</div>
			</div>
			
			<div class="d-flex mb-20">
				<div class="col flex-38" v-if="count > 0"></div>
				<div @click.prevent="onAddSociety" class="btn-add text-primary font-medium text-pointer">
					<i class="pi pi-plus"></i>
					<Text i="account.company.social.add" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted } from 'vue';
import {ref, watch} from 'vue';
import { useI18n } from "vue-i18n";
	
	const props = defineProps({
		modelValue: [Object, Array]
	});

	const emit = defineEmits(['update:modelValue']);

	const { t } = useI18n();
	
	const items = ref([
		{id: 'facebook', name: 'FaceBook', icon: '/icon/society/facebook.svg'},
		{id: 'youtube', name: 'YouTube', icon: '/icon/society/youtube.svg'},
		{id: 'linkedin', name: 'LinkedIn', icon: '/icon/society/linkedin.svg'},
		{id: 'instagram', name: 'Instagram', icon: '/icon/society/instagram.svg'}
	]);

	const model = ref([]);
	const show = ref(false);
			
	const onAddSociety = () => {
		show.value = true;
	}
			
	const onChange = (e) => {
		model.value.push({url: null, society: e.value});
		show.value = false;
	}
			
	const findSocial = (social) => {
		return items.value.find(s => s.id == social);
	}
			
	const onRemove = (index) => {
		model.value.splice(index, 1);
	}
			
	const onHideSociety = () => {
		show.value = false;
	}

	onMounted(() => {
		model.value = props.modelValue;
	})

	watch(() => props.modelValue, () => {
		model.value = props.modelValue;
	}, { deep: true});
			
	watch(() => model.value, () => {
		emit("update:form", model.value);
	}, { deep: true});
			

</script>
