<template>
	<div>
		<div class="fs-12 text-muted mb-10" v-if="label">{{ label }}</div>
		<div class="row">
			<div :class="className" v-for="(item, index) in items" :key="index">
				<div class="mt-3 font-medium"><Text :i="item" /></div>
				<div class="mb-6 fs-12 text-muted"><Text i="ad.adr.select" /></div>
				
				<div class="my-10">
					<InputCheckbox label="ad.adr1" v-model="model[index+1].adr1" :binary="true" />
					<InputCheckbox label="ad.adr2" v-model="model[index+1].adr2" :binary="true" />
					<InputCheckbox label="ad.adr3" v-model="model[index+1].adr3" :binary="true" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {ref, watch} from "vue";
	
	export default {
		props: {
			modelValue: Object,
			cols: {
				type: Number,
				default: 2,
			},
			label: {
				type: String,
				default: 'ADR'
			}
		},
		setup(props, { emit }) {
			const model = ref({1: {}, 2: {}});
			const className = ref('col-md-6');

			if (props.cols !== undefined) {
				if (props.cols == 1) {
					className.value = 'col-md-12';
				}
			}

			if (props.modelValue !== undefined) {
				model.value = JSON.parse(JSON.stringify(props.modelValue));
			}
			
			const items = ref([
				'ad.adr4', 'ad.adr5'
			]);

			watch(() => props.modelValue, () => {
               	model.value = props.modelValue;
            	}, { deep: true });
			
			watch(() => model.value, () => {
				emit("update:modelValue", model.value);
			}, { deep: true})
			
			return { items, model, className }
		}
	}
</script>