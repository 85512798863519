<template>
    <v-menu>
        <template v-slot:activator="{ props }">
            <v-btn class="text-none" v-bind="props" variant="outlined">
				<div class="d-flex">
					<span>PL</span>
					<span>Polski</span>
				</div>
			</v-btn>
        </template>

        <v-list>
            <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-title>{{ item.label }}</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script setup>
    import { useLanguageStore } from '../../stores/useLanguageStore';
    import { computed, onMounted } from "vue";
    import { storeToRefs } from "pinia";
    import { useI18n } from "vue-i18n";
	import { useUserStore } from '../../stores/useUserStore';
	import { useLanguage } from '../../composables/useLanguage';
	import useLocale from "@/composables/useLocale";
	import { useRouter } from 'vue-router';

    const languageStore = useLanguageStore();
	const userStore = useUserStore();
    const { setLanguage } = languageStore;
    const { lang, language } = storeToRefs(languageStore);
	const { isAdmin, isModerator } = storeToRefs(userStore);
    const { t, locale } = useI18n();
	const { onOpenAdminLanguageDialogHandler, onOpenAdminLanguageManageDialogHandler } = useLanguage();
	const { onLoadLocale } = useLocale();
	const router = useRouter();

    const current = computed(() => {
		let items = [];
		if (language.value && language.value.items) {
			items = language.value.items
		} else if (language.value) {
			items = language.value;
		}
        return (items) ? items.find(e => e.code === locale.value) : null;
    });

    const onChangeLang = async (value) => {
		localStorage.setItem('_lang', value);
		await onLoadLocale(value);
		router.push(`/${value}`);
    }

    const items = computed(() => {
        const locales = [];
		if (language.value && typeof language.value === 'object' && language.value) {
			let items = [];
			if (language.value && language.value.items) {
				items = language.value.items
			} else if (language.value) {
				items = language.value;
			} else {
				items = {};
			}

			items.map(locale => {
				locales.push(
					{
						label: locale.name,
						icon: `fi fi-${locale.code}`,
						command: () => { onChangeLang(locale.code, locale.locale); }
					}
				);
			});
		}

		if (isAdmin.value || isModerator.value) {
			locales.push({ label: t('admin.addlanguage'), icon: '', command:() => {
				onOpenAdminLanguageDialogHandler();
			}});
			locales.push({ label: t('admin.language.manage'), icon: '', command:() => {
				onOpenAdminLanguageManageDialogHandler();
			}});
		}

        return locales;
    });

    onMounted(() => {

    })

    /*import { computed, onMounted, ref, watch } from "vue";
	import { useI18n } from 'vue-i18n'
	import { StorageSerializers, useStorage } from '@vueuse/core'
	import { useTranslation } from "../../composables/useTranslation";
	import { useRouter } from "vue-router";
	import { useLanguageStore } from '../../stores/useLanguageStore';
	import { useUserStore } from '../../stores/useUserStore';
    import { storeToRefs } from 'pinia';
	
	export default {
		setup() {
			const state = useStorage('db-store', null, undefined, { serializer: StorageSerializers.object });
			const { localesTranslation } = useTranslation();
			const { locale } = useI18n();
			const router = useRouter();
			const lang = ref(state.value.lang);
			const current = ref([]);
			const items = ref([]);
			const localeTranslation = ref([]);
			const { setLanguage } = useLanguageStore();
			const useStore = useUserStore();

			const { user, isAdmin } = storeToRefs(useStore);
			
			const onChangeLang = (value, locale_code) => {
				lang.value = value;
				locale.value = value;
				state.value.lang = value;
				setLanguage(value);
				// router.push(`/${locale_code}`);
			}

			const onLoadLanguages = async() => {
				localeTranslation.value = await localesTranslation();

				const locales = [];
				for(let i in localeTranslation.value) {
					let locale = localeTranslation.value[i];
					locales.push(
						{
							label: locale.label,
							icon: `fi fi-${locale.code}`,
							command: () => { onChangeLang(locale.code, locale.locale); }
						}
					);
				}

				items.value = locales;
				current.value = localeTranslation.value.find(l => l.code == lang.value);

				setLanguage(current.value.code);
			}

			onMounted(async() => {
				await onLoadLanguages();
			});
			
			watch(() => lang.value, () => {
				current.value = localeTranslation.value.find(l => l.code == lang.value);
			});

			watch(() => isAdmin.value, async () => {
				await onLoadLanguages();
			});
			
			return {
				current,
				lang,
				items,
				isAdmin
			}
		}
	}*/
</script>