<template>
	<InputField :label="label" :icon="icon">
		<div v-if="iconLeft" class="icon">
			<InlineSvg :src="iconLeft" width="20" />
		</div>
		<template v-if="hasSlotLabel" #label>
			<slot name="label" />
		</template>
		<PrimeInputText v-bind="$attrs" />
		<div v-if="icon" class="icon">
			<InlineSvg :src="icon" width="20" />
		</div>
	</InputField>
</template>

<script>
	import { ref } from 'vue';
	
	export default {
		props: {
			iconLeft: String,
			icon: String,
			label: String
		},
		setup(props, {slots}) {
			let hasSlotLabel = ref(false);
			
			if (slots.label) {
				hasSlotLabel.value = true;
			}
			
			return {
				hasSlotLabel
			}
		}
	}
</script>