<template>
	<div>
		<div class="row g-2 g-lg-3" v-for="(day, index) in days" :key="day.id">
			<div class="col flex-130">
				<div class="mt-10">
					<InputCheckbox :label="day.name" @input="onChangeOpenStatus(index)" :binary="true" v-model="day.status" />
				</div>
			</div>
			<div class="col">
				<InputDropdown :disabled="!day.status" v-model="day.begin" :options="hours" />
 			</div>
			<div class="col">
				<InputDropdown :disabled="!day.status" v-model="day.end" :options="hours" />
			</div>
		</div>
	</div>
</template>

<script setup>
	import {ref, watch} from 'vue';

	const props = defineProps({
		modelValue: {
			type: [Array, Object]
		}
	})

	const emit = defineEmits(['update:modelValue']);
	
	const days = ref([
		{id:1, name: 'ad.offer.monday', status: false, begin: null, end: null},
		{id:2, name: 'ad.offer.tuesday', status: false, begin: null, end: null},
		{id:3, name: 'ad.offer.wednesday', status: false, begin: null, end: null},
		{id:4, name: 'ad.offer.thursday', status: false, begin: null, end: null},
		{id:5, name: 'ad.offer.friday', status: false, begin: null, end: null},
		{id:6, name: 'ad.offer.saturday', status: false, begin: null, end: null},
		{id:7, name: 'ad.offer.sunday', status: false, begin: null, end: null},
	]);
			
	const hours = ref([]);
	for(let a = 6; a <= 23; a++) {
		hours.value.push({id: a, name: `${a}:00`});
	}
			
	watch(() => props.modelValue, () => {
		if (typeof props.modelValue == 'object' && Object.keys(props.modelValue).length == 7) {
			days.value = props.modelValue;
		}
	}, { deep: true });
			
	watch(() => days.value, () => {
		emit("update:modelValue", days.value);
	}, {deep: true});
			
	const onChangeOpenStatus = (index) => {
		let status = days.value[index].status;
		if (index > 0 && status) {
			let firstFillable = null;
					
			for(let f in days.value) {
				let item = days.value[f];
				if (item.begin != null || item.end != null) {
					firstFillable = f;
					break;
				}
			}
					
			if (status && firstFillable) {
				if (days.value[0].begin) {
					days.value[index].begin = days.value[0].begin;
				}
				if (days.value[0].end) {
					days.value[index].end = days.value[0].end;
				}
			}
		}
		else if (!status) {
			days.value[index].begin = null;
			days.value[index].end = null;
		}
	}
</script>