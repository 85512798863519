<template>
	<div class="ui__slidemenu">
		<div class="ui__slidemenu__nav">
			<div class="ui__slidemenu__searchbar d-none">
				<div class="input">
					<i class="pi pi-search"></i>
					<input type="text" />
				</div>
			</div>
			
			<div class="ui__slidemenu__ps mt-40">
				<ul>
					<li v-for="(item, index) in categories" :key="`item_${index}`">
						<router-link to="">
							<span><Text :i="item.name" /></span>
						</router-link>
					</li>
				</ul>
			</div>
			<i @click="onHideMenu" class="pi pi-times"></i>
		</div>
		
		<div @click="onHideMenu" class="ui__slidemenu__opacity"></div>
	</div>
</template>

<script setup>
import { useCategoryStore } from "@/stores/useCategoryStore";
import {storeToRefs} from "pinia";

const categoryStore = useCategoryStore();
const { categories } = storeToRefs(categoryStore);
</script>
