import { useDialog } from 'primevue/usedialog';
import DialogLanguage from '@/components/Admin/Dialog/Language.vue';
import DialogManageLanguage from '@/components/Admin/Dialog/LanguageManage.vue';
import { useI18n } from 'vue-i18n';
import { h, ref } from 'vue';
import Button from 'primevue/button';
import { useApi } from '@/composables/useApi';
import { useTranslation } from "@/composables/useTranslation";
import { useLanguageStore } from "@/stores/useLanguageStore";
import {storeToRefs} from "pinia";
import { useToast } from 'primevue/usetoast';

const formAdminLanguage = ref({
    hash: '',
    name: '',
    locale: '',
    code: ''
});

const errorAdminLanguage = ref({});
const { makeApiRequest } = useApi();

export function useLanguage() {
    const dialog = useDialog();
    let dialogRef = null;
    let dialogManageRef = null;
    const { t } = useI18n();
    const { onLoadLanguagesListFromDb } = useTranslation();
    const languageStore= useLanguageStore();
    const { language } = storeToRefs(languageStore);
    const toast = useToast();

    const onOpenAdminLanguageDialog = (header) => {
        dialogRef = dialog.open(DialogLanguage, {
            props: {
                header: t(header),
                modal: true,
                style: {
                    width:'100%',
                    'max-width':'500px'
                }
            },
            templates: {
                footer: () => {
                    return [
                        h(Button, { label: t('ui.cancel'), onClick: () => dialogRef.close(), autofocus: true}),
                        h(Button, { label: t('ui.save'), onClick: () => onSaveAdminLanguage(), autofocus: true})
                    ]
                }
            },
        });
    }

    const onOpenAdminLanguageManageDialog = () => {
        dialogRef = dialog.open(DialogManageLanguage, {
            props: {
                header: t('admin.language.manage'),
                modal: true,
                style: {
                    width:'100%',
                    'max-width':'500px'
                }
            },
        });
    }

    const onSaveAdminLanguageSort = async() => {
        let data = [];
        language.value.items.map(e => data.push(e.hash));
        await makeApiRequest('/admin/setting/language/sortable', 'post', data);
        toast.add({severity:'success', summary: t('admin.language.savesoort'), life: 3000});
    }

    const onSaveAdminLanguage = async () => {
        try {
            let url = '/admin/setting/language';
            let method = 'post';
            if (formAdminLanguage.value.hash !== '') {
                url = `/admin/setting/language/${formAdminLanguage.value.hash}`;
                method = 'put';
            }
            await makeApiRequest(url, method, formAdminLanguage.value, {}, errorAdminLanguage);

            formAdminLanguage.value = {
                hash: '',
                name: '',
                locale: '',
                code: ''
            };
        }
        catch(e) {
            console.error(e);
        }
    }

    const onSaveAdminLanguageHandler = () => {
        onSaveAdminLanguage();
    }

    const onOpenAdminLanguageDialogHandler = () => {
        onOpenAdminLanguageDialog('admin.addlanguage');
    }

    const onOpenAdminLanguageUpdateDialogHandler = (hash) => {
        const item = language.value.items.find(e => e.hash === hash);
        formAdminLanguage.value = item;
        onOpenAdminLanguageDialog('admin.editlanguage');
    }

    const onOpenAdminLanguageManageDialogHandler = () => {
        onOpenAdminLanguageManageDialog()
    }

    const onDeleteLangauge = async(hash) => {
        try {
            await makeApiRequest(`/admin/setting/language/${hash}`, 'delete');
            toast.add({severity:'success', summary: t('admin.language.successdelete'), life: 3000});
            await onLoadLanguagesListFromDb();
        } catch(e) {
            console.error(e);
        }
    }

    const onUpdateLanguage = async (hash) => {
        const item = language.value.items.find(e => e.hash === hash);
        await makeApiRequest(`/admin/setting/language/${hash}`, 'put', item);
        toast.add({severity:'success', summary: t('admin.language.successadded'), life: 3000});
    }

    const onUpdateLanguageHandler = async(hash) => {
        await onUpdateLanguage(hash);
    }

    const onDeleteLanguageHandler = async (hash) => {
        await onDeleteLangauge(hash);
    }

    return {
        formAdminLanguage,
        errorAdminLanguage,
        onOpenAdminLanguageDialog,
        onOpenAdminLanguageManageDialog,
        onOpenAdminLanguageDialogHandler,
        onOpenAdminLanguageManageDialogHandler,
        onOpenAdminLanguageUpdateDialogHandler,
        onSaveAdminLanguage,
        onSaveAdminLanguageHandler,
        onDeleteLanguageHandler,
        onSaveAdminLanguageSort,
        onUpdateLanguageHandler
    }
}