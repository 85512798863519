<template>
	<InputField :class="className">
		<PrimeMultiSelect v-bind="$attrs" :options="options" optionLabel="name" optionValue="id" :showToggleAll="false">
			<template #option="slotProps">
				<slot name="option" :slotProps="slotProps">
					<Text :i="slotProps.option.name" />
				</slot>
			</template>
			<template #value="slotProps">
				<slot name="value" :slotProps="slotProps">
					<span v-if="slotProps.value !== null" class="d-inline-block mr-3" v-for="(option, index) of slotProps.value">
						<Text :i="getLabel(option)" />
						{{ Object.keys(slotProps.value).length > index+1 ? "," : "" }}
					</span>
					<span v-else>
						<Text i="ui.choose" />
					</span>
				</slot>
			</template>
		</PrimeMultiSelect>
	</InputField>
</template>

<script>
	import { useI18n } from "vue-i18n";
	
	export default {
		props: {
			options: Array,
			className: String
		},
		setup(props) {
			const { t } = useI18n();
			
			const getLabel = (option) => {
				let item = props.options.find(i => i.id == option);
				if (item) {
					return item.name;
				}
			}

			return { t, getLabel }
		}
	}
</script>