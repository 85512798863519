<template>
	<div class="ui__header" :class="{'open__megamenu' : show_megamenu}">
		<div class="container">
			<div class="d-lg-flex justify-content-between">
				<div class="d-flex justify-content-between align-items-center">
					<div class="d-flex align-items-center flex-lg-row">
						<UiBack />
						<div class="d-flex flex-column">
							<div class="ui__name">
								<router-link to="/">Driver<span>Book</span></router-link>
							</div>
						</div>
						<UiLink @click="openMegaMenu" svg="/icon/category.svg" class="d-none d-lg-inline-block btn btn-white btn-outlined-primary ml-30 mr-12"><Text i="ui.categories" /></UiLink>
						
					</div>
					<div class="d-none d-lg-block">
						<UiLangswitcher />
					</div>
				</div>

				<div class="d-none d-lg-flex align-items-center">
					<div class="mr-5 position-relative">
						<template v-if="isAuthenticated">
							<UiLink svg="/icon/account.svg" class="btn btn-white" @click="openUserContentMenu"><Text i="account.office" /></UiLink>
							<UiContentMenu v-if="show_usercontentmenu" :items="user_menu" ref="target_user" class="ui__contentmenu__user position-absolute">
								<template #header>
									<div class=""></div>
									<div class="d-flex justify-content-between align-items-center">
										<div class="d-flex align-items-center">
											<div class="flex-44 mr-10">
												<PrimeAvatar v-if="userInfo.avatar" shape="circle" :image="userInfo.avatar" size="large" />
												<PrimeAvatar v-else shape="circle" :label="userInfo.signature" size="large" />
											</div>
											<div>
												<span>{{ userInfo.name }}</span><br>
												<span class="fs-12 text-muted lh-18">{{ userInfo.email }}</span>
											</div>
										</div>
										<ui-href :to="{name: 'accountSetting'}" @click="hideContentMenu">
											<InlineSvg src="/icon/cog.svg" height="24" />
										</ui-href>
									</div>
								</template>
							</UiContentMenu>
						</template>
						<UiLink v-else svg="/icon/account.svg" class="btn btn-white" :to="{name: 'auth.login', params: {lang: locale}}"><Text i="ui.account" /></UiLink>
					</div>
					<ui-href v-if="show_button_add_ad" svg="/icon/circle-plus.svg" class="btn btn-primary" :to="{name: 'createItem'}"><Text i="ad.new" /></ui-href>
				</div>
			</div>
		</div>
		
		<UiMegaMenu ref="target" v-if="show_megamenu" v-model:show_megamenu="show_megamenu" />
	</div>
</template>

<script>
	import UiBreadcrumb from '@/components/Ui/Breadcrumb.vue';
	import UiLangswitcher from '@/components/Ui/Langswitcher.vue';
	import UiMegaMenu from '@/components/Ui/MegaMenu.vue';
	import { useRouter } from 'vue-router';
	import { useUser } from "../../composables/useUser";
	import { useUi } from '@/composables/useUi';
	import { ref, onMounted, computed, inject } from "vue";
	import { onClickOutside } from '@vueuse/core';
	import { useI18n } from "vue-i18n";
	import {useUserStore} from "../../stores/useUserStore";
	import UiBack from './../../components/Ui/Back.vue';
	import { storeToRefs } from 'pinia';
	
	export default {
		components: { UiBreadcrumb, UiLangswitcher, UiMegaMenu, UiBack },
		setup(props, { root }) {
			const show = ref(false);
			const show_megamenu = ref(false);
			const router = useRouter();
			const { locale } = useI18n();
			const user_store = useUserStore();
			const { authenticated, user } = storeToRefs(user_store);

			const target = ref(null);
			const target_user = ref(null);
			const { userNavigation } = useUser();
			const userInfo = ref(user);
			const isAuthenticated = ref(authenticated);
			const { show_usercontentmenu, hideContentMenu } = useUi();

			const show_button_add_ad = computed(() => {
				if (userInfo && userInfo.value && userInfo.value.role && userInfo.value.role.includes(3)) {
					return false;
				}
				return true;
			});

			const user_menu = computed(() => {
				const menu = userNavigation.value;
				menu.push({label: 'account.nav.logout', route: {name: 'accountLogout'}, className: 'font-regular border-top-gray-200'});
				return menu;
			});

			onClickOutside(target, (event) => {
				setTimeout(() => {
					if (show_usercontentmenu.value == false) {
						show.value = false;
					}
					show_megamenu.value = false;
				}, 10);
			})

			onClickOutside(target_user, (event) => {
				setTimeout(() => {
					if (show_megamenu.value == false) {
						show.value = false;
					}
					show_usercontentmenu.value = false;
				}, 10);
			})

			function openUserContentMenu() {
				if (isAuthenticated.value == true) {
					show_usercontentmenu.value = true;
					show_megamenu.value = false;
					show.value = true;
				}
				else {
					router.push(`/${locale}/auth/login`);
				}
			}
			function openMegaMenu() {
				show_megamenu.value = true;
				show_usercontentmenu.value = false;
				show.value = true;
			}
			
			return {
				locale,
				target_user,
				target,
				show,
				show_megamenu,
				show_usercontentmenu,
				show_button_add_ad,
				user_menu,
				userInfo,
				isAuthenticated,
				authenticated,
				hideContentMenu,
				openUserContentMenu,openMegaMenu
			}
		}
	}
</script>
