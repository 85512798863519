<template>
	<div class="">
		<div v-if="title" class="ui__collapse__header mt-20 mb-10">
			<h4><Text :i="title" /></h4>
			<i @click="onChangeShow" v-if="collapseInBreakpoints.includes(breakpoint)" class="pi pi-chevron-up"></i>
		</div>
		<component :is="component" v-show="open">
			<slot />
		</component>
	</div>
</template>

<script>
	import { useBreakpoint } from "../../../../../composables/useBreakpoint";
	import {computed, ref} from "vue";
	
	export default {
		props: {
			title: String,
			card: {
				type: Boolean,
				default: true
			}
		},
		setup(props) {
			const { breakpoint } = useBreakpoint();
			const open = ref(true);
			
			const collapseInBreakpoints = ref(['sm', 'md']);
			
			const onChangeShow = () => {
				open.value = !open.value;
			}

			const component = computed(() => {
				return props.card == true ? 'UiCard' : 'div';
			})
			
			return { breakpoint, onChangeShow, collapseInBreakpoints, open, component }
		}
	}
</script>