import axios from "axios";
import { defineStore } from "pinia";

export const useCategoryStore = defineStore("category_store", {
	state: () => ({
		list: {},
		category: null,
		categories: []
	}),
	actions: {
		setCategory(category) {
			this.category = category;
		},
		setCategories(categories) {
			this.categories = categories;
		}
	}
});