import { ref, onMounted } from "vue";
import axios from "axios";
import { storeToRefs } from "pinia";
import { useUserStore } from './../stores/useUserStore';

const userNavigation = ref([]);

export function useUser() {

	const main = useUserStore();
	const { user, authenticated, userProxyId, userProxy, isAdmin, isCompany, isEmployee, isModerator } = storeToRefs(main);

	const isAuthenticated = ref(false);
	isAuthenticated.value = authenticated.value;

	const userInfo = ref({});
	userInfo.value = user.value;
	if (userProxyId.value !== null) {
		userInfo.value = userProxy.value;
	}

	onMounted(() => {
		userNavigation.value = [];
		if(authenticated.value) {
			if (isAdmin.value || isModerator.value) {
				userNavigation.value.push({label: 'admin.account', route: {name: 'adminAccount'}, active: false, component: null});
				userNavigation.value.push({label: 'admin.sms', route: '/pl/admin/sms', active: false, component: null});
				userNavigation.value.push({label: 'admin.integration', route: '/pl/admin/integration', active: false, component: null});
				userNavigation.value.push({label: 'admin.parser', route: '/pl/admin/parser', active: false, component: null});
				userNavigation.value.push({label: 'admin.payment', route: '/pl/admin/payment', active: false, component: null});
				userNavigation.value.push({label: 'admin.support', route: '/pl/admin/support', active: false, component: null});
			} else {
				userNavigation.value.push({label: 'account.nav.my-cv', route: {name: 'accountCvCreateDriver'}, active: false, component: 'UiAccountMenuCv'});
				userNavigation.value.push({label: 'account.nav.my-company', route: {name: 'accountCompanies'}, active: false, component: null});
				userNavigation.value.push({label: 'account.nav.my-ad', route: {name: 'AccountDashboard'}, subtext: [
					// {text: '+2 odpowiedzi', class: 'muted'}, {text: '+1 nowy kandydat', class: 'primary'}
				], active: false, component: null});
				userNavigation.value.push({label: 'account.nav.favorite', route: {name: 'accountFavorite'}, active: false, component: null});
				userNavigation.value.push({label: 'account.nav.payment', route: {name: 'accountPayment'}, active: false, component: null});
				userNavigation.value.push({label: 'account.nav.notification', route: {name: 'accountNotification'}, active: false, component: null});
			}
		}
	});

	async function onLogin(data) {
		await axios.get('csrf-cookie').then(response => {
			return axios.post("auth/login", data)
				.then(response => {
					return response;
				})
				.catch(e => {
					return {'status': 'error', error: e};
				});
		});
	}

	return {
		onLogin,
		isAuthenticated,
		userInfo,
		userNavigation
	}
}