<template>
	<ui-card class="ui__tabsnavigation">
		<vue-horizontal responsive class="horizontal" ref="horizontal" snap="none" :button="false">
			<ul>
				<li :class="{active: route_path === path}">
					<ui-href :to="`/${locale}/${route_path}`">
						<span class="material-icons-outlined">search</span>
						<span class="ui__tabsnavigation__label"><Text i="ad.all" /></span>
					</ui-href>
				</li>
				<li :class="{active: item.route === path}" v-for="item in subcategories" :key="item.route">
					<ui-href :to="`/${locale}/${item.route}`">
						<div>
							<div class="tab-icon">
								<div :class="`db db-${item.icon}`"></div>
							</div>
						</div>
						<span class="ui__tabsnavigation__label">
							<span v-if="item.tab">{{ item.tab }}</span>
							<Text v-else :i="item.name" />
						</span>
					</ui-href>
				</li>
			</ul>
		</vue-horizontal>
	</ui-card>
</template>

<script>
	import { useUi } from "../../composables/useUi";
	import { computed, ref } from "vue";
	import VueHorizontal from 'vue-horizontal';
	import { useRoute } from "vue-router";
	import { useI18n } from "vue-i18n";
	
	export default {
		components: {
			VueHorizontal
		},
		props: {
			route_path: String,
			subcategories: Array,
		},
		setup() {
			const { scaleSvg } = useUi();
			const base = ref(16);
			const route = useRoute();
			const { locale } = useI18n();

			const path = computed(() => {
				return route.path.substring(4, route.path.length);
			})
			
			function scale(size) {
				return scaleSvg(size, base.value);
			}
			
			return { path, scale, locale }
		}
	}
</script>

<style scoped>
.tab-icon {
	font-size:28px;
}
</style>