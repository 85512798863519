import { createApp } from 'vue'
import { createPinia, storeToRefs } from 'pinia'
import UniversalSocialauth from "universal-social-auth";
import { createHead } from '@vueuse/head';

import App from './App.vue'
import createI18n from './system/i18n';
import router from './router/index'
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import DialogService from 'primevue/dialogservice';
import PrimeVue from 'primevue/config';
import apiClient from "./http/api";
import InlineSvg from '@jambonn/vue-inline-svg';
import VueHorizontal from "vue-horizontal";
import axios from 'axios';
import VueAxios from 'vue-axios';

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
	components,
	directives,
});

const i18n = createI18n();

const options = {
	providers: {
		google: {
			clientId: '172751805895-lhhrois3c44joisoefk6gogmlceiumjg.apps.googleusercontent.com',
			redirectUri: 'https://pl.driverbook.net/auth/google/callback'
		},
		facebook: {
			clientId: '1946054002242486',
			redirectUri: 'https://pl.driverbook.net/auth/facebook/callback'
		},
	}
}

// PrimeVue Components
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

import AccordionTab from 'primevue/accordiontab';
import PrimeAutoComplete from "primevue/autocomplete";
import PrimeInputText from 'primevue/inputtext';
import PrimeInputDropdown from 'primevue/dropdown';
import PrimeCheckbox from 'primevue/checkbox';
import PrimeMultiSelect from 'primevue/multiselect';
import PrimeButton from 'primevue/button';
import PrimeSplitButton from 'primevue/splitbutton';
import PrimeTabView from 'primevue/tabview';
import PrimeTabPanel from 'primevue/tabpanel';
import PrimeScrollPanel from 'primevue/scrollpanel';
import PrimeFileUpload from 'primevue/fileupload';
import PrimeInputSwitch from 'primevue/inputswitch';
import PrimeAvatar from 'primevue/avatar';
import PrimeCalendar from 'primevue/calendar';
import PrimeToast from 'primevue/toast';
import PrimeRadioButton from 'primevue/radiobutton';
import PrimeConfirmDialog from 'primevue/confirmdialog';
import PrimeEditor from 'primevue/editor';
import PrimePassword from 'primevue/password';
import PrimeAccordion from 'primevue/accordion';
import PrimeAccordionTab from 'primevue/accordiontab';
import PrimeInputTextarea from 'primevue/textarea';
import PrimeSkeleton from 'primevue/skeleton';

// Global Components
import InputAutocomplete from './components/Input/Autocomplete.vue';
import InputDropdown from './components/Input/Dropdown.vue';
import InputDropdownSet from './components/Input/DropdownSet.vue';
import InputMultiDropdown from './components/Input/MultiDropdown.vue';
import InputText from './components/Input/Text.vue';
import InputField from './components/Input/Field.vue';
import InputCalendar from './components/Input/Calendar.vue';
import InputCheckbox from './components/Input/Checkbox.vue';
import InputCheckboxes from './components/Input/Checkboxes.vue';
import InputRadio from './components/Input/Radio.vue';
import InputRadios from './components/Input/Radios.vue';
import InputImage from './components/Input/Image.vue';
import InputEditor from './components/Input/Editor.vue';
import InputHtml from './components/Input/Html.vue';
import InputAdr from './components/Input/Adr.vue';
import InputLocation from './components/Input/Location.vue';
import InputOpening from './components/Input/Opening.vue';
import InputSociety from './components/Input/Society.vue';
import InputError from './components/Input/Error.vue';
import InputTextarea from './components/Input/Textarea.vue';
import InputPassword from './components/Input/Password.vue';
import InputNumber from './components/Input/Number.vue';
import InputSwitch from './components/Input/Switch.vue';
import InputPhone from './components/Input/Phone.vue';
import InputCascadeDropdown from './components/Input/CascadeDropdown.vue';
import UiCollapse from './components/Ui/Collapse.vue';
import UiCollapseSeparator from  './components/Ui/CollapseSeparator.vue';
import UiCard from './components/Ui/Card.vue';
import InputCheckboxesImage from './components/Input/CheckboxesImage.vue';
import UiButton from './components/Ui/Button.vue';
import UiLink from './components/Ui/Link.vue';
import UiImage from './components/Ui/Image.vue';
import UiHeadline from './components/Ui/Headline.vue';
import UiHeader from './components/Ui/Header.vue';
import UiBlockFade from './components/Ui/BlackFade.vue';
import UiTextFlag from './components/Ui/TextFlag.vue'
import UiSlider from './components/Ui/Slider.vue';
import AdIcon from './components/Ad/Icon.vue';
import AdItemActions from './components/Ad/Item/Actions.vue';
import AdItemDetailList from './components/Ad/Item/Detail/List/Index.vue';
import AdItemDetailListItem from './components/Ad/Item/Detail/List/Item.vue';
import UiTabsNavigation from './components/Ui/TabsNavigation.vue';
import UiHeaderTabs from './components/Ui/HeaderTabs.vue';
import UiTitle from './components/Ui/Title.vue';
import UiContentMenu from './components/Ui/ContentMenu.vue';
import UiTile from './components/Ui/Tile.vue';
import AdItemDetailBox from './components/Ad/Item/Detail/Box/Index.vue';
import UiLocation from './components/Ui/Location.vue';
import UiLocationCountry from './components/Ui/LocationCountry.vue';
import AdItemContentMenu from './components/Ad/Item/ContentMenu.vue';
import UiAccountMenuCv from './components/Ui/AccountMenuCv.vue';
import UiFooter from '@/components/Ui/Footer.vue';
import Text from './components/Text/Index.vue';
import AdminTranslation from './components/Admin/Translation/Index.vue';
import UiTabmenu from './components/Ui/Tabmenu.vue';
import InputDropdownCountry from './components/Input/DropdownCountry.vue';
import InputDropdownLanguage from './components/Input/DropdownLanguage.vue';
import UiPrompt from './components/Ui/Prompt.vue';
import UiScaleSvg from './components/Ui/ScaleSvg.vue';
import UiAlert from './components/Ui/Alert.vue';
import UiTooltip from './components/Ui/Tooltip.vue';
import UiBreadcrumb from './components/Ui/Breadcrumb.vue';
import UiHref from './components/Ui/Href.vue';
import FormBody from './components/Form/Body/Index.vue';
import UiIcon from './components/Ui/Icon.vue';
import Filter from './components/Filter/index.vue';
import FilterItem from './components/Filter/item.vue';

// Admin
import GateArea from './components/Gate/Area/index.vue';

// Layouts
import AppLayout from './layout/AppLayout.vue';
import AdminLayout from './layout/AdminLayout.vue';
import AccountLayout from './layout/AccountLayout.vue';
import AdminBodyLayout from './layout/AdminBodyLayout.vue';

const app = createApp(App)

const Oauth = new UniversalSocialauth(axios, options);

app.config.globalProperties.$api = apiClient;
app.config.globalProperties.$Oauth = Oauth;
app.config.globalProperties.$axios = axios;

app.use(vuetify);
app.use(createPinia())
app.use(i18n)
app.use(PerfectScrollbar)
app.use(router)
app.use(PrimeVue)
app.use(ToastService)
app.use(ConfirmationService)
app.use(VueHorizontal)
app.use(VueAxios, axios)
app.use(DialogService);
app.use(createHead());

app.mount('#app')
app.component('InlineSvg', InlineSvg);

// PrimeVue Components
app.component('PrimeInputDropdown', PrimeInputDropdown);
app.component('PrimeInputText', PrimeInputText);
app.component('PrimeAutoComplete', PrimeAutoComplete);
app.component('PrimeCheckbox', PrimeCheckbox);
app.component('PrimeMultiSelect', PrimeMultiSelect);
app.component('PrimeButton', PrimeButton);
app.component('PrimeSplitButton', PrimeSplitButton);
app.component('PrimeTabView', PrimeTabView);
app.component('PrimeTabPanel', PrimeTabPanel);
app.component('PrimeScrollPanel', PrimeScrollPanel);
app.component('PrimeFileUpload', PrimeFileUpload);
app.component('PrimeInputSwitch', PrimeInputSwitch);
app.component('PrimeAvatar', PrimeAvatar);
app.component('PrimeCalendar', PrimeCalendar);
app.component('PrimeToast', PrimeToast);
app.component('PrimeRadioButton', PrimeRadioButton);
app.component('PrimeConfirmDialog', PrimeConfirmDialog);
app.component('PrimeEditor', PrimeEditor);
app.component('PrimePassword', PrimePassword);
app.component('PrimeAccordion', PrimeAccordion);
app.component('PrimeAccordionTab', PrimeAccordionTab);
app.component('PrimeInputTextarea', PrimeInputTextarea);
app.component('PrimeSkeleton', PrimeSkeleton);

// Global Components
app.component('InputAutocomplete', InputAutocomplete);
app.component('InputDropdown', InputDropdown);
app.component('InputDropdownSet', InputDropdownSet);
app.component('InputMultiDropdown', InputMultiDropdown);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('InputField', InputField);
app.component('InputImage', InputImage);
app.component('UiCollapse', UiCollapse);
app.component('UiCollapseSeparator', UiCollapseSeparator);
app.component('InputCheckbox', InputCheckbox);
app.component('InputCheckboxes', InputCheckboxes);
app.component('InputCheckboxesImage', InputCheckboxesImage);
app.component('InputCalendar', InputCalendar);
app.component('InputDropdownCountry', InputDropdownCountry);
app.component('InputDropdownLanguage', InputDropdownLanguage);
app.component('InputRadio', InputRadio);
app.component('InputRadios', InputRadios);
app.component('InputEditor', InputEditor);
app.component('InputHtml', InputHtml);
app.component('InputAdr', InputAdr);
app.component('InputLocation', InputLocation);
app.component('InputOpening', InputOpening);
app.component('InputSociety', InputSociety);
app.component('InputError', InputError);
app.component('InputPassword', InputPassword);
app.component('InputPhone', InputPhone);
app.component('InputCascadeDropdown', InputCascadeDropdown);
app.component('UiCard', UiCard);
app.component('UiCollapse', UiCollapse);
app.component('UiButton', UiButton);
app.component('UiLink', UiLink);
app.component('UiImage', UiImage);
app.component('UiHeadline', UiHeadline);
app.component('UiHeader', UiHeader);
app.component('UiBlockFade', UiBlockFade);
app.component('UiTextFlag', UiTextFlag);
app.component('UiHref', UiHref);
app.component('AdIcon', AdIcon);
app.component('AdItemActions', AdItemActions);
app.component('AdItemDetailList', AdItemDetailList);
app.component('AdItemDetailListItem', AdItemDetailListItem);
app.component('UiTabsNavigation', UiTabsNavigation);
app.component('UiHeaderTabs', UiHeaderTabs);
app.component('UiTitle', UiTitle);
app.component('UiContentMenu', UiContentMenu);
app.component('UiTile', UiTile);
app.component('UiSlider', UiSlider);
app.component('AdItemDetailBox', AdItemDetailBox);
app.component('UiLocation', UiLocation);
app.component('UiLocationCountry', UiLocationCountry);
app.component('AdItemContentMenu', AdItemContentMenu);
app.component('UiAccountMenuCv', UiAccountMenuCv);
app.component('UiFooter', UiFooter);
app.component('Text', Text);
app.component('AdminTranslation', AdminTranslation);
app.component('UiTabmenu', UiTabmenu);
app.component('UiPrompt', UiPrompt);
app.component('UiScaleSvg', UiScaleSvg);
app.component('UiAlert', UiAlert);
app.component('UiTooltip', UiTooltip);
app.component('InputTextarea', InputTextarea);
app.component('UiBreadcrumb', UiBreadcrumb);
app.component('UiIcon', UiIcon);
app.component('FormBody', FormBody);
app.component('Filter', Filter);
app.component('FilterItem', FilterItem);
app.component('AccordionTab', AccordionTab);
app.component('InputSwitch', InputSwitch);

// Admin
app.component('GateArea', GateArea);

// Layouts
app.component('AppLayout', AppLayout);
app.component('AdminLayout', AdminLayout);
app.component('AccountLayout', AccountLayout);
app.component('AdminBodyLayout', AdminBodyLayout);

import { useCoreStore } from "./stores/useCoreStore";
const core_store = useCoreStore();
const { setStatusLoader, getStatusLoader } = core_store;
const { loader } = storeToRefs(core_store);

function changeStatus(status) {
	setStatusLoader(status);
}

axios.defaults.baseURL = 'https://pl.driverbook.net/api/'; // TODO API URL
if (localStorage.getItem('auth._token') !== undefined && localStorage.getItem('auth._token') !== null) {
	axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('auth._token')}`}
}
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:3000/';
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type,Authorization';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,OPTIONS';
axios.defaults.headers.common['Lang'] = 'nl';

axios.interceptors.request.use(req => {
	changeStatus('waiting');
	setTimeout(() => {
		if (loader.value === 'waiting') {
			changeStatus(true);
		}
	}, 500);
	return req;
}, function(err) {
	changeStatus(false);
	return Promise.reject(err);
});

axios.interceptors.response.use(res => {
	changeStatus(false);
	return res;
}, function(err) {
	changeStatus(false);
	return Promise.reject(err);
});

// PROMPT
import {PromptSymbol} from "./composables/usePrompt";
import { BreadcrumbSymbol } from "./composables/useBreadcrumb";
import PromptEventBus from './bus/PromptsEventBus.js';
import BreadcrumbEventBus from './bus/BreadcrumbEventBus.js';
//import './registerServiceWorker'

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register('/service-worker.js').then(function() {
		console.log('Service Worker Registered!');
	});
}

const PromptService = {
	require: (options) => {
		PromptEventBus.emit('prompts', options);
	},
	close: () => {
		PromptEventBus.emit('hide');
	}
};
app.config.globalProperties.$prompt = PromptService;
app.provide(PromptSymbol, PromptService);

// BREADCRUMB
const BreadcrumbService = {
	create: (options) => {
		BreadcrumbEventBus.emit('breadcrumb', options);
	}
}
app.config.globalProperties.$breadcrumb = BreadcrumbService;
app.provide(BreadcrumbSymbol, BreadcrumbService);

import "./assets/scss/app.scss";