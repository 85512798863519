<template>
	<UiCard class="ui__tile" :class="{active: active}">
		<div class="ui__tile__box">
			<div class="ui__tile__icon">
				<img :src="icon" />
			</div>
			<div class="ui__tile__title">{{ title }}</div>
			<slot name="additional"></slot>
		</div>
	</UiCard>
</template>

<script>
	export default {
		props: {
			active: Boolean,
			icon: String,
			title: String
		},
		setup() {

		}
	}
</script>