<template>
	<InputField>
		<PrimeCalendar v-bind="$attrs" dateFormat="yy-mm-dd" v-model="model" />
	</InputField>
</template>

<script>

	import { onMounted, ref, watch } from "vue";
	import { usePrimeVue } from "primevue/config";
	import moment from 'moment';
	import { useI18n } from "vue-i18n";
	
	export default {
		props: {
			modelValue: [Date, String]
		},
		setup(props, { emit }) {
			const { t } = useI18n();

			const daysName = ref([
				t('calendar.dayNames.sunday'),
				t('calendar.dayNames.monday'),
				t('calendar.dayNames.tuesday'),
				t('calendar.dayNames.wednesday'),
				t('calendar.dayNames.thursday'),
				t('calendar.dayNames.friday'),
				t('calendar.dayNames.saturday')
			]);

			const dayNamesShort = ref([
				t('calendar.dayNamesShort.sun'),
				t('calendar.dayNamesShort.mon'),
				t('calendar.dayNamesShort.tue'),
				t('calendar.dayNamesShort.wed'),
				t('calendar.dayNamesShort.thu'),
				t('calendar.dayNamesShort.fri'),
				t('calendar.dayNamesShort.sat')
			]);

			const dayNamesMin = ref([
				t('calendar.dayNamesMin.su'),
				t('calendar.dayNamesMin.mo'),
				t('calendar.dayNamesMin.tu'),
				t('calendar.dayNamesMin.we'),
				t('calendar.dayNamesMin.th'),
				t('calendar.dayNamesMin.fr'),
				t('calendar.dayNamesMin.sa')
			]);

			const monthNames = ref([
				t('calendar.monthNames.january'),
				t('calendar.monthNames.february'),
				t('calendar.monthNames.march'),
				t('calendar.monthNames.april'),
				t('calendar.monthNames.may'),
				t('calendar.monthNames.june'),
				t('calendar.monthNames.july'),
				t('calendar.monthNames.august'),
				t('calendar.monthNames.september'),
				t('calendar.monthNames.october'),
				t('calendar.monthNames.november'),
				t('calendar.monthNames.december'),
			]);

			const monthNamesShort = ref([
				t('calendar.monthNamesShort.jan'),
				t('calendar.monthNamesShort.feb'),
				t('calendar.monthNamesShort.mar'),
				t('calendar.monthNamesShort.apr'),
				t('calendar.monthNamesShort.may'),
				t('calendar.monthNamesShort.jun'),
				t('calendar.monthNamesShort.jul'),
				t('calendar.monthNamesShort.aug'),
				t('calendar.monthNamesShort.sep'),
				t('calendar.monthNamesShort.oct'),
				t('calendar.monthNamesShort.nov'),
				t('calendar.monthNamesShort.dec'),
			]);

			const today = ref('calendar.today');
			const weekHeader = ref('calendar.weekHeader');
			const firstDayOfWeek = ref('calendar.firstDayOfWeek');
			const dateFormat = ref('calendar.dateFormat');

			const model = ref();
			const changeLocale = () => {
				const primevue = usePrimeVue();
				primevue.config.locale.dayNames = daysName.value;
				primevue.config.locale.dayNamesShort = dayNamesShort.value;
				primevue.config.locale.dayNamesMin = dayNamesMin.value;
				primevue.config.locale.monthNames = monthNames.value;
				primevue.config.locale.monthNamesShort = monthNamesShort.value;
				primevue.config.locale.today = today.value;
				primevue.config.locale.weekHeader = weekHeader.value;
				primevue.config.locale.firstDayOfWeek = firstDayOfWeek.value;
				primevue.config.locale.dateFormat = dateFormat.value;
			}

			watch(() => model.value, () => {
				if (model.value !== null) {
					model.value = moment(model.value).format("YYYY-MM-DD");
					emit("update:modelValue", model.value);
				}
			})

			watch(() => props.modelValue, () => {
				if (props.modelValue !== undefined) {
					model.value = props.modelValue;
				}
			})
			
			onMounted(() => {
				changeLocale();
				if (props.modelValue !== undefined) {
					model.value = props.modelValue;
				}
			})

			return { model }
		}
	}
</script>