<template>
	<div class="admin__translate" v-if="(isAdmin || isModerator) && translation_key">
		<perfect-scrollbar style="height:100vh">
		<div class="p-20">
			<InputText v-model="keyMerge" label="Klucz" disabled />
			<div class="mb-20" v-for="(item, code) in form.text" :key="item.code">
				<InputTextarea class="mt-3" :autoResize="true" :label="code" rows="1" v-model="form.text[code]" />
			</div>
			
			<div class="d-flex justify-content-end row g-2">
				<div class="col"><PrimeButton @click="onSetTranslationKey(null)" class="p-button-outlined w-100" label="Anuluj" /></div>
				<div class="col"><PrimeButton class="w-100" @click="onSave" label="Zapisz" /></div>
			</div>
		</div>
		</perfect-scrollbar>
	</div>
	<div v-if="isAdmin || isModerator" class="admin__translate__switch">
		<input-switch v-model="translateMode"><span class="fs-12 text-muted">{{ t(translateMode ? 'admin.translate.close' : 'admin.translate.open') }}</span></input-switch>
	</div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { ref, inject, watch, onMounted } from 'vue';
import { useTranslation } from "../../../composables/useTranslation";
import { useUserStore } from "./../../../stores/useUserStore";
import { useI18n } from 'vue-i18n';
import { useApi } from '../../../composables/useApi';

const onSetTranslationKey = inject("onSetTranslationKey");
const translation_key = inject("translation_key");
const localeTranslation = ref([]);
const { onLoadLanguagesList, onSendToAi, onLoadTranslateItem } = useTranslation();
const keyMerge = ref(null);
const userStore = useUserStore();
const { makeApiRequest } = useApi();

const props = defineProps(['table', 'column', 'id']);
const translateMode = inject('translateMode');
const { isAdmin, isModerator } = storeToRefs(userStore);
const { t } = useI18n();
const languages = ref({});

const form = ref({
	group: '',
	key: '',
	text: []
});

const langName = (lang) => {
	//const language = 
}

const onLoadForm = async() => {
	if (isAdmin || isModerator) {

		form.value = {
			group: '',
			key: '',
			text: {}
		};

		languages.value = await onLoadLanguagesList();
		const key = JSON.parse(JSON.stringify(translation_key.value));
		keyMerge.value = key;
		const keyParams = key ? key.split(".") : '';
		if (keyParams !== null && keyParams) {
			form.value.group = keyParams[0];
			keyParams.splice(0, 1);
			form.value.key = keyParams.join(".");

			Object.keys(languages.value["items"]).forEach(key => {
				const language = languages.value["items"][key]; console.log(language);
				form.value.text[language.code] = '';
				localeTranslation.value.push({id: language.code, code: language.code, label: language.name });
			});

			try {
				const data = await makeApiRequest('/admin/setting/translate/translate', 'post', {group: form.value.group, key: form.value.key });
				form.value.text = data.text;
			} catch(e) {
				console.error(e);
			}
		}

		//await onLoadTranslateItem('language_lines', 'text', 1);
	}
}

watch(() => translation_key, () => {
	onLoadForm();
}, { deep: true });

onMounted(async() => {
	onLoadForm();
})

const onSave = async() => {
	try {
		await makeApiRequest('/admin/setting/translate', 'post', form.value);
	} catch(e) {
		console.log(e);
	}
}

const onLoadTransaltionFromAi = async() => {
	
}
</script>