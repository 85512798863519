<template>
	<li>
		<div class="ad__list__icon">
			<div v-if="iconClass" :class="`db db-icon-primary db-${iconClass}`"></div>
			<InlineSvg v-if="icon" :src="icon" height="18" />
			<span :class="`fi fi-${flag}`" v-if="flag"></span>
		</div>
		<span class="ad__list__label" v-if="label"><Text :i="label" /></span>
		<div class="ad__list__value">
			<span v-if="value">{{ value }}</span>
			<slot v-else />
		</div>
	</li>
</template>

<script>
export default {
	name: 'AdItemDetailListItem',
	props: {
		icon: String,
		iconClass: String,
		label: String,
		flag: String,
		value: String
	},
	setup() {
	
	}
}
</script>

<style scoped>
.db-icon-primary {
	font-size:18px;
	color:#1A72E9;
}
</style>