<template>
	<div class="ad__icon">
        <template v-if="loading">
            <div class="ad__icon__svg d-flex flex-column align-items-center justify-content-center">
                <PrimeSkeleton size="24px" class="24px"></PrimeSkeleton>
                <PrimeSkeleton class="mt-3" width="4rem" height="10px" />
            </div>
        </template>
		<div v-else class="ad__icon__svg d-flex flex-column align-items-center justify-content-center">
			<div class="d-flex align-items-end" :style="`height:${base}px`">
                <div :class="`db db-${icon}`"></div>
            </div>
			<div class="fs-12 mt-3" v-if="title"><Text :i="title" /></div>
		</div>
	</div>
</template>

<script>
	import { onMounted, ref, watch } from 'vue';
	import { useCategory } from "../../composables/useCategory";
    import { useLibraryStore } from '../../stores/useLibraryStore';
    import ScaleSvg from '../Ui/ScaleSvg.vue';
	
	export default {
    props: {
        src: String,
        id: Number,
        name: String,
        loading: {
            type: Boolean,
            default: false
        },
        base: {
            type: Number,
            default: 20
        }
    },
    setup(props) {
        const src = props.src;
        const id = props.id;
        const icon = ref(null);
        const { CategoryGet } = useCategory();
        const title = ref(null);
        const libraryStore = useLibraryStore();
        const { getVehicleType } = libraryStore;

        if (props.name !== null) {
            title.value = props.name;
        }
        onMounted(async() => {
            if (id !== null && id !== undefined) {
                let data = await getVehicleType(props.id);
                icon.value = data.icon;
                title.value = data.name;
            }
            else if (src != "") {
                icon.value = src;
            }
        })

        watch(() => props.id, async() => {
            if (props.id !== null && props.id !== undefined) {
                let data = await getVehicleType(props.id);
                icon.value = data.icon;
                title.value = data.name;
            }
        })

        return { icon, title };
    },
    components: { ScaleSvg }
}
</script>

<style scoped>
.ad__icon__svg .db {
    font-size:28px;
}
</style>