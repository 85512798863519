<template>
	<div :class="className" class="ui__collapse__separator"></div>
</template>

<script>
	export default {
		props: {
			className: String
		}
	}
</script>