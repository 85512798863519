<template>
	<div class="input__checkbox__image">
		<div class="input__label" v-if="label && !hide_label"><Text :i="label" /></div>
		<div class="input__label" v-if="tooltip"><Text :i="tooltip" /></div>
		<div class="input__checkbox__image__items" :class="className">
			<template v-for="item in options" :key="item.id">
				<ui-card class="input__checkbox__image__item" @click="onCommand(item.id); onChecked(item.id);" :class="`${checkIncludes(item.id) ? 'checked' : null}`">
					<div class="input__checkbox__image__icons">
						<div class="checkbox-icon" :class="`db db-${item.icon}`"></div>
					</div>
					<span><Text :i="item.name" /></span>
				</ui-card>
			</template>
		</div>
		<div v-if="children" class="mt-15">
			<InputCheckboxesImage id="10" :options="children" :tooltip="subtooltip" :col="col" />
		</div>
	</div>
</template>

<script>
import {computed, ref, watch} from "vue";
	
	export default {
		props: {
			col: {
				type: [Object, Number],
				default: 1
			},
			hide_label: {
				type: Boolean,
				default: false,
			},
			type: {
				type: String,
				default: 'checkbox'
			},
			command: Function,
			modelValue: Array,
			tooltip: String,
			subtooltip: String,
			label: String,
			options: Array,
			iconsSize: {
				type: Number,
				default: 18
			}
		},
		emits:["modelValue"],
		setup(props, { emit }) {
			const checked = ref(props.type == 'checkbox' ? [] : null);
			
			const onChecked = (id) => {
				if (props.type == 'checkbox') {
					if (checked.value == undefined) {
						checked.value = [];
					}
					let index = checked.value.findIndex(i => i == id);
					if (index == -1) {
						checked.value.push(id);
					} else {
						checked.value.splice(index, 1);
					}
				}
				else {
					checked.value = id;
				}
				
				emit("update:modelValue", checked);
			}
			
			const checkIncludes = (id) => {
				if (props.type == 'checkbox' && typeof(checked.value) == 'object') {
					return checked.value.includes(id);
				}
				else {
					return checked.value == id ? true : false;
				}
			}

			const children = computed(() => {
				if (props.type == 'radio' && checked.value) {
					const items = props.options.find(e => e.id === checked.value);
					if (items && items.children) {
						return items.children;
					}
				}
			})
			
			const updateModelValue = () => {
				if (props.modelValue == undefined) {
					checked.value = props.type == 'checkbox' ? [] : null;
				}
				else {
					if (checked.value != props.modelValue && props.modelValue != null) {

					}
					checked.value = props.modelValue;
				}
			}
			
			const className = computed(() => {
				if (typeof(props.col) == "object") {
					let array = [];
					for(let c in props.col) {
						array.push(`c-${c}-${props.col[c]}`);
					}
					return array;
				}
			})
			
			const onCommand = (id) => {
				if (props.command !== undefined) {
					props.command(id);
				}
			}
			
			checked.value = props.modelValue;
			watch(() => props.modelValue, () => { updateModelValue() }, {deep: true});
			
			return {
				children,
				onCommand,
				className,
				checkIncludes,
				onChecked,
				checked
			}
		}
	}
</script>

<style scoped>
.checkbox-icon {
	font-size:28px;
}
</style>