<template>

    <div class="row">
        <div class="col">
            <input-radio label="Polska" />
        </div>
    </div>

    <div class="ml-0">
        <input-autocomplete label="Miasto, region" />
        <div class="mt-16 mb-32">
            <div class="fs-12 ml-20 mr-20 text-muted mb-10 d-flex justify-content-between">
                <Text i="ad.findinradius" />
                <span>{{ val }} km</span>
            </div>
            <div class="px-20">
                <Slider :step="5" :min="0" :max="30" v-bind="$attrs" v-model="val" />
            </div>
        </div>
    </div>

    <div class="ui__collapse__separator mb-20" id="43" component="UiCollapseSeparator"></div>

    <input-radio>Praca za granicą</input-radio>
    <div class="ml-0">
        <input-dropdown label="Wybierz państwo" />
    </div>

</template>

<script setup>
import { ref } from 'vue';
import Slider from 'primevue/slider';

const val = ref(0);
</script>