<template>
     <InputDropdown :label="label" optionLabel="nativeName" v-bind="$attrs" :options="items">
     </InputDropdown>
</template>

<script setup>
import { computed } from 'vue';
import { useLibrary } from '@/composables/useLibrary';

const { languagesList } = useLibrary();
const items = computed(() => languagesList.value);

defineProps({
     label: String,
})
</script>