<template>
	<div class="alert" :class="`alert-${type}`">
		<div class="d-flex align-items-center">
			<div  v-if="icon" class="mr-10">
				<InlineSvg :src="icon" height="18" />
			</div>
			<span class="d-block"><slot /></span>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			type: String,
			icon: String
		}
	}
</script>