import { useTitle } from "@vueuse/core";
import { onMounted, watch } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLanguageStore } from './../stores/useLanguageStore';
import { storeToRefs } from "pinia";

export default function() {
     const languageStore = useLanguageStore();
     const router = useRouter();
     const { lang } = storeToRefs(languageStore);
     let meta = '';
     let route = '';
     const uRoute = useRoute();

     const title = {
          pl: 'Driver Book - międzynardowy portal ogłoszeniowy dla kierowców',
          gb: 'Driver Book - international advertising portal for drivers',
          ru: 'Driver Book - международный рекламный портал для водителей',
          de: 'Driver Book - internationales Anzeigenportal für Fahrer',
          nl: 'Driver Book - internationaal advertentieportaal voor chauffeurs',
          fr: 'Driver Book - portail publicitaire international pour les conducteurs',
          es: 'Driver Book - portal de publicidad internacional para conductores',
          ro: 'Driver Book - portal internațional de publicitate pentru șoferi'
     } 

     onMounted(() => {
          route = router.currentRoute.value.name;
     });

     watch(() => lang, () => {
          if (meta && meta.title) {
               useTitle(meta.title);
          } else {
               useTitle(title[lang.value]);
          }
     }, { deep: true });

     watch(() => uRoute.fullPath, async () => {
          route = router.currentRoute.value.name;
          let url = '';
          if (route) {
               let params = JSON.stringify(uRoute.params);
               params = params.toString();
               url = `meta?route=${route}&params=${params}`;
          }
          
          const request = await axios.get(url);
          const { data } = request;
          if (data) {
               meta = data;
               if (data.title) {
                    useTitle(data.title);
               } else {
                    useTitle(title);
               }
          } else {
               useTitle(title);
          }
     });

     useTitle(title);
}