<template>
	<div>
		<div class="input__image" @click="onOpenSelect" :style="`border-radius: ${radius}px; width:${size}px; height:${size}px`">
			<InlineSvg v-if="source === null && preview === null" src="/icon/photo.svg" height="24" />
			<span v-if="source === null && preview === null" class="mt-5 d-block"><Text :i="label" /></span>
			<button type="button" @click="onPrompts" v-if="source !== null || preview !== null" class="change"><InlineSvg src="/icon/pencil.svg" /></button>
			
			<img v-if="preview" :src="preview" :style="`width:${size}px; height:${size}px; border-radius: ${radius}px; overflow:hidden; object-fit: cover;`" class="input__image__preview" />
			
			<input type="file" ref="fileInput" id="fileInput" @input="onSelectFile" accept=".png, .jpg, .jpeg" style="display:none" />
		</div>

		<Dialog header="Uwaga!" modal v-model:visible="dialog" >
			<div>{{ t('ui.image.maxsize') }}.<br />{{ t('ui.image.yoursize') }} <strong>{{ formatBytes(bytes) }}</strong>. {{ t('ui.image.resize') }}.</div>
			<template #footer>
				<div class="d-flex justify-content-center">
					<PrimeButton @click="dialog = false" label="OK" autofocus />
				</div>
			</template>
		</Dialog>
	</div>
</template>

<script>
	import { onMounted, ref, watch } from "vue";
	import { usePrompts } from "../../composables/usePrompt";
	import axios from 'axios';
	import Dialog from 'primevue/dialog';
	import { useToast } from "primevue/usetoast";
	import { useI18n } from "vue-i18n";

	export default {
		props: {
			size: {
				type: Number,
				default: 0
			},
			radius: {
				type: Number,
				default: 0
			},
			label: {
				type: String,
				default: 'ui.photo'
			},
			src: {
				type: String
			},
			kind: {
				type: String,
				required: true
			},
			callback: {
				type: Function
			},
			modelValue: {
				type: String
			}
		},
		components: {
			Dialog
		},
		emits: ['modelValue'],
		setup(props, { emit }) {
			const prompts = usePrompts();
			const source = ref(null);
			const preview = ref(null);
			const fileInput = ref(null);
			const dialog = ref(false);
			const bytes = ref(0);
			const toast = useToast();
			const { t } = useI18n();
			
			function onPrompts() {
				prompts.require({
					items: [
						{label: t('ui.image.select'), command: () => { onChangeImage() }},
						{label: t('ui.image.delete'), command: () => { onRemoveImage() }},
					]
				});
			}

			function formatBytes(bytes, decimals = 2) {
				if (bytes === 0) return '0 Bytes';

				const k = 1024;
				const dm = decimals < 0 ? 0 : decimals;
				const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

				const i = Math.floor(Math.log(bytes) / Math.log(k));

				return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
			}
			
			function onSelectFile() {
				let imgFile = fileInput.value.files;
				if (imgFile && imgFile[0]) {
					bytes.value = imgFile[0].size;
					let fileSize = (bytes.value / 1024) / 1024;
					if (fileSize > 4) {
						dialog.value = true;
					}
					else {
						let reader = new FileReader;
						reader.onload = e => {
							
							preview.value = e.target.result;

							let formData = new FormData();
							formData.append('file', imgFile[0]);
							formData.append('kind', props.kind);

							axios.post("/attachment/upload", formData).then(({data}) => {
								if (props.callback !== undefined) {
									props.callback();
								}
								emit("update:modelValue", data);

								const inputField = document.getElementById("fileInput");
								inputField.value = '';
								toast.add({severity:'success', summary: t('ui.image.alert.added'), life: 3000});
							})
						}
						reader.readAsDataURL(imgFile[0]);
					}
				}
			}
			
			function onChangeImage() {
				fileInput.value.click();
				prompts.close();
			}
			
			function onRemoveImage() {
				preview.value = null;
				source.value = null;
				prompts.close();
				const inputField = document.getElementById("fileInput");
				inputField.value = '';

				axios.delete(`/attachment/delete/${props.kind}`).then(({data}) => {
					if (props.callback !== undefined) {
						props.callback();
					}
					toast.add({severity:'success', summary: t('ui.image.alert.remove'), life: 3000});
				})
			}
			
			function onOpenSelect() {
				if (source.value !== null || preview.value !== null) {
					onPrompts()
				}
				else {
					fileInput.value.click();
				}
			}

			onMounted(() => {
				preview.value = null;
				if (props.src && props.src !== null) {
					preview.value = `${props.src}?time=${Math.random()}`;
				}
			})

			watch(() => props.src, () => {
				if (props.src && props.src !== null) {
					preview.value = `${props.src}?time=${Math.random()}`;
				}
			}, { deep:  true});
			
			return {
				dialog,
				source,
				fileInput,
				preview,
				bytes,
				formatBytes,
				onOpenSelect,
				onPrompts,
				onSelectFile
			}
		}
	}
</script>
