<template>
	<InputField>
		<InputNumber v-bind="$attrs" />
	</InputField>
</template>

<script>
     import InputNumber from 'primevue/inputnumber';

     export default {
          components: {
               InputNumber
          }
     }
</script>