<template>
	<ul class="ad__buttons">
		<li @click="onShare($event)">
			<div class="db db-share"></div>
			<span class="d-block" v-if="labels"><Text i="ui.share" /></span>
		</li>
		<li @click="onFavourite($event)">
			<div :class="`db db-favorite${add ? '-full' : ''}`"></div>
			<span v-if="labels"><Text i="ui.watch" /></span>
		</li>
		<li @click="onHide($event)">
			<div class="db db-minus-circle"></div>
			<span v-if="labels"><Text i="ui.block" /></span>
		</li>
	</ul>

	<Dialog header="Uwaga" modal v-model:visible="display" :style="{'width': '400px', 'max-width': '100%'}">
		<p>Aby dodać ogłoszenie do obserwowanych lub usunąć je z listy wyszukiwania, <b>musisz posiadać dodany profil</b> kierowcy lub firmy.</p>
		<template #footer>
            <ui-button label="OK" @click="display = false" />
        </template>
	</Dialog>
</template>

<script>
	import { useUser } from "../../../composables/useUser";
	import { useShare } from '@vueuse/core'
	import { useRouter } from "vue-router";
	import axios from "axios";
	import Dialog from 'primevue/dialog';
	import { useToast } from "primevue/usetoast";
	import { ref, watch, inject } from 'vue';
	import { useUserStore } from "../../../stores/useUserStore";
	import { storeToRefs } from 'pinia';
	
	export default  {
		components: {
			Dialog
		},
		props: {
			item: Object,
			callback: Function,
			callbackHide: Function,
			added: {
				type: Boolean,
				default: false
			},
			labels: {
				type: Boolean,
				default: false
			}
		},
		setup(props) {
			const { share, isSupported } = useShare()
			const { isAuthenticated } = useUser();
			const router = useRouter();
			const toast = useToast();
			const add = ref(false);
			const display = ref(false);
			const userStore = useUserStore();

			const { user } = storeToRefs(userStore);
			add.value = props.added;

			watch(() => props.added, () => {
				add.value = props.added;
			})

			const onShare = (e) => {
				if (props.item === undefined || props.item.id === undefined) {
					return false;
				}
				
				share({
					title: props.item.name,
					url: location.href,
				});
				e.preventDefault();
				e.stopPropagation();
			}

			const onHide = (e) => {
				if (props.item === undefined || props.item.id === undefined) {
					return false;
				}

				if (isAuthenticated.value) {
					if (props.callbackHide !== undefined && user.value.status_id === 2) {
						props.callbackHide(props.item.id);
					} else {
						display.value = true;
					}
				}
				else {
					router.push("/pl/auth/login");
				}

				e.preventDefault();
				e.stopPropagation();
			}

			const onFavourite = (e) => {
				let action = add.value ? 'remove' : 'create';

				if (props.item === undefined || props.item.id === undefined) {
					return false;
				}

				const form = {
					id: props.item.id,
					action
				}
				if (isAuthenticated.value) {
					if (user.value.status_id === 2) {
						axios.post("/account/favorite", form).then(({data}) => {
							if (add.value) {
								toast.add({severity:'success', summary: 'Ogłoszenie zostało usunięte z obserwowanych', life: 3000});
								add.value = false;
							}
							else {
								toast.add({severity:'success', summary: 'Ogłoszenie zostało dodane do obserwowanych', life: 3000});
								add.value = true;
							}
						});

						if (props.callback !== undefined) {
							props.callback();
						}
					} else {
						display.value = true;
					}
				}
				else {
					router.push("/pl/auth/login");
				}

				e.preventDefault();
				e.stopPropagation();
			}

			return { isAuthenticated, onFavourite, onShare, add, onHide, display }
		}
	}
</script>