<template>
	<div class="input__radios">
		<div class="input__label" v-if="label && !hide_label"><Text :i="label" /></div>
		<div class="input__label d-block mb-5" v-if="tooltip"><Text :i="tooltip" /></div>
		<div class="row">
			<div :class="classRow" v-for="option in options" :key="option.id">
				<div :class="{active: option.id == model}" @click="onChange(option.id)" class="input__radio">
					<div class="input__radio__point"> </div>
					<div><Text :i="option.name" /></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, watch, computed } from 'vue';
	
	export default {
		name: 'InputRadios',
		props: {
			label: String,
			col: {
				type: [Number, Object],
				default: 1
			},
			options: Array,
			name: String,
			tooltip: String,
			inline: Boolean,
			modelValue: [String, Number],
		},
		emits:["modelValue"],
		setup(props, { emit }) {
			const model = ref('');

			model.value = props.modelValue;

			watch(() => props.modelValue, () => {
				model.value = props.modelValue;
			}, { deep: true});
			
			const classRow = computed(() => {
				if (props.col == 1) {
					return 'col-12';
				}
				else if (props.col == 2) {
					return 'col-6';
				}
				else if (props.col == 3) {
					return 'col-4';
				}
			});
			
			watch(() => model, () => {
				//console.log(model.value);
			}, { deep: true});
			
			const onChange = (id) => {
				if (model.value == id) {
					id = '';
				}
				model.value = id;
				
				emit("update:modelValue", model);
			}
			
			return {
				onChange,
				classRow,
				model
			}
		}
	}
</script>