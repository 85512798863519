import axios from "axios";
import { defineStore } from "pinia";

export const useLibraryStore = defineStore("library_store", {
     state: () => ({
          vehicleType: [],
          region: []
     }),
     actions: {
          async getVehicleType(id) {
               let item = this.vehicleType.find((e => e.id === id));
               if (item === undefined) {
                    const request = await axios.get(`ad/category${id ? '/'+id : ''}`);
                    const { data } = request;
                    item = {id: data.id, name: data.name, icon: data.icon };
                    this.vehicleType.push(item);
               }
               return item;
          }
     }
});