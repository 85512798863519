<template>
	<InputField :label="label">
		<template v-if="hasSlotLabel" #label>
			<slot name="label" />
		</template>
		<PrimeInputTextarea v-bind="$attrs" />
	</InputField>
</template>

<script>
	import { ref } from 'vue';
	
	export default {
		props: {
			label: String
		},
		setup(props, {slots}) {
			let hasSlotLabel = ref(false);
			
			if (slots.label) {
				hasSlotLabel.value = true;
			}
			
			return {
				hasSlotLabel
			}
		}
	}
</script>