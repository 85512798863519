import { defineStore } from "pinia";

export const useCoreStore = defineStore("core_store", {
	state: () => ({
		loader: false,
		regula: false,
	}),
	getters: {
		getStatusLoader(state) {
			return state.loader;
		}
	},
	actions: {
		setStatusLoader(status) {
			this.loader = status;
		},
	}
});