<template>
	<router-link v-if="back && countItem > 0" :to="back">
		<i class="ml--10 mr-15 pi pi-arrow-left d-inline-flex d-lg-none" />
	</router-link>
</template>

<script>
	import BreadcrumbEventBus from "../../bus/BreadcrumbEventBus";
	import {ref, onMounted, watch} from "vue";
	
	export default {
		name: 'UiBack',
		setup() {
			const items = ref({});
			const back = ref(null);
			const countItem = ref(0);
			
			const breadcrumbListener = (options) => {
				items.value = options.items;
			}
			
			onMounted(() => {
				BreadcrumbEventBus.on('breadcrumb', breadcrumbListener);
			});
			
			watch(() => items.value, () => {
				let count = items.value.length;
				if (count > 1) {
					let item = items.value[count - 2];
					if (item) {
						back.value = item.url;
					}
				}

				countItem.value = count;
				
			}, { deep: true })
			
			return { back, countItem }
		}
	}
</script>
