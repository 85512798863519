import { ref } from 'vue';
import { useApi } from './useApi';
import { useUserStore } from '../stores/useUserStore';
import { storeToRefs } from 'pinia';

const formAuthRegister = ref({
    kind: 'register',
    email: '',
    password: '',
    phones: [{"value": "", "country_code": ""}],
    code: '',
    ruleData: false,
    rule: false,
    ruleOption: false
});

const errorAuthRegister = ref({});
const stepAuthRegister = ref(0);
const session = ref(120000);
const resent = ref(false);

export default function() {
    const { makeApiRequest } = useApi();
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    const onFormAuthRegisterSubmit = async() => {
        try {
            let url = 'auth/register';
            if (stepAuthRegister.value === 1) {
                url = 'auth/register/store'
            }
            const data = await makeApiRequest(url, 'post', formAuthRegister.value, {}, errorAuthRegister);
            stepAuthRegister.value = 1;
            return data;
        } catch(e) {
            console.error(e);
        }
    }

    const reSentSms = async() => {
        await makeApiRequest('auth/register', 'post', formAuthRegister.value, {}, errorAuthRegister);
        session.value = 120000;
        resent.value = false;
	}

    const onResendActivationMail = async() => {
        if (user.value && user.value.hash) {
            await makeApiRequest('auth/reactivate', 'post', {hash: user.value.hash});
        }
    }

    return {
        session,
        onFormAuthRegisterSubmit,
        onResendActivationMail,
        errorAuthRegister,
        formAuthRegister,
        stepAuthRegister,
        resent,
        reSentSms
    }
}