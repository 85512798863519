<template>
	<draggable v-model="language.items" tag="transition-group" @end="onSaveAdminLanguageSort" item-key="id">
		<template #item="{element}">
			<div class="d-flex align-items-center my-16">
				<div class="flex-fill">
					{{ element.name }}
				</div>
				<div>
					<input-switch v-model="element.status" @change="onUpdateLanguageHandler(element.hash)" />
				</div>
				<div>
					<a href="javascript:;" class="mr-12" @click="onOpenAdminLanguageUpdateDialogHandler(element.hash)"><InlineSvg src="/icon/primary/pencil-thin.svg" height="22" /></a>
					<a href="javascript:;" @click="onDeleteLanguageHandler(element.hash)"><InlineSvg src="/icon/primary/trash-thin.svg" height="22" /></a>
				</div>
			</div>
		</template>
	</draggable>
</template>

<script setup>
import draggable from 'vuedraggable'
import { useLanguage } from "@/composables/useLanguage";
import { ref, onMounted, watch } from "vue";
import { useLanguageStore } from "@/stores/useLanguageStore";
import {storeToRefs} from "pinia";

const languageStore = useLanguageStore();
const { language } = storeToRefs(languageStore);
const languages = ref([]);
const { onDeleteLanguageHandler, onSaveAdminLanguageSort, onUpdateLanguageHandler, onOpenAdminLanguageUpdateDialogHandler } = useLanguage();
const setLanguages = () => {
	if (language && language.value && language.value.items) {
		languages.value = language.value.items;
	} else if (language.value) {
		languages.value = language.value;
	}
}

onMounted(() => {
	setLanguages();
});
</script>