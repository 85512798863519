import axios from "axios";
import { useI18n } from "vue-i18n";

export function useApi() {

	async function makeApiRequest(url, method, postData = {}, headers = {}, setErrors = null) {
		if (setErrors && setErrors.value) {
			setErrors.value = {};
		}
		try {
			axios.defaults.baseURL = 'https://pl.driverbook.net/api/'; // TODO API URL
			axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
			axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
			axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type,Authorization';
			axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,OPTIONS';
			axios.defaults.headers.common['Lang'] = 'nl';
			
			const response = await axios({
				method: method,
				url: url,
				data: postData,
				headers: headers,
			});

			return response.data;
		} catch(error) {
			if (error.response && error.response.status && error.response.status === 422 && setErrors !== null) {
				if (setErrors.value && error.response.data.errors) {
					setErrors.value = error.response.data.errors;
				}
				return Promise.reject(error.response.data);
			}
			else if (error.response) {
				console.error('Błąd odpowiedzi z serwera:', error.response.data);
				return Promise.reject(error.response.data);
			}
			else if (error.request) {
				console.error('Brak odpowiedzi z serwera:', error.request);
				return Promise.reject('Brak odpowiedzi z serwera');
			}
			else {
				console.error('Błąd:', error.message);
				return Promise.reject(error.message);
			}
		}
	}

	return {
		makeApiRequest
	}
}