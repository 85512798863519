<template>
	<div class="d-flex align-items-center ui__headline">
		<router-link class="d-inline-block d-lg-none" v-if="back" :to="back"><i class="pi pi-arrow-left"></i> </router-link>
		<h4><slot /></h4>
	</div>
</template>

<script>
	export default {
		props: {
			back: [Object, String]
		}
	}
</script>