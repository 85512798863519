<template>
	<div class="ad__item__contentmenu">
		<slot name="actions">
			<ul class="actions">
				<li v-if="!item.expired">
					<router-link :to="{name: 'AccountAdEdit', params: {hash: item.hash}}">
						<div class="ad__item__contentmenu__icon">
							<InlineSvg src="/icon/primary/pencil-thin.svg" width="16" />
						</div>
						<span><Text i="ui.edit" /></span>
					</router-link>
				</li>
				<li v-if="item.expired">
					<router-link to="">
						<div class="ad__item__contentmenu__icon">
							<InlineSvg src="/icon/repeat.svg" width="16" />
						</div>
						<span><Text i="ui.refresh" /></span>
					</router-link>
				</li>
				<li @click="onDelete(item.hash)">
					<div class="ad__item__contentmenu__item">
						<div class="ad__item__contentmenu__icon">
							<InlineSvg src="/icon/primary/trash-thin.svg" height="16" />
						</div>
						<span><Text i="ui.delete" /></span>
					</div>
				</li>
			</ul>
		</slot>
		
		<div class="ad__item__contentmenu__stats">
			<div class="text-muted mb-10">Statystyka</div>
			<ul>
				<li>
					<div class="ad__item__contentmenu__icon">
						<InlineSvg src="/icon/view.svg" width="14" />
					</div>
					<span>{{ item.view }} wyświetleń</span>
				</li>
				<li>
					<div class="ad__item__contentmenu__icon">
						<InlineSvg src="/icon/phone.svg" width="14" />
					</div>
					<span>{{ item.view_contact }} wyświetleń kontaktu</span>
				</li>
				<li>
					<div class="ad__item__contentmenu__icon">
						<InlineSvg src="/icon/calendar.svg" width="14" />
					</div>
					<span>Dodano: {{ item.created_at }}</span>
				</li>
				<li>
					<div class="ad__item__contentmenu__icon"></div>
					Wygasa za {{ item.expired_at }} dni
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import { useConfirm } from 'primevue/useconfirm';
	import { useRouter } from 'vue-router';
	export default {
		props: {
			item: Object
		},
		setup(props, { slots }) {
			const confirm = useConfirm();
			const router = useRouter()

			const onDelete = (hash) => {
				confirm.require({
					message: 'Czy napewnoc chcesz usunąć ogłoszenie?',
					header: 'Potwierdź usunięcie',
					icon: 'pi pi-exclamation-triangle',
					acceptLabel: 'Tak, usuń',
					rejectLabel: 'Nie',
					accept: () => {
						router.push({name: 'AccountAdDelete', params: {hash}});
					}
				});
			}

			return { onDelete };
		}
	}
</script>