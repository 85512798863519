<template>
	<UiCard class="ui__contentmenu">
		<div class="ui__contentmenu__header" v-if="header">
			<slot v-if="hasHeaderSlot" name="header"></slot>
			<div v-else class="d-flex align-items-center">
				<div class=""></div>
				<div>
					<span>{{ userInfo.name }}</span><br>
					<span class="fs-12 text-muted lh-18">{{ userInfo.role }}</span>
				</div>
			</div>
		</div>
		<ul class="ui__contentmenu__content">
			<li v-for="item, i in items" :key="i">
				<hr v-if="item.type == 'divider'" style="margin:0; padding:0;" />
				<ui-href v-else :to="item.route" @click="hideContentMenu" :class="item.className">
					<div class="d-flex justify-content-between">
						<div>
							<Text :i="item.label" />
							<span v-if="item.subtext" class="subtext">
								<span v-for="subitem, sindex in item.subtext" :key="sindex" :class="`text-${subitem.class}`">{{ subitem.text }}</span>
							</span>
						</div>
						<span class="badge" v-if="item.badge">0</span>
					</div>
				</ui-href>
			</li>
		</ul>
	</UiCard>
</template>

<script>
	import { computed } from "vue";
	import { useUser } from "../../composables/useUser";
	import { useUi } from '@/composables/useUi';

	export default {
		props: {
			items: String,
			header: {
				type: Boolean,
				default: true
			}
		},
		setup(props, { slots }) {
			const { show_usercontentmenu, hideContentMenu } = useUi();
			const { userInfo } =  useUser();

			let hasHeaderSlot = computed(() => {
				return slots.header && slots.header().length;
			});

			return {
				hasHeaderSlot,
				userInfo,
				show_usercontentmenu,

				hideContentMenu
			}
		}
	}
</script>