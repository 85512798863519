import axios from 'axios';
import {computed, ref} from 'vue';
import { useLibrary } from './useLibrary.js';
import { storeToRefs } from "pinia";
import { useUserStore } from '../stores/useUserStore';
import { useRouter } from 'vue-router';
import { useLanguageStore } from './../stores/useLanguageStore.js';

const transaltionsItems = ref([]);
const formTranslate = ref({
	hash: null,
	group: null,
	text: {},
	setting: {
		api: false
	}
});

export function useTranslation() {

	const { LibraryGet } = useLibrary();
	const userStore = useUserStore();
	const languageStore = useLanguageStore();
	const { user, isAdmin } = storeToRefs(userStore);
	const { setLanguages } = languageStore;
	const { language } = storeToRefs(languageStore);
	const router = useRouter();

	const onCreateLanguage = async() => {
		const request = await axios.post('admin/setting/language');
		const { data } = request;
		return data;
	}

	const onLoadLanguagesListFromDb = async() => {
		let url = 'language';
		if (isAdmin.value) {
			url = 'admin/setting/language';
		}
		const request = await axios.get(url);
		const { data } = request;
		setLanguages(data);
		return data;
	}

	const onLoadLanguagesList = async() => {
		if (language.value.length === 0) {
			return await onLoadLanguagesListFromDb();
		} else {
			return language.value;
		}
	}

	const onLoadTranslateItem = async(table, column, id) => {
		const request = await axios.get(`admin/setting/translate/${table}/${column}/${id}`);
		const { data } = request;
		return data;
	}

	const onLoadTransaltionsList = async(params = {}) => {
		const url_params = new URLSearchParams(params).toString();
		const request = await axios.get(`admin/setting/translate?${url_params}`);
		const { data } = request;
		transaltionsItems.value = request;
	}

	const onLoadTransaltionsGroupList = async() => {
		const request = await axios.get('admin/setting/translate/group');
		const { data } = request;
		return data;
	}

	const onSendToAi = async(languages, table, column, id, source) => {
		const request = await axios.post('admin/setting/translate/ai', {languages, table, column, id, source})
	}

	const onSaveCreate = async() => {
		const request = await axios.post('admin/setting/translate', formTranslate.value);
		const { data } = request;
		router.push(`/pl/admin/content/translate/${data.hash}`)
	}

	return {
		formTranslate,
		transaltionsItems,
		onLoadLanguagesListFromDb,
		onLoadTranslateItem,
		onLoadLanguagesList,
		onLoadTransaltionsList,
		onSaveCreate,
		onCreateLanguage,
		onLoadTransaltionsGroupList,
		onSendToAi
	}
}