<template>
	<div class="d-flex ui__textflag">
		<span :class="`fi fi-${model}`"></span>
		<span><slot /></span>
	</div>
</template>

<script>
	import { ref, computed } from 'vue'

	export default {
		props: {
			flag: String
		},
		setup(props) {
			const model = computed(() => {
				if (props.flag) {
					return props.flag.toLowerCase();
				}
			})

			return { model }
		}
	}
</script>