<template>
	<div class="ui__header__title">
		<div class="ui__header__title__slider">
			<Vue3Marquee direction="reverse" clone :duration="50">
				<div v-for="(item, index) in items" :key="index"><div :class="`db-${item} category__icon text-gray-700`" /></div>
			</Vue3Marquee>
		</div>
		<h1><Text :i="title" /></h1>
	</div>
</template>

<script setup>
	import { computed } from 'vue';
	import { useRoute } from "vue-router";
	import { Vue3Marquee } from 'vue3-marquee';

	const route = useRoute();
	const title = computed(() => route.meta.title ?? 'ui.title');

	const items = [
		'a1',
		'ce-set2-izotherm',
		'b-delivery',
		'c-cementmixer',
		'ce-set1-izotherm',
		'b-minivan',
		'd-school',
		'c-trolley',
		'ce-set1-silos',
		'a',
		'ce-set3-izotherm',
		'c-crane',
		'b-personal',
		'b-burta',
		'd-tourist',
		'c-laweta',
		'c-window',
		'ce-set1-window',
		'excavator',
		'ce-set1-tilt',
		'b-limousine',
		'ce-set2-cattle',
		'agricultural',
		'ce-set1-autotransporter'
	];
</script>

<style>
.category__icon {
	font-size:28px;
	margin:0 16px;
}
</style>