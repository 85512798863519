import pl from './../locales/pl.json';
import gb from './../locales/en.json';
import de from './../locales/de.json';
import fr from './../locales/fr.json';
import es from './../locales/es.json';
import ro from './../locales/ro.json';
import nl from './../locales/nl.json';

import _ from 'lodash';
import { StorageSerializers, useStorage } from '@vueuse/core'
import axios from "axios";
import {computed, ref, watch} from 'vue';

export function useLibrary() {

	async function LibraryGet(type) {
		return await axios.get(`library/items?type=${type}`);
	}

	async function StatesGet(code) {
		return await axios.get(`option/states/${code}`);
	}

	async function CitiesGet(code, id_state, search) {
		return await axios.get(`library/cities/${code}/${id_state}/${search}`);
	}

	const state = useStorage('db-store', null, undefined, { serializer: StorageSerializers.object });
	const lang = 'pl'; // TODO do zrobienia
	const messages = { pl: pl, gb: gb, de: de, fr: fr, es: es, ro: ro, nl: nl };

	const AccommodationPeriod = ref([
		{id: 1, name: 'ad.period.hour'},
		{id: 2, name: 'ad.period.day'},
		{id: 3, name: 'ad.period.week'},
		{id: 4, name: 'ad.period.month'}
	]);

	const AccommodationType = ref([
		{id: 1, name: 'ad.accommodation.type.hotel'},
		{id: 2, name: 'ad.accommodation.type.hostel'},
		{id: 3, name: 'ad.accommodation.type.room'},
		{id: 4, name: 'ad.accommodation.type.social'},
		{id: 5, name: 'ad.accommodation.type.container'}
	]);

	const AccommodationBed = ref([
		{id: 1, name: 'ad.accommodation.bed.single'},
		{id: 2, name: 'ad.accommodation.bed.bunker'},
	]);

	const AccommodationPerson = ref([
		{id:1, name: 1},
		{id:2, name: 2},
		{id:3, name: 3},
		{id:4, name: 4},
		{id:5, name: 5},
		{id:6, name: 6},
		{id:7, name: 7},
		{id:8, name: 8},
		{id:9, name: 9},
		{id:10, name: 10},
	]);

	const AccommodationFacilities = ref([
		{id:1, name: 'ad.accommodation.facilities.safe', icon: '/icon/safe.svg'},
		{id:2, name: 'ad.accommodation.facilities.shower', icon: '/icon/shower.svg'},
		{id:3, name: 'Wi-Fi', icon: '/icon/wifi.svg'},
		{id:4, name: 'ad.accommodation.facilities.parking', icon: '/icon/parking.svg'},
		{id:5, name: 'ad.accommodation.facilities.kitchen', icon: '/icon/kitchen.svg'},
		{id:6, name: 'ad.accommodation.facilities.washing-machine', icon: '/icon/washing-machine.svg'},
	]);

	const SalaryPeriod = ref([
		{id:1, name: 'ad.salary.per-hour'},
		{id:2, name: 'ad.salary.per-day'},
		{id:3, name: 'ad.salary.per-week'},
		{id:4, name: 'ad.salary.per-month'},
	]);

	const SalaryPeriodShort = ref([
		{id:1, name: 'h'},
		{id:2, name: 'dn'},
		{id:3, name: 'tydz.'},
		{id:4, name: 'mc'},
	]);

	const labelSalaryPeriodShort = (index) => {
		let item = SalaryPeriodShort.value.find(e => e.id == index);
		return item.name;
	}

	const labelSalaryPeriod = (index) => {
		let item = SalaryPeriod.value.find(e => e.id == index);
		return item.name;
	}

	const SalaryPeriodBonus = ref([
		{id:1, name: 'ad.salary.per-week'},
		{id:2, name: 'ad.salary.per-month'},
		{id:3, name: 'ad.salary.per-quarter'},
		{id:4, name: 'ad.salary.per-year'},
	]);

	const SalaryFormat = ref([
		{id: 1, name: 'ad.salary.static'},
		{id: 2, name: 'ad.salary.range'},
	]);

	const SalaryType = ref([
		{id: 1, name: 'ad.salary.rate'},
		{id: 2, name: 'ad.salary.run'},
		{id: 3, name: 'ad.salary.carriage'},
		{id: 4, name: 'ad.salary.negotiate'},
	]);

	const SalaryTypeSimple = ref([
		{id: 1, name: 'ad.salary.rate'},
		{id: 4, name: 'ad.salary.negotiate'},
	]);

	const SalaryCurrency = ref([
		{id: 'PLN', name: 'PLN'},
		{id: 'EUR', name: 'EUR'},
		{id: 'USD', name: 'USD'},
		{id: 'GBP', name: 'GBP'},
	]);

	const OfferAgreement = ref([
		{id: 1, name: 'ad.offer.contract-of-employment'},
		{id: 2, name: 'ad.offer.contract-of-mandate'},
		{id: 3, name: 'ad.offer.contract'},
		//{id: 4, name: 'ad.offer.negotiable'},
	]);

	const labelOfferAgreement = (index) => {
		let item = OfferAgreement.value.find(e => e.id == index);
		return item.name
	}

	const OfferType = ref([
		{id: 1, name: 'ad.offer.constant'},
		{id: 2, name: 'ad.offer.temporary'},
	]);

	const labelOfferType = (index) => {
		let item = OfferType.value.find(e => e.id == index);
		if (item) {
			return item.name
		}
	}

	const OfferKind = ref([
		{id: 1, name: 'ad.offer.day'},
		{id: 2, name: 'ad.offer.night'}
	]);

	const labelOfferKind = (index) => {
		let item = OfferKind.value.find(e => e.id == index);
		return item.name
	}

	const OfferTime = ref([
		{id: 1, name: 'ad.offer.fulltime'},
		{id: 2, name: 'ad.offer.parttime'},
		//{id: 3, name: 'ad.offer.negotiable'},
	]);

	const OfferSystem = ref([
		{id: 1, name: 'ad.offer.1day', option: 'ad.offer.1day'},
		{id: 2, name: 'ad.offer.1day', option: 'ad.offer.2days'},
		{id: 3, name: 'ad.offer.1week', option: 'ad.offer.weekend'},
		{id: 4, name: 'ad.offer.1week', option: 'ad.offer.1week'},
		{id: 5, name: 'ad.offer.2weeks', option: 'ad.offer.weekend'},
		{id: 6, name: 'ad.offer.2weeks', option: 'ad.offer.1week'},
		{id: 7, name: 'ad.offer.2weeks', option: 'ad.offer.2weeks'},
		{id: 8, name: 'ad.offer.3weeks', option: 'ad.offer.1week'},
		{id: 9, name: 'ad.offer.4weeks', option: 'ad.offer.1week'},
		{id: 10, name: 'ad.offer.4weeks', option: 'ad.offer.2weeks'},
		{id: 11, name: 'ad.offer.negotiable', className: 'mt-3 without__line', classInput: 'mt-5 without__line'},
	]);

	const OfferSchedule = ref([
		{id: 1, name: 'ad.offer.monday'},
		{id: 2, name: 'ad.offer.tuesday'},
		{id: 3, name: 'ad.offer.wednesday'},
		{id: 4, name: 'ad.offer.thursday'},
		{id: 5, name: 'ad.offer.friday'},
		{id: 6, name: 'ad.offer.saturday'},
		{id: 7, name: 'ad.offer.sunday'},
	]);

	const OfferScheduleGroup = ref([
		{id: 1, name: 'ad.offer.working-days'},
		{id: 2, name: 'ad.offer.weekends'},
		{id: 3, name: 'ad.offer.every-day'},
	]);

	const OfferTeam = ref([
		{id: 1, name: 'ad.offer.team1'},
		{id: 2, name: 'ad.offer.team2'},
	]);

	const RequirementsExperience = ref([
		{id:1, name: 'ad.requirements.without-experience'},
		{id:2, name: 'ad.requirements.over6months'},
		{id:3, name: 'ad.requirements.than_year'},
		{id:4, name: 'ad.requirements.over_2years'},
		{id:5, name: 'ad.requirements.over_5years'},
	]);

	const labelRequirementsExperience = (index) => {
		let item = RequirementsExperience.value.find(e => e.id == index);
		if (item) {
			return item.name;
		}
		return null;
	}

	const RequirementsLanguage = ref([
		{id:1, name: 'ad.languages.basic', subname: 'ad.languages.basic_subname'},
		{id:2, name: 'ad.languages.communacious', subname: 'ad.languages.communacious_subname'},
		{id:3, name: 'ad.languages.current', subname: 'ad.languages.current_subname'},
		{id:4, name: 'ad.languages.advance', subname: 'ad.languages.advance_subname'},
	]);

	const labelRequirementsLanguage = (index) => {
		let item = RequirementsLanguage.value.find(e => e.id == index);
		return item.name;
	}

	const TransportType = ref([
		{id:1, name: 'ad.filter.direction.national'},
		{id:2, name: 'ad.filter.direction.international'}
	]);

	const InstructorCategory = ref([
		{id: 162, name: 'db.library.162', icon: '/icon/categories/a.svg'},
          {id: 163, name: 'db.library.163', icon: '/icon/categories/b.svg'},
          {id: 164, name: 'db.library.164', icon: '/icon/categories/be.svg'},
          {id: 165, name: 'db.library.165', icon: '/icon/categories/c.svg'},
          {id: 166, name: 'db.library.166', icon: '/icon/categories/d.svg'},
          {id: 167, name: 'db.library.167', icon: '/icon/categories/e.svg'},
	]);

	const InstructorTheory = ref([
		{id: 169, name: 'db.library.169', icon: '/icon/categories/road_rules.svg'},
          {id: 170, name: 'db.library.170', icon: '/icon/categories/code95.svg'},
          {id: 171, name: 'db.library.171', icon: '/icon/categories/adr.svg'},
          {id: 172, name: 'db.library.172', icon: '/icon/categories/secure_load.svg'},
          {id: 173, name: 'db.library.173', icon: '/icon/categories/first-aid.svg'},
          {id: 174, name: 'db.library.174', icon: '/icon/categories/bhp.svg'},
	]);

	const TransportDirection = ref([
		{id:1, name: 'db.library.29'},
		{id:2, name: 'db.library.30'},
		{id:3, name: 'db.library.31'},
		{id:4, name: 'db.library.32'},
		{id:5, name: 'db.library.33'},
		{id:6, name: 'db.library.34'}
	]);

	const SalaryPeriodDistance = ref([
		{id:1, name: 'ad.filter.per-kilometer'},
		{id:2, name: 'ad.filter.per-mile'},
	]);

	const labelSalaryPeriodDistance = (index) => {
		let item = SalaryPeriodDistance.value.find(e => e.id == index);
		return item.name;
	}

	const Months = ref([
		{id: 1, name: 'month.1'},
		{id: 2, name: 'month.2'},
		{id: 3, name: 'month.3'},
		{id: 4, name: 'month.4'},
		{id: 5, name: 'month.5'},
		{id: 6, name: 'month.6'},
		{id: 7, name: 'month.7'},
		{id: 8, name: 'month.8'},
		{id: 9, name: 'month.9'},
		{id: 10, name: 'month.10'},
		{id: 11, name: 'month.11'},
		{id: 12, name: 'month.12'}
	]);

	const Years = ref([]);
	let year =  new Date().getFullYear();
	for(let a = year; a >= 2021; a--) {
		Years.value.push({id: a, name: a});
	}

	const documentType = ref([
		{id: 1, name: 'cv.workVisa'},
		{id: 2, name: 'cv.residence-permit'},
		{id: 3, name: 'cv.permanent-place-of-residence'},
	]);

	const labelDocumentType = (index) => {
		let item = documentType.value.find(e => e.id == index);
		return item.name;
	}

	/*const citizenship = [
		{id: 'af', name: _.get(messages, `${lang}.library.citizenship.afghanistan`)},
		{id: 'al', name: _.get(messages, `${lang}.library.citizenship.albania`)}
	];*/

	const DriverLicence = ref([
		{id: 'a1', name: 'A1', icon: '/icon/categories/a1.svg'},
		{id: 'a', name: 'A', icon: '/icon/categories/a.svg'},
		{id: 'b', name: 'B', icon: '/icon/categories/b.svg'},
		{id: 'be', name: 'B E', icon: '/icon/categories/be.svg'},
		{id: 'c1', name: 'C1', icon: '/icon/categories/c.svg'},
		{id: 'c1e', name: 'C1 E', icon: '/icon/categories/c1e.svg'},
		{id: 'c', name: 'C', icon: '/icon/categories/c.svg'},
		{id: 'ce', name: 'CE', icon: '/icon/categories/ce.svg'},
		{id: 'd1', name: 'D1', icon: '/icon/categories/d1.svg'},
		{id: 'd1e', name: 'D1E', icon: '/icon/categories/d1e.svg'},
		{id: 'd', name: 'D', icon: '/icon/categories/d.svg'},
		{id: 'de', name: 'DE', icon: '/icon/categories/de.svg'},
	]);

	const languagesList = ref([
		{"id":"ab", "flag":"", "name":"Abkhaz","nativeName":"аҧсуа"},
		{"id":"aa", "flag":"", "name":"Afar","nativeName":"Afaraf"},
		{"id":"af", "flag":"", "name":"Afrikaans","nativeName":"Afrikaans"},
		{"id":"ak", "flag":"", "name":"Akan","nativeName":"Akan"},
		{"id":"sq", "flag":"", "name":"Albanian","nativeName":"Shqip"},
		{"id":"am", "flag":"", "name":"Amharic","nativeName":"አማርኛ"},
		{"id":"ar", "flag":"", "name":"Arabic","nativeName":"العربية"},
		{"id":"an", "flag":"", "name":"Aragonese","nativeName":"Aragonés"},
		{"id":"hy", "flag":"", "name":"Armenian","nativeName":"Հայերեն"},
		{"id":"as", "flag":"", "name":"Assamese","nativeName":"অসমীয়া"},
		{"id":"av", "flag":"", "name":"Avaric","nativeName":"авар мацӀ, магӀарул мацӀ"},
		{"id":"ae", "flag":"", "name":"Avestan","nativeName":"avesta"},
		{"id":"ay", "flag":"", "name":"Aymara","nativeName":"aymar aru"},
		{"id":"az", "flag":"", "name":"Azerbaijani","nativeName":"azərbaycan dili"},
		{"id":"bm", "flag":"", "name":"Bambara","nativeName":"bamanankan"},
		{"id":"ba", "flag":"", "name":"Bashkir","nativeName":"башҡорт теле"},
		{"id":"eu", "flag":"", "name":"Basque","nativeName":"euskara, euskera"},
		{"id":"be", "flag":"", "name":"Belarusian","nativeName":"Беларуская"},
		{"id":"bn", "flag":"", "name":"Bengali","nativeName":"বাংলা"},
		{"id":"bh", "flag":"", "name":"Bihari","nativeName":"भोजपुरी"},
		{"id":"bi", "flag":"", "name":"Bislama","nativeName":"Bislama"},
		{"id":"bs", "flag":"", "name":"Bosnian","nativeName":"bosanski jezik"},
		{"id":"br", "flag":"", "name":"Breton","nativeName":"brezhoneg"},
		{"id":"bg", "flag":"", "name":"Bulgarian","nativeName":"български език"},
		{"id":"my", "flag":"", "name":"Burmese","nativeName":"ဗမာစာ"},
		{"id":"ca", "flag":"", "name":"Catalan; Valencian","nativeName":"Català"},
		{"id":"ch", "flag":"", "name":"Chamorro","nativeName":"Chamoru"},
		{"id":"ce", "flag":"", "name":"Chechen","nativeName":"нохчийн мотт"},
		{"id":"ny", "flag":"", "name":"Chichewa; Chewa; Nyanja","nativeName":"chiCheŵa, chinyanja"},
		{"id":"zh", "flag":"", "name":"Chinese","nativeName":"中文 (Zhōngwén), 汉语, 漢語"},
		{"id":"cv", "flag":"", "name":"Chuvash","nativeName":"чӑваш чӗлхи"},
		{"id":"kw", "flag":"", "name":"Cornish","nativeName":"Kernewek"},
		{"id":"co", "flag":"", "name":"Corsican","nativeName":"corsu, lingua corsa"},
		{"id":"cr", "flag":"", "name":"Cree","nativeName":"ᓀᐦᐃᔭᐍᐏᐣ"},
		{"id":"hr", "flag":"", "name":"Croatian","nativeName":"hrvatski"},
		{"id":"cs", "flag":"", "name":"Czech","nativeName":"česky, čeština"},
		{"id":"da", "flag":"", "name":"Danish","nativeName":"dansk"},
		{"id":"dv", "flag":"", "name":"Divehi; Dhivehi; Maldivian;","nativeName":"ދިވެހި"},
		{"id":"nl", "flag":"", "name":"Dutch","nativeName":"Nederlands, Vlaams"},
		{"id":"en", "flag":"", "name":"English","nativeName":"English"},
		{"id":"eo", "flag":"", "name":"Esperanto","nativeName":"Esperanto"},
		{"id":"et", "flag":"", "name":"Estonian","nativeName":"eesti, eesti keel"},
		{"id":"ee", "flag":"", "name":"Ewe","nativeName":"Eʋegbe"},
		{"id":"fo", "flag":"", "name":"Faroese","nativeName":"føroyskt"},
		{"id":"fj", "flag":"", "name":"Fijian","nativeName":"vosa Vakaviti"},
		{"id":"fi", "flag":"", "name":"Finnish","nativeName":"suomi, suomen kieli"},
		{"id":"fr", "flag":"", "name":"French","nativeName":"français, langue française"},
		{"id":"ff", "flag":"", "name":"Fula; Fulah; Pulaar; Pular","nativeName":"Fulfulde, Pulaar, Pular"},
		{"id":"gl", "flag":"", "name":"Galician","nativeName":"Galego"},
		{"id":"ka", "flag":"", "name":"Georgian","nativeName":"ქართული"},
		{"id":"de", "flag":"", "name":"German","nativeName":"Deutsch"},
		{"id":"el", "flag":"", "name":"Greek, Modern","nativeName":"Ελληνικά"},
		{"id":"gn", "flag":"", "name":"Guaraní","nativeName":"Avañeẽ"},
		{"id":"gu", "flag":"", "name":"Gujarati","nativeName":"ગુજરાતી"},
		{"id":"ht", "flag":"", "name":"Haitian; Haitian Creole","nativeName":"Kreyòl ayisyen"},
		{"id":"ha", "flag":"", "name":"Hausa","nativeName":"Hausa, هَوُسَ"},
		{"id":"he", "flag":"", "name":"Hebrew (modern)","nativeName":"עברית"},
		{"id":"hz", "flag":"", "name":"Herero","nativeName":"Otjiherero"},
		{"id":"hi", "flag":"", "name":"Hindi","nativeName":"हिन्दी, हिंदी"},
		{"id":"ho", "flag":"", "name":"Hiri Motu","nativeName":"Hiri Motu"},
		{"id":"hu", "flag":"", "name":"Hungarian","nativeName":"Magyar"},
		{"id":"ia", "flag":"", "name":"Interlingua","nativeName":"Interlingua"},
		{"id":"id", "flag":"", "name":"Indonesian","nativeName":"Bahasa Indonesia"},
		{"id":"ie", "flag":"", "name":"Interlingue","nativeName":"Originally called Occidental; then Interlingue after WWII"},
		{"id":"ga", "flag":"", "name":"Irish","nativeName":"Gaeilge"},
		{"id":"ig", "flag":"", "name":"Igbo","nativeName":"Asụsụ Igbo"},
		{"id":"ik", "flag":"", "name":"Inupiaq","nativeName":"Iñupiaq, Iñupiatun"},
		{"id":"io", "flag":"", "name":"Ido","nativeName":"Ido"},
		{"id":"is", "flag":"", "name":"Icelandic","nativeName":"Íslenska"},
		{"id":"it", "flag":"", "name":"Italian","nativeName":"Italiano"},
		{"id":"iu", "flag":"", "name":"Inuktitut","nativeName":"ᐃᓄᒃᑎᑐᑦ"},
		{"id":"ja", "flag":"", "name":"Japanese","nativeName":"日本語 (にほんご／にっぽんご)"},
		{"id":"jv", "flag":"", "name":"Javanese","nativeName":"basa Jawa"},
		{"id":"kl", "flag":"", "name":"Kalaallisut, Greenlandic","nativeName":"kalaallisut, kalaallit oqaasii"},
		{"id":"kn", "flag":"", "name":"Kannada","nativeName":"ಕನ್ನಡ"},
		{"id":"kr", "flag":"", "name":"Kanuri","nativeName":"Kanuri"},
		{"id":"ks", "flag":"", "name":"Kashmiri","nativeName":"कश्मीरी, كشميري"},
		{"id":"kk", "flag":"", "name":"Kazakh","nativeName":"Қазақ тілі"},
		{"id":"km", "flag":"", "name":"Khmer","nativeName":"ភាសាខ្មែរ"},
		{"id":"ki", "flag":"", "name":"Kikuyu, Gikuyu","nativeName":"Gĩkũyũ"},
		{"id":"rw", "flag":"", "name":"Kinyarwanda","nativeName":"Ikinyarwanda"},
		{"id":"ky", "flag":"", "name":"Kirghiz, Kyrgyz","nativeName":"кыргыз тили"},
		{"id":"kv", "flag":"", "name":"Komi","nativeName":"коми кыв"},
		{"id":"kg", "flag":"", "name":"Kongo","nativeName":"KiKongo"},
		{"id":"ko", "flag":"", "name":"Korean","nativeName":"한국어 (韓國語), 조선말 (朝鮮語)"},
		{"id":"ku", "flag":"", "name":"Kurdish","nativeName":"Kurdî, كوردی"},
		{"id":"kj", "flag":"", "name":"Kwanyama, Kuanyama","nativeName":"Kuanyama"},
		{"id":"la", "flag":"", "name":"Latin","nativeName":"latine, lingua latina"},
		{"id":"lb", "flag":"", "name":"Luxembourgish, Letzeburgesch","nativeName":"Lëtzebuergesch"},
		{"id":"lg", "flag":"", "name":"Luganda","nativeName":"Luganda"},
		{"id":"li", "flag":"", "name":"Limburgish, Limburgan, Limburger","nativeName":"Limburgs"},
		{"id":"ln", "flag":"", "name":"Lingala","nativeName":"Lingála"},
		{"id":"lo", "flag":"", "name":"Lao","nativeName":"ພາສາລາວ"},
		{"id":"lt", "flag":"", "name":"Lithuanian","nativeName":"lietuvių kalba"},
		{"id":"lu", "flag":"", "name":"Luba-Katanga","nativeName":""},
		{"id":"lv", "flag":"", "name":"Latvian","nativeName":"latviešu valoda"},
		{"id":"gv", "flag":"", "name":"Manx","nativeName":"Gaelg, Gailck"},
		{"id":"mk", "flag":"", "name":"Macedonian","nativeName":"македонски јазик"},
		{"id":"mg", "flag":"", "name":"Malagasy","nativeName":"Malagasy fiteny"},
		{"id":"ms", "flag":"", "name":"Malay","nativeName":"bahasa Melayu, بهاس ملايو"},
		{"id":"ml", "flag":"", "name":"Malayalam","nativeName":"മലയാളം"},
		{"id":"mt", "flag":"", "name":"Maltese","nativeName":"Malti"},
		{"id":"mi", "flag":"", "name":"Māori","nativeName":"te reo Māori"},
		{"id":"mr", "flag":"", "name":"Marathi (Marāṭhī)","nativeName":"मराठी"},
		{"id":"mh", "flag":"", "name":"Marshallese","nativeName":"Kajin M̧ajeļ"},
		{"id":"mn", "flag":"", "name":"Mongolian","nativeName":"монгол"},
		{"id":"na", "flag":"", "name":"Nauru","nativeName":"Ekakairũ Naoero"},
		{"id":"nv", "flag":"", "name":"Navajo, Navaho","nativeName":"Diné bizaad, Dinékʼehǰí"},
		{"id":"nb", "flag":"", "name":"Norwegian Bokmål","nativeName":"Norsk bokmål"},
		{"id":"nd", "flag":"", "name":"North Ndebele","nativeName":"isiNdebele"},
		{"id":"ne", "flag":"", "name":"Nepali","nativeName":"नेपाली"},
		{"id":"ng", "flag":"", "name":"Ndonga","nativeName":"Owambo"},
		{"id":"nn", "flag":"", "name":"Norwegian Nynorsk","nativeName":"Norsk nynorsk"},
		{"id":"no", "flag":"", "name":"Norwegian","nativeName":"Norsk"},
		{"id":"ii", "flag":"", "name":"Nuosu","nativeName":"ꆈꌠ꒿ Nuosuhxop"},
		{"id":"nr", "flag":"", "name":"South Ndebele","nativeName":"isiNdebele"},
		{"id":"oc", "flag":"", "name":"Occitan","nativeName":"Occitan"},
		{"id":"oj", "flag":"", "name":"Ojibwe, Ojibwa","nativeName":"ᐊᓂᔑᓈᐯᒧᐎᓐ"},
		{"id":"cu", "flag":"", "name":"Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic","nativeName":"ѩзыкъ словѣньскъ"},
		{"id":"om", "flag":"", "name":"Oromo","nativeName":"Afaan Oromoo"},
		{"id":"or", "flag":"", "name":"Oriya","nativeName":"ଓଡ଼ିଆ"},
		{"id":"os", "flag":"", "name":"Ossetian, Ossetic","nativeName":"ирон æвзаг"},
		{"id":"pa", "flag":"", "name":"Panjabi, Punjabi","nativeName":"ਪੰਜਾਬੀ, پنجابی"},
		{"id":"pi", "flag":"", "name":"Pāli","nativeName":"पाऴि"},
		{"id":"fa", "flag":"", "name":"Persian","nativeName":"فارسی"},
		{"id":"pl", "flag":"", "name":"Polish","nativeName":"polski"},
		{"id":"ps", "flag":"", "name":"Pashto, Pushto","nativeName":"پښتو"},
		{"id":"pt", "flag":"", "name":"Portuguese","nativeName":"Português"},
		{"id":"qu", "flag":"", "name":"Quechua","nativeName":"Runa Simi, Kichwa"},
		{"id":"rm", "flag":"", "name":"Romansh","nativeName":"rumantsch grischun"},
		{"id":"rn", "flag":"", "name":"Kirundi","nativeName":"kiRundi"},
		{"id":"ro", "flag":"", "name":"Romanian, Moldavian, Moldovan","nativeName":"română"},
		{"id":"ru", "flag":"", "name":"Russian","nativeName":"русский язык"},
		{"id":"sa", "flag":"", "name":"Sanskrit (Saṁskṛta)","nativeName":"संस्कृतम्"},
		{"id":"sc", "flag":"", "name":"Sardinian","nativeName":"sardu"},
		{"id":"sd", "flag":"", "name":"Sindhi","nativeName":"सिन्धी, سنڌي، سندھی"},
		{"id":"se", "flag":"", "name":"Northern Sami","nativeName":"Davvisámegiella"},
		{"id":"sm", "flag":"", "name":"Samoan","nativeName":"gagana faa Samoa"},
		{"id":"sg", "flag":"", "name":"Sango","nativeName":"yângâ tî sängö"},
		{"id":"sr", "flag":"", "name":"Serbian","nativeName":"српски језик"},
		{"id":"gd", "flag":"", "name":"Scottish Gaelic; Gaelic","nativeName":"Gàidhlig"},
		{"id":"sn", "flag":"", "name":"Shona","nativeName":"chiShona"},
		{"id":"si", "flag":"", "name":"Sinhala, Sinhalese","nativeName":"සිංහල"},
		{"id":"sk", "flag":"", "name":"Slovak","nativeName":"slovenčina"},
		{"id":"sl", "flag":"", "name":"Slovene","nativeName":"slovenščina"},
		{"id":"so", "flag":"", "name":"Somali","nativeName":"Soomaaliga, af Soomaali"},
		{"id":"st", "flag":"", "name":"Southern Sotho","nativeName":"Sesotho"},
		{"id":"es", "flag":"", "name":"Spanish; Castilian","nativeName":"español, castellano"},
		{"id":"su", "flag":"", "name":"Sundanese","nativeName":"Basa Sunda"},
		{"id":"sw", "flag":"", "name":"Swahili","nativeName":"Kiswahili"},
		{"id":"ss", "flag":"", "name":"Swati","nativeName":"SiSwati"},
		{"id":"sv", "flag":"", "name":"Swedish","nativeName":"svenska"},
		{"id":"ta", "flag":"", "name":"Tamil","nativeName":"தமிழ்"},
		{"id":"te", "flag":"", "name":"Telugu","nativeName":"తెలుగు"},
		{"id":"tg", "flag":"", "name":"Tajik","nativeName":"тоҷикӣ, toğikī, تاجیکی"},
		{"id":"th", "flag":"", "name":"Thai","nativeName":"ไทย"},
		{"id":"ti", "flag":"", "name":"Tigrinya","nativeName":"ትግርኛ"},
		{"id":"bo", "flag":"", "name":"Tibetan Standard, Tibetan, Central","nativeName":"བོད་ཡིག"},
		{"id":"tk", "flag":"", "name":"Turkmen","nativeName":"Türkmen, Түркмен"},
		{"id":"tl", "flag":"", "name":"Tagalog","nativeName":"Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"},
		{"id":"tn", "flag":"", "name":"Tswana","nativeName":"Setswana"},
		{"id":"to", "flag":"", "name":"Tonga (Tonga Islands)","nativeName":"faka Tonga"},
		{"id":"tr", "flag":"", "name":"Turkish","nativeName":"Türkçe"},
		{"id":"ts", "flag":"", "name":"Tsonga","nativeName":"Xitsonga"},
		{"id":"tt", "flag":"", "name":"Tatar","nativeName":"татарча, tatarça, تاتارچا"},
		{"id":"tw", "flag":"", "name":"Twi","nativeName":"Twi"},
		{"id":"ty", "flag":"", "name":"Tahitian","nativeName":"Reo Tahiti"},
		{"id":"ug", "flag":"", "name":"Uighur, Uyghur","nativeName":"Uyƣurqə, ئۇيغۇرچە"},
		{"id":"uk", "flag":"", "name":"Ukrainian","nativeName":"українська"},
		{"id":"ur", "flag":"", "name":"Urdu","nativeName":"اردو"},
		{"id":"uz", "flag":"", "name":"Uzbek","nativeName":"zbek, Ўзбек, أۇزبېك"},
		{"id":"ve", "flag":"", "name":"Venda","nativeName":"Tshivenḓa"},
		{"id":"vi", "flag":"", "name":"Vietnamese","nativeName":"Tiếng Việt"},
		{"id":"vo", "flag":"", "name":"Volapük","nativeName":"Volapük"},
		{"id":"wa", "flag":"", "name":"Walloon","nativeName":"Walon"},
		{"id":"cy", "flag":"", "name":"Welsh","nativeName":"Cymraeg"},
		{"id":"wo", "flag":"", "name":"Wolof","nativeName":"Wollof"},
		{"id":"fy", "flag":"", "name":"Western Frisian","nativeName":"Frysk"},
		{"id":"xh", "flag":"", "name":"Xhosa","nativeName":"isiXhosa"},
		{"id":"yi", "flag":"", "name":"Yiddish","nativeName":"ייִדיש"},
		{"id":"yo", "flag":"", "name":"Yoruba","nativeName":"Yorùbá"},
		{"id":"za", "flag":"", "name":"Zhuang, Chuang","nativeName":"Saɯ cueŋƅ, Saw cuengh"}
	]);

	const citizenship = [
		{"id": "AF", "status": true, "code3": "AFG", "name": _.get(messages, `${lang}.library.citizenship.afghanistan`), "number": "004"},
		{"id": "AL", "status": true, "code3": "ALB", "name": _.get(messages, `${lang}.library.citizenship.albania`), "number": "008"},
		{"id": "DZ", "status": true, "code3": "DZA", "name": "Algeria", "number": "012"},
		{"id": "AS", "status": true, "code3": "ASM", "name": "American Samoa", "number": "016"},
		{"id": "AD", "status": true, "code3": "AND", "name": "Andorra", "number": "020"},
		{"id": "AO", "status": true, "code3": "AGO", "name": "Angola", "number": "024"},
		{"id": "AI", "status": true, "code3": "AIA", "name": "Anguilla", "number": "660"},
		{"id": "AQ", "status": true, "code3": "ATA", "name": "Antarctica", "number": "010"},
		{"id": "AG", "status": true, "code3": "ATG", "name": "Antigua and Barbuda", "number": "028"},
		{"id": "AR", "status": true, "code3": "ARG", "name": "Argentina", "number": "032"},
		{"id": "AM", "status": true, "code3": "ARM", "name": "Armenia", "number": "051"},
		{"id": "AW", "status": true, "code3": "ABW", "name": "Aruba", "number": "533"},
		{"id": "AU", "status": true, "code3": "AUS", "name": "Australia", "number": "036"},
		{"id": "AT", "status": true, "code3": "AUT", "name": "Austria", "number": "040"},
		{"id": "AZ", "status": true, "code3": "AZE", "name": "Azerbaijan", "number": "031"},
		{"id": "BS", "status": true, "code3": "BHS", "name": "Bahamas (the)", "number": "044"},
		{"id": "BH", "status": true, "code3": "BHR", "name": "Bahrain", "number": "048"},
		{"id": "BD", "status": true, "code3": "BGD", "name": "Bangladesh", "number": "050"},
		{"id": "BB", "status": true, "code3": "BRB", "name": "Barbados", "number": "052"},
		{"id": "BY", "status": true, "code3": "BLR", "name": "Belarus", "number": "112"},
		{"id": "BE", "status": true, "code3": "BEL", "name": "Belgium", "number": "056"},
		{"id": "BZ", "status": true, "code3": "BLZ", "name": "Belize", "number": "084"},
		{"id": "BJ", "status": true, "code3": "BEN", "name": "Benin", "number": "204"},
		{"id": "BM", "status": true, "code3": "BMU", "name": "Bermuda", "number": "060"},
		{"id": "BT", "status": true, "code3": "BTN", "name": "Bhutan", "number": "064"},
		{"id": "BO", "status": true, "code3": "BOL", "name": "Bolivia (Plurinational State of)", "number": "068"},
		{"id": "BQ", "status": true, "code3": "BES", "name": "Bonaire, Sint Eustatius and Saba", "number": "535"},
		{"id": "BA", "status": true, "code3": "BIH", "name": "Bosnia and Herzegovina", "number": "070"},
		{"id": "BW", "status": true, "code3": "BWA", "name": "Botswana", "number": "072"},
		{"id": "BV", "status": true, "code3": "BVT", "name": "Bouvet Island", "number": "074"},
		{"id": "BR", "status": true, "code3": "BRA", "name": "Brazil", "number": "076"},
		{"id": "IO", "status": true, "code3": "IOT", "name": "British Indian Ocean Territory (the)", "number": "086"},
		{"id": "BN", "status": true, "code3": "BRN", "name": "Brunei Darussalam", "number": "096"},
		{"id": "BG", "status": true, "code3": "BGR", "name": "Bulgaria", "number": "100"},
		{"id": "BF", "status": true, "code3": "BFA", "name": "Burkina Faso", "number": "854"},
		{"id": "BI", "status": true, "code3": "BDI", "name": "Burundi", "number": "108"},
		{"id": "CV", "status": true, "code3": "CPV", "name": "Cabo Verde", "number": "132"},
		{"id": "KH", "status": true, "code3": "KHM", "name": "Cambodia", "number": "116"},
		{"id": "CM", "status": true, "code3": "CMR", "name": "Cameroon", "number": "120"},
		{"id": "CA", "status": true, "code3": "CAN", "name": "Canada", "number": "124"},
		{"id": "KY", "status": true, "code3": "CYM", "name": "Cayman Islands (the)", "number": "136"},
		{"id": "CF", "status": true, "code3": "CAF", "name": "Central African Republic (the)", "number": "140"},
		{"id": "TD", "status": true, "code3": "TCD", "name": "Chad", "number": "148"},
		{"id": "CL", "status": true, "code3": "CHL", "name": "Chile", "number": "152"},
		{"id": "CN", "status": true, "code3": "CHN", "name": "China", "number": "156"},
		{"id": "CX", "status": true, "code3": "CXR", "name": "Christmas Island", "number": "162"},
		{"id": "CC", "status": true, "code3": "CCK", "name": "Cocos (Keeling) Islands (the)", "number": "166"},
		{"id": "CO", "status": true, "code3": "COL", "name": "Colombia", "number": "170"},
		{"id": "KM", "status": true, "code3": "COM", "name": "Comoros (the)", "number": "174"},
		{"id": "CD", "status": true, "code3": "COD", "name": "Congo (the Democratic Republic of the)", "number": "180"},
		{"id": "CG", "status": true, "code3": "COG", "name": "Congo (the)", "number": "178"},
		{"id": "CK", "status": true, "code3": "COK", "name": "Cook Islands (the)", "number": "184"},
		{"id": "CR", "status": true, "code3": "CRI", "name": "Costa Rica", "number": "188"},
		{"id": "HR", "status": true, "code3": "HRV", "name": "Croatia", "number": "191"},
		{"id": "CU", "status": true, "code3": "CUB", "name": "Cuba", "number": "192"},
		{"id": "CW", "status": true, "code3": "CUW", "name": "Curaçao", "number": "531"},
		{"id": "CY", "status": true, "code3": "CYP", "name": "Cyprus", "number": "196"},
		{"id": "CZ", "status": true, "code3": "CZE", "name": "Czechia", "number": "203"},
		{"id": "CI", "status": true, "code3": "CIV", "name": "Côte d'Ivoire", "number": "384"},
		{"id": "DK", "status": true, "code3": "DNK", "name": "Denmark", "number": "208"},
		{"id": "DJ", "status": true, "code3": "DJI", "name": "Djibouti", "number": "262"},
		{"id": "DM", "status": true, "code3": "DMA", "name": "Dominica", "number": "212"},
		{"id": "DO", "status": true, "code3": "DOM", "name": "Dominican Republic (the)", "number": "214"},
		{"id": "EC", "status": true, "code3": "ECU", "name": "Ecuador", "number": "218"},
		{"id": "EG", "status": true, "code3": "EGY", "name": "Egypt", "number": "818"},
		{"id": "SV", "status": true, "code3": "SLV", "name": "El Salvador", "number": "222"},
		{"id": "GQ", "status": true, "code3": "GNQ", "name": "Equatorial Guinea", "number": "226"},
		{"id": "ER", "status": true, "code3": "ERI", "name": "Eritrea", "number": "232"},
		{"id": "EE", "status": true, "code3": "EST", "name": "Estonia", "number": "233"},
		{"id": "SZ", "status": true, "code3": "SWZ", "name": "Eswatini", "number": "748"},
		{"id": "ET", "status": true, "code3": "ETH", "name": "Ethiopia", "number": "231"},
		{"id": "FK", "status": true, "code3": "FLK", "name": "Falkland Islands (the) [Malvinas]", "number": "238"},
		{"id": "FO", "status": true, "code3": "FRO", "name": "Faroe Islands (the)", "number": "234"},
		{"id": "FJ", "status": true, "code3": "FJI", "name": "Fiji", "number": "242"},
		{"id": "FI", "status": true, "code3": "FIN", "name": "Finland", "number": "246"},
		{"id": "FR", "status": true, "code3": "FRA", "name": "France", "number": "250"},
		{"id": "GF", "status": true, "code3": "GUF", "name": "French Guiana", "number": "254"},
		{"id": "PF", "status": true, "code3": "PYF", "name": "French Polynesia", "number": "258"},
		{"id": "TF", "status": true, "code3": "ATF", "name": "French Southern Territories (the)", "number": "260"},
		{"id": "GA", "status": true, "code3": "GAB", "name": "Gabon", "number": "266"},
		{"id": "GM", "status": true, "code3": "GMB", "name": "Gambia (the)", "number": "270"},
		{"id": "GE", "status": true, "code3": "GEO", "name": "Georgia", "number": "268"},
		{"id": "DE", "status": false, "code3": "DEU", "name": "Germany", "number": "276"},
		{"id": "GH", "status": true, "code3": "GHA", "name": "Ghana", "number": "288"},
		{"id": "GI", "status": true, "code3": "GIB", "name": "Gibraltar", "number": "292"},
		{"id": "GR", "status": true, "code3": "GRC", "name": "Greece", "number": "300"},
		{"id": "GL", "status": true, "code3": "GRL", "name": "Greenland", "number": "304"},
		{"id": "GD", "status": true, "code3": "GRD", "name": "Grenada", "number": "308"},
		{"id": "GP", "status": true, "code3": "GLP", "name": "Guadeloupe", "number": "312"},
		{"id": "GU", "status": true, "code3": "GUM", "name": "Guam", "number": "316"},
		{"id": "GT", "status": true, "code3": "GTM", "name": "Guatemala", "number": "320"},
		{"id": "GG", "status": true, "code3": "GGY", "name": "Guernsey", "number": "831"},
		{"id": "GN", "status": true, "code3": "GIN", "name": "Guinea", "number": "324"},
		{"id": "GW", "status": true, "code3": "GNB", "name": "Guinea-Bissau", "number": "624"},
		{"id": "GY", "status": true, "code3": "GUY", "name": "Guyana", "number": "328"},
		{"id": "HT", "status": true, "code3": "HTI", "name": "Haiti", "number": "332"},
		{"id": "HM", "status": true, "code3": "HMD", "name": "Heard Island and McDonald Islands", "number": "334"},
		{"id": "VA", "status": true, "code3": "VAT", "name": "Holy See (the)", "number": "336"},
		{"id": "HN", "status": true, "code3": "HND", "name": "Honduras", "number": "340"},
		{"id": "HK", "status": true, "code3": "HKG", "name": "Hong Kong", "number": "344"},
		{"id": "HU", "status": true, "code3": "HUN", "name": "Hungary", "number": "348"},
		{"id": "IS", "status": true, "code3": "ISL", "name": "Iceland", "number": "352"},
		{"id": "IN", "status": true, "code3": "IND", "name": "India", "number": "356"},
		{"id": "ID", "status": true, "code3": "IDN", "name": "Indonesia", "number": "360"},
		{"id": "IR", "status": true, "code3": "IRN", "name": "Iran (Islamic Republic of)", "number": "364"},
		{"id": "IQ", "status": true, "code3": "IRQ", "name": "Iraq", "number": "368"},
		{"id": "IE", "status": true, "code3": "IRL", "name": "Ireland", "number": "372"},
		{"id": "IM", "status": true, "code3": "IMN", "name": "Isle of Man", "number": "833"},
		{"id": "IL", "status": true, "code3": "ISR", "name": "Israel", "number": "376"},
		{"id": "IT", "status": true, "code3": "ITA", "name": "Italy", "number": "380"},
		{"id": "JM", "status": true, "code3": "JAM", "name": "Jamaica", "number": "388"},
		{"id": "JP", "status": true, "code3": "JPN", "name": "Japan", "number": "392"},
		{"id": "JE", "status": true, "code3": "JEY", "name": "Jersey", "number": "832"},
		{"id": "JO", "status": true, "code3": "JOR", "name": "Jordan", "number": "400"},
		{"id": "KZ", "status": true, "code3": "KAZ", "name": "Kazakhstan", "number": "398"},
		{"id": "KE", "status": true, "code3": "KEN", "name": "Kenya", "number": "404"},
		{"id": "KI", "status": true, "code3": "KIR", "name": "Kiribati", "number": "296"},
		{"id": "KP", "status": true, "code3": "PRK", "name": "Korea (the Democratic People's Republic of)", "number": "408"},
		{"id": "KR", "status": true, "code3": "KOR", "name": "Korea (the Republic of)", "number": "410"},
		{"id": "KW", "status": true, "code3": "KWT", "name": "Kuwait", "number": "414"},
		{"id": "KG", "status": true, "code3": "KGZ", "name": "Kyrgyzstan", "number": "417"},
		{"id": "LA", "status": true, "code3": "LAO", "name": "Lao People's Democratic Republic (the)", "number": "418"},
		{"id": "LV", "status": true, "code3": "LVA", "name": "Latvia", "number": "428"},
		{"id": "LB", "status": true, "code3": "LBN", "name": "Lebanon", "number": "422"},
		{"id": "LS", "status": true, "code3": "LSO", "name": "Lesotho", "number": "426"},
		{"id": "LR", "status": true, "code3": "LBR", "name": "Liberia", "number": "430"},
		{"id": "LY", "status": true, "code3": "LBY", "name": "Libya", "number": "434"},
		{"id": "LI", "status": true, "code3": "LIE", "name": "Liechtenstein", "number": "438"},
		{"id": "LT", "status": true, "code3": "LTU", "name": "Lithuania", "number": "440"},
		{"id": "LU", "status": true, "code3": "LUX", "name": "Luxembourg", "number": "442"},
		{"id": "MO", "status": true, "code3": "MAC", "name": "Macao", "number": "446"},
		{"id": "MG", "status": true, "code3": "MDG", "name": "Madagascar", "number": "450"},
		{"id": "MW", "status": true, "code3": "MWI", "name": "Malawi", "number": "454"},
		{"id": "MY", "status": true, "code3": "MYS", "name": "Malaysia", "number": "458"},
		{"id": "MV", "status": true, "code3": "MDV", "name": "Maldives", "number": "462"},
		{"id": "ML", "status": true, "code3": "MLI", "name": "Mali", "number": "466"},
		{"id": "MT", "status": true, "code3": "MLT", "name": "Malta", "number": "470"},
		{"id": "MH", "status": true, "code3": "MHL", "name": "Marshall Islands (the)", "number": "584"},
		{"id": "MQ", "status": true, "code3": "MTQ", "name": "Martinique", "number": "474"},
		{"id": "MR", "status": true, "code3": "MRT", "name": "Mauritania", "number": "478"},
		{"id": "MU", "status": true, "code3": "MUS", "name": "Mauritius", "number": "480"},
		{"id": "YT", "status": true, "code3": "MYT", "name": "Mayotte", "number": "175"},
		{"id": "MX", "status": true, "code3": "MEX", "name": "Mexico", "number": "484"},
		{"id": "FM", "status": true, "code3": "FSM", "name": "Micronesia (Federated States of)", "number": "583"},
		{"id": "MD", "status": true, "code3": "MDA", "name": "Moldova (the Republic of)", "number": "498"},
		{"id": "MC", "status": true, "code3": "MCO", "name": "Monaco", "number": "492"},
		{"id": "MN", "status": true, "code3": "MNG", "name": "Mongolia", "number": "496"},
		{"id": "ME", "status": true, "code3": "MNE", "name": "Montenegro", "number": "499"},
		{"id": "MS", "status": true, "code3": "MSR", "name": "Montserrat", "number": "500"},
		{"id": "MA", "status": true, "code3": "MAR", "name": "Morocco", "number": "504"},
		{"id": "MZ", "status": true, "code3": "MOZ", "name": "Mozambique", "number": "508"},
		{"id": "MM", "status": true, "code3": "MMR", "name": "Myanmar", "number": "104"},
		{"id": "NA", "status": true, "code3": "NAM", "name": "Namibia", "number": "516"},
		{"id": "NR", "status": true, "code3": "NRU", "name": "Nauru", "number": "520"},
		{"id": "NP", "status": true, "code3": "NPL", "name": "Nepal", "number": "524"},
		{"id": "NL", "status": false, "code3": "NLD", "name": "Netherlands (the)", "number": "528"},
		{"id": "NC", "status": true, "code3": "NCL", "name": "New Caledonia", "number": "540"},
		{"id": "NZ", "status": true, "code3": "NZL", "name": "New Zealand", "number": "554"},
		{"id": "NI", "status": true, "code3": "NIC", "name": "Nicaragua", "number": "558"},
		{"id": "NE", "status": true, "code3": "NER", "name": "Niger (the)", "number": "562"},
		{"id": "NG", "status": true, "code3": "NGA", "name": "Nigeria", "number": "566"},
		{"id": "NU", "status": true, "code3": "NIU", "name": "Niue", "number": "570"},
		{"id": "NF", "status": true, "code3": "NFK", "name": "Norfolk Island", "number": "574"},
		{"id": "MP", "status": true, "code3": "MNP", "name": "Northern Mariana Islands (the)", "number": "580"},
		{"id": "NO", "status": true, "code3": "NOR", "name": "Norway", "number": "578"},
		{"id": "OM", "status": true, "code3": "OMN", "name": "Oman", "number": "512"},
		{"id": "PK", "status": true, "code3": "PAK", "name": "Pakistan", "number": "586"},
		{"id": "PW", "status": true, "code3": "PLW", "name": "Palau", "number": "585"},
		{"id": "PS", "status": true, "code3": "PSE", "name": "Palestine, State of", "number": "275"},
		{"id": "PA", "status": true, "code3": "PAN", "name": "Panama", "number": "591"},
		{"id": "PG", "status": true, "code3": "PNG", "name": "Papua New Guinea", "number": "598"},
		{"id": "PY", "status": true, "code3": "PRY", "name": "Paraguay", "number": "600"},
		{"id": "PE", "status": true, "code3": "PER", "name": "Peru", "number": "604"},
		{"id": "PH", "status": true, "code3": "PHL", "name": "Philippines (the)", "number": "608"},
		{"id": "PN", "status": true, "code3": "PCN", "name": "Pitcairn", "number": "612"},
		{"id": "PL", "status": false, "code3": "POL", "name": "Polska", "number": "616"},
		{"id": "PT", "status": true, "code3": "PRT", "name": "Portugal", "number": "620"},
		{"id": "PR", "status": true, "code3": "PRI", "name": "Puerto Rico", "number": "630"},
		{"id": "QA", "status": true, "code3": "QAT", "name": "Qatar", "number": "634"},
		{"id": "MK", "status": true, "code3": "MKD", "name": "Republic of North Macedonia", "number": "807"},
		{"id": "RO", "status": true, "code3": "ROU", "name": "Romania", "number": "642"},
		{"id": "RU", "status": true, "code3": "RUS", "name": "Russian Federation (the)", "number": "643"},
		{"id": "RW", "status": true, "code3": "RWA", "name": "Rwanda", "number": "646"},
		{"id": "RE", "status": true, "code3": "REU", "name": "Réunion", "number": "638"},
		{"id": "BL", "status": true, "code3": "BLM", "name": "Saint Barthélemy", "number": "652"},
		{"id": "SH", "status": true, "code3": "SHN", "name": "Saint Helena, Ascension and Tristan da Cunha", "number": "654"},
		{"id": "KN", "status": true, "code3": "KNA", "name": "Saint Kitts and Nevis", "number": "659"},
		{"id": "LC", "status": true, "code3": "LCA", "name": "Saint Lucia", "number": "662"},
		{"id": "MF", "status": true, "code3": "MAF", "name": "Saint Martin (French part)", "number": "663"},
		{"id": "PM", "status": true, "code3": "SPM", "name": "Saint Pierre and Miquelon", "number": "666"},
		{"id": "VC", "status": true, "code3": "VCT", "name": "Saint Vincent and the Grenadines", "number": "670"},
		{"id": "WS", "status": true, "code3": "WSM", "name": "Samoa", "number": "882"},
		{"id": "SM", "status": true, "code3": "SMR", "name": "San Marino", "number": "674"},
		{"id": "ST", "status": true, "code3": "STP", "name": "Sao Tome and Principe", "number": "678"},
		{"id": "SA", "status": true, "code3": "SAU", "name": "Saudi Arabia", "number": "682"},
		{"id": "SN", "status": true, "code3": "SEN", "name": "Senegal", "number": "686"},
		{"id": "RS", "status": true, "code3": "SRB", "name": "Serbia", "number": "688"},
		{"id": "SC", "status": true, "code3": "SYC", "name": "Seychelles", "number": "690"},
		{"id": "SL", "status": true, "code3": "SLE", "name": "Sierra Leone", "number": "694"},
		{"id": "SG", "status": true, "code3": "SGP", "name": "Singapore", "number": "702"},
		{"id": "SX", "status": true, "code3": "SXM", "name": "Sint Maarten (Dutch part)", "number": "534"},
		{"id": "SK", "status": true, "code3": "SVK", "name": "Slovakia", "number": "703"},
		{"id": "SI", "status": true, "code3": "SVN", "name": "Slovenia", "number": "705"},
		{"id": "SB", "status": true, "code3": "SLB", "name": "Solomon Islands", "number": "090"},
		{"id": "SO", "status": true, "code3": "SOM", "name": "Somalia", "number": "706"},
		{"id": "ZA", "status": true, "code3": "ZAF", "name": "South Africa", "number": "710"},
		{"id": "GS", "status": true, "code3": "SGS", "name": "South Georgia and the South Sandwich Islands", "number": "239"},
		{"id": "SS", "status": true, "code3": "SSD", "name": "South Sudan", "number": "728"},
		{"id": "ES", "status": true, "code3": "ESP", "name": "Spain", "number": "724"},
		{"id": "LK", "status": true, "code3": "LKA", "name": "Sri Lanka", "number": "144"},
		{"id": "SD", "status": true, "code3": "SDN", "name": "Sudan (the)", "number": "729"},
		{"id": "SR", "status": true, "code3": "SUR", "name": "Suriname", "number": "740"},
		{"id": "SJ", "status": true, "code3": "SJM", "name": "Svalbard and Jan Mayen", "number": "744"},
		{"id": "SE", "status": true, "code3": "SWE", "name": "Sweden", "number": "752"},
		{"id": "CH", "status": true, "code3": "CHE", "name": "Switzerland", "number": "756"},
		{"id": "SY", "status": true, "code3": "SYR", "name": "Syrian Arab Republic", "number": "760"},
		{"id": "TW", "status": true, "code3": "TWN", "name": "Taiwan", "number": "158"},
		{"id": "TJ", "status": true, "code3": "TJK", "name": "Tajikistan", "number": "762"},
		{"id": "TZ", "status": true, "code3": "TZA", "name": "Tanzania, United Republic of", "number": "834"},
		{"id": "TH", "status": true, "code3": "THA", "name": "Thailand", "number": "764"},
		{"id": "TL", "status": true, "code3": "TLS", "name": "Timor-Leste", "number": "626"},
		{"id": "TG", "status": true, "code3": "TGO", "name": "Togo", "number": "768"},
		{"id": "TK", "status": true, "code3": "TKL", "name": "Tokelau", "number": "772"},
		{"id": "TO", "status": true, "code3": "TON", "name": "Tonga", "number": "776"},
		{"id": "TT", "status": true, "code3": "TTO", "name": "Trinidad and Tobago", "number": "780"},
		{"id": "TN", "status": true, "code3": "TUN", "name": "Tunisia", "number": "788"},
		{"id": "TR", "status": true, "code3": "TUR", "name": "Turkey", "number": "792"},
		{"id": "TM", "status": true, "code3": "TKM", "name": "Turkmenistan", "number": "795"},
		{"id": "TC", "status": true, "code3": "TCA", "name": "Turks and Caicos Islands (the)", "number": "796"},
		{"id": "TV", "status": true, "code3": "TUV", "name": "Tuvalu", "number": "798"},
		{"id": "UG", "status": true, "code3": "UGA", "name": "Uganda", "number": "800"},
		{"id": "UA", "status": true, "code3": "UKR", "name": "Ukraine", "number": "804"},
		{"id": "AE", "status": true, "code3": "ARE", "name": "United Arab Emirates (the)", "number": "784"},
		{"id": "GB", "status": true, "code3": "GBR", "name": "United Kingdom of Great Britain and Northern Ireland (the)", "number": "826"},
		{"id": "UM", "status": true, "code3": "UMI", "name": "United States Minor Outlying Islands (the)", "number": "581"},
		{"id": "US", "status": true, "code3": "USA", "name": "United States of America (the)", "number": "840"},
		{"id": "UY", "status": true, "code3": "URY", "name": "Uruguay", "number": "858"},
		{"id": "UZ", "status": true, "code3": "UZB", "name": "Uzbekistan", "number": "860"},
		{"id": "VU", "status": true, "code3": "VUT", "name": "Vanuatu", "number": "548"},
		{"id": "VE", "status": true, "code3": "VEN", "name": "Venezuela (Bolivarian Republic of)", "number": "862"},
		{"id": "VN", "status": true, "code3": "VNM", "name": "Viet Nam", "number": "704"},
		{"id": "VG", "status": true, "code3": "VGB", "name": "Virgin Islands (British)", "number": "092"},
		{"id": "VI", "status": true, "code3": "VIR", "name": "Virgin Islands (U.S.)", "number": "850"},
		{"id": "WF", "status": true, "code3": "WLF", "name": "Wallis and Futuna", "number": "876"},
		{"id": "EH", "status": true, "code3": "ESH", "name": "Western Sahara", "number": "732"},
		{"id": "YE", "status": true, "code3": "YEM", "name": "Yemen", "number": "887"},
		{"id": "ZM", "status": true, "code3": "ZMB", "name": "Zambia", "number": "894"},
		{"id": "ZW", "status": true, "code3": "ZWE", "name": "Zimbabwe", "number": "716"},
		{"id": "AX", "status": true, "code3": "ALA", "name": "Åland Islands", "number": "248"}
	];

	const labelCitizenship = (index) => {
		if (index) {
			index = index.toUpperCase();
		}
		let item = citizenship.find(e => e.id == index);
		if (item) {
			return item.name;
		}
	}

	const labelLanguage = (index) => {
		if (index) {
			index = index.toLowerCase();
		}
		let item = languagesList.value.find(e => e.id == index);
		if (item) {
			return item.nativeName;
		}
	}

	const digitCountry = [
			{
			    "name": "Afghanistan",
			    "dialCode": "+93",
			    "isoCode": "AF",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/af.svg"
			},
			{
			    "name": "Aland Islands",
			    "dialCode": "+358",
			    "isoCode": "AX",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ax.svg"
			},
			{
			    "name": "Albania",
			    "dialCode": "+355",
			    "isoCode": "AL",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/al.svg"
			},
			{
			    "name": "Algeria",
			    "dialCode": "+213",
			    "isoCode": "DZ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/dz.svg"
			},
			{
			    "name": "American Samoa",
			    "dialCode": "+1684",
			    "isoCode": "AS",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/as.svg"
			},
			{
			    "name": "Andorra",
			    "dialCode": "+376",
			    "isoCode": "AD",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ad.svg"
			},
			{
			    "name": "Angola",
			    "dialCode": "+244",
			    "isoCode": "AO",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ao.svg"
			},
			{
			    "name": "Anguilla",
			    "dialCode": "+1264",
			    "isoCode": "AI",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ai.svg"
			},
			{
			    "name": "Antarctica",
			    "dialCode": "+672",
			    "isoCode": "AQ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/aq.svg"
			},
			{
			    "name": "Antigua and Barbuda",
			    "dialCode": "+1268",
			    "isoCode": "AG",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ag.svg"
			},
			{
			    "name": "Argentina",
			    "dialCode": "+54",
			    "isoCode": "AR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ar.svg"
			},
			{
			    "name": "Armenia",
			    "dialCode": "+374",
			    "isoCode": "AM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/am.svg"
			},
			{
			    "name": "Aruba",
			    "dialCode": "+297",
			    "isoCode": "AW",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/aw.svg"
			},
			{
			    "name": "Ascension Island",
			    "dialCode": "+247",
			    "isoCode": "AC",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ac.svg"
			},
			{
			    "name": "Australia",
			    "dialCode": "+61",
			    "isoCode": "AU",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/au.svg"
			},
			{
			    "name": "Austria",
			    "dialCode": "+43",
			    "isoCode": "AT",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/at.svg"
			},
			{
			    "name": "Azerbaijan",
			    "dialCode": "+994",
			    "isoCode": "AZ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/az.svg"
			},
			{
			    "name": "Bahamas",
			    "dialCode": "+1242",
			    "isoCode": "BS",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bs.svg"
			},
			{
			    "name": "Bahrain",
			    "dialCode": "+973",
			    "isoCode": "BH",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bh.svg"
			},
			{
			    "name": "Bangladesh",
			    "dialCode": "+880",
			    "isoCode": "BD",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bd.svg"
			},
			{
			    "name": "Barbados",
			    "dialCode": "+1246",
			    "isoCode": "BB",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bb.svg"
			},
			{
			    "name": "Belarus",
			    "dialCode": "+375",
			    "isoCode": "BY",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/by.svg"
			},
			{
			    "name": "Belgium",
			    "dialCode": "+32",
			    "isoCode": "BE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/be.svg"
			},
			{
			    "name": "Belize",
			    "dialCode": "+501",
			    "isoCode": "BZ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bz.svg"
			},
			{
			    "name": "Benin",
			    "dialCode": "+229",
			    "isoCode": "BJ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bj.svg"
			},
			{
			    "name": "Bermuda",
			    "dialCode": "+1441",
			    "isoCode": "BM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bm.svg"
			},
			{
			    "name": "Bhutan",
			    "dialCode": "+975",
			    "isoCode": "BT",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bt.svg"
			},
			{
			    "name": "Bolivia",
			    "dialCode": "+591",
			    "isoCode": "BO",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bo.svg"
			},
			{
			    "name": "Bosnia and Herzegovina",
			    "dialCode": "+387",
			    "isoCode": "BA",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ba.svg"
			},
			{
			    "name": "Botswana",
			    "dialCode": "+267",
			    "isoCode": "BW",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bw.svg"
			},
			{
			    "name": "Brazil",
			    "dialCode": "+55",
			    "isoCode": "BR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/br.svg"
			},
			{
			    "name": "British Indian Ocean Territory",
			    "dialCode": "+246",
			    "isoCode": "IO",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/io.svg"
			},
			{
			    "name": "Brunei Darussalam",
			    "dialCode": "+673",
			    "isoCode": "BN",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bn.svg"
			},
			{
			    "name": "Bulgaria",
			    "dialCode": "+359",
			    "isoCode": "BG",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bg.svg"
			},
			{
			    "name": "Burkina Faso",
			    "dialCode": "+226",
			    "isoCode": "BF",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bf.svg"
			},
			{
			    "name": "Burundi",
			    "dialCode": "+257",
			    "isoCode": "BI",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bi.svg"
			},
			{
			    "name": "Cambodia",
			    "dialCode": "+855",
			    "isoCode": "KH",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kh.svg"
			},
			{
			    "name": "Cameroon",
			    "dialCode": "+237",
			    "isoCode": "CM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cm.svg"
			},
			{
			    "name": "Canada",
			    "dialCode": "+1",
			    "isoCode": "CA",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ca.svg"
			},
			{
			    "name": "Cape Verde",
			    "dialCode": "+238",
			    "isoCode": "CV",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cv.svg"
			},
			{
			    "name": "Cayman Islands",
			    "dialCode": "+1345",
			    "isoCode": "KY",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ky.svg"
			},
			{
			    "name": "Central African Republic",
			    "dialCode": "+236",
			    "isoCode": "CF",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cf.svg"
			},
			{
			    "name": "Chad",
			    "dialCode": "+235",
			    "isoCode": "TD",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/td.svg"
			},
			{
			    "name": "Chile",
			    "dialCode": "+56",
			    "isoCode": "CL",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cl.svg"
			},
			{
			    "name": "China",
			    "dialCode": "+86",
			    "isoCode": "CN",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cn.svg"
			},
			{
			    "name": "Christmas Island",
			    "dialCode": "+61",
			    "isoCode": "CX",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cx.svg"
			},
			{
			    "name": "Cocos (Keeling) Islands",
			    "dialCode": "+61",
			    "isoCode": "CC",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cc.svg"
			},
			{
			    "name": "Colombia",
			    "dialCode": "+57",
			    "isoCode": "CO",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/co.svg"
			},
			{
			    "name": "Comoros",
			    "dialCode": "+269",
			    "isoCode": "KM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/km.svg"
			},
			{
			    "name": "Congo",
			    "dialCode": "+242",
			    "isoCode": "CG",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cg.svg"
			},
			{
			    "name": "Cook Islands",
			    "dialCode": "+682",
			    "isoCode": "CK",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ck.svg"
			},
			{
			    "name": "Costa Rica",
			    "dialCode": "+506",
			    "isoCode": "CR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cr.svg"
			},
			{
			    "name": "Croatia",
			    "dialCode": "+385",
			    "isoCode": "HR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/hr.svg"
			},
			{
			    "name": "Cuba",
			    "dialCode": "+53",
			    "isoCode": "CU",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cu.svg"
			},
			{
			    "name": "Cyprus",
			    "dialCode": "+357",
			    "isoCode": "CY",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cy.svg"
			},
			{
			    "name": "Czech Republic",
			    "dialCode": "+420",
			    "isoCode": "CZ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cz.svg"
			},
			{
			    "name": "Democratic Republic of the Congo",
			    "dialCode": "+243",
			    "isoCode": "CD",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/cd.svg"
			},
			{
			    "name": "Denmark",
			    "dialCode": "+45",
			    "isoCode": "DK",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/dk.svg"
			},
			{
			    "name": "Djibouti",
			    "dialCode": "+253",
			    "isoCode": "DJ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/dj.svg"
			},
			{
			    "name": "Dominica",
			    "dialCode": "+1767",
			    "isoCode": "DM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/dm.svg"
			},
			{
			    "name": "Dominican Republic",
			    "dialCode": "+1849",
			    "isoCode": "DO",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/do.svg"
			},
			{
			    "name": "Ecuador",
			    "dialCode": "+593",
			    "isoCode": "EC",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ec.svg"
			},
			{
			    "name": "Egypt",
			    "dialCode": "+20",
			    "isoCode": "EG",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/eg.svg"
			},
			{
			    "name": "El Salvador",
			    "dialCode": "+503",
			    "isoCode": "SV",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sv.svg"
			},
			{
			    "name": "Equatorial Guinea",
			    "dialCode": "+240",
			    "isoCode": "GQ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gq.svg"
			},
			{
			    "name": "Eritrea",
			    "dialCode": "+291",
			    "isoCode": "ER",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/er.svg"
			},
			{
			    "name": "Estonia",
			    "dialCode": "+372",
			    "isoCode": "EE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ee.svg"
			},
			{
			    "name": "Eswatini",
			    "dialCode": "+268",
			    "isoCode": "SZ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sz.svg"
			},
			{
			    "name": "Ethiopia",
			    "dialCode": "+251",
			    "isoCode": "ET",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/et.svg"
			},
			{
			    "name": "Falkland Islands (Malvinas)",
			    "dialCode": "+500",
			    "isoCode": "FK",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fk.svg"
			},
			{
			    "name": "Faroe Islands",
			    "dialCode": "+298",
			    "isoCode": "FO",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fo.svg"
			},
			{
			    "name": "Fiji",
			    "dialCode": "+679",
			    "isoCode": "FJ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fj.svg"
			},
			{
			    "name": "Finland",
			    "dialCode": "+358",
			    "isoCode": "FI",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fi.svg"
			},
			{
			    "name": "France",
			    "dialCode": "+33",
			    "isoCode": "FR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fr.svg"
			},
			{
			    "name": "French Guiana",
			    "dialCode": "+594",
			    "isoCode": "GF",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gf.svg"
			},
			{
			    "name": "French Polynesia",
			    "dialCode": "+689",
			    "isoCode": "PF",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pf.svg"
			},
			{
			    "name": "Gabon",
			    "dialCode": "+241",
			    "isoCode": "GA",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ga.svg"
			},
			{
			    "name": "Gambia",
			    "dialCode": "+220",
			    "isoCode": "GM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gm.svg"
			},
			{
			    "name": "Georgia",
			    "dialCode": "+995",
			    "isoCode": "GE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ge.svg"
			},
			{
			    "name": "Germany",
			    "dialCode": "+49",
			    "isoCode": "DE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/de.svg"
			},
			{
			    "name": "Ghana",
			    "dialCode": "+233",
			    "isoCode": "GH",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gh.svg"
			},
			{
			    "name": "Gibraltar",
			    "dialCode": "+350",
			    "isoCode": "GI",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gi.svg"
			},
			{
			    "name": "Greece",
			    "dialCode": "+30",
			    "isoCode": "GR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gr.svg"
			},
			{
			    "name": "Greenland",
			    "dialCode": "+299",
			    "isoCode": "GL",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gl.svg"
			},
			{
			    "name": "Grenada",
			    "dialCode": "+1473",
			    "isoCode": "GD",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gd.svg"
			},
			{
			    "name": "Guadeloupe",
			    "dialCode": "+590",
			    "isoCode": "GP",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gp.svg"
			},
			{
			    "name": "Guam",
			    "dialCode": "+1671",
			    "isoCode": "GU",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gu.svg"
			},
			{
			    "name": "Guatemala",
			    "dialCode": "+502",
			    "isoCode": "GT",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gt.svg"
			},
			{
			    "name": "Guernsey",
			    "dialCode": "+44-1481",
			    "isoCode": "GG",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gg.svg"
			},
			{
			    "name": "Guinea",
			    "dialCode": "+224",
			    "isoCode": "GN",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gn.svg"
			},
			{
			    "name": "Guinea-Bissau",
			    "dialCode": "+245",
			    "isoCode": "GW",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gw.svg"
			},
			{
			    "name": "Guyana",
			    "dialCode": "+592",
			    "isoCode": "GY",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gy.svg"
			},
			{
			    "name": "Haiti",
			    "dialCode": "+509",
			    "isoCode": "HT",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ht.svg"
			},
			{
			    "name": "Holy See (Vatican City State)",
			    "dialCode": "+379",
			    "isoCode": "VA",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/va.svg"
			},
			{
			    "name": "Honduras",
			    "dialCode": "+504",
			    "isoCode": "HN",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/hn.svg"
			},
			{
			    "name": "Hong Kong",
			    "dialCode": "+852",
			    "isoCode": "HK",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/hk.svg"
			},
			{
			    "name": "Hungary",
			    "dialCode": "+36",
			    "isoCode": "HU",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/hu.svg"
			},
			{
			    "name": "Iceland",
			    "dialCode": "+354",
			    "isoCode": "IS",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/is.svg"
			},
			{
			    "name": "India",
			    "dialCode": "+91",
			    "isoCode": "IN",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/in.svg"
			},
			{
			    "name": "Indonesia",
			    "dialCode": "+62",
			    "isoCode": "ID",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/id.svg"
			},
			{
			    "name": "Iran",
			    "dialCode": "+98",
			    "isoCode": "IR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ir.svg"
			},
			{
			    "name": "Iraq",
			    "dialCode": "+964",
			    "isoCode": "IQ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/iq.svg"
			},
			{
			    "name": "Ireland",
			    "dialCode": "+353",
			    "isoCode": "IE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ie.svg"
			},
			{
			    "name": "Isle of Man",
			    "dialCode": "+44-1624",
			    "isoCode": "IM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/im.svg"
			},
			{
			    "name": "Israel",
			    "dialCode": "+972",
			    "isoCode": "IL",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/il.svg"
			},
			{
			    "name": "Italy",
			    "dialCode": "+39",
			    "isoCode": "IT",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/it.svg"
			},
			{
			    "name": "Ivory Coast / Cote d'Ivoire",
			    "dialCode": "+225",
			    "isoCode": "CI",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ci.svg"
			},
			{
			    "name": "Jamaica",
			    "dialCode": "+1876",
			    "isoCode": "JM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/jm.svg"
			},
			{
			    "name": "Japan",
			    "dialCode": "+81",
			    "isoCode": "JP",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/jp.svg"
			},
			{
			    "name": "Jersey",
			    "dialCode": "+44-1534",
			    "isoCode": "JE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/je.svg"
			},
			{
			    "name": "Jordan",
			    "dialCode": "+962",
			    "isoCode": "JO",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/jo.svg"
			},
			{
			    "name": "Kazakhstan",
			    "dialCode": "+77",
			    "isoCode": "KZ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kz.svg"
			},
			{
			    "name": "Kenya",
			    "dialCode": "+254",
			    "isoCode": "KE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ke.svg"
			},
			{
			    "name": "Kiribati",
			    "dialCode": "+686",
			    "isoCode": "KI",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ki.svg"
			},
			{
			    "name": "Korea, Democratic People's Republic of Korea",
			    "dialCode": "+850",
			    "isoCode": "KP",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kp.svg"
			},
			{
			    "name": "Korea, Republic of South Korea",
			    "dialCode": "+82",
			    "isoCode": "KR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kr.svg"
			},
			{
			    "name": "Kosovo",
			    "dialCode": "+383",
			    "isoCode": "XK",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/xk.svg"
			},
			{
			    "name": "Kuwait",
			    "dialCode": "+965",
			    "isoCode": "KW",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kw.svg"
			},
			{
			    "name": "Kyrgyzstan",
			    "dialCode": "+996",
			    "isoCode": "KG",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kg.svg"
			},
			{
			    "name": "Laos",
			    "dialCode": "+856",
			    "isoCode": "LA",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/la.svg"
			},
			{
			    "name": "Latvia",
			    "dialCode": "+371",
			    "isoCode": "LV",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lv.svg"
			},
			{
			    "name": "Lebanon",
			    "dialCode": "+961",
			    "isoCode": "LB",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lb.svg"
			},
			{
			    "name": "Lesotho",
			    "dialCode": "+266",
			    "isoCode": "LS",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ls.svg"
			},
			{
			    "name": "Liberia",
			    "dialCode": "+231",
			    "isoCode": "LR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lr.svg"
			},
			{
			    "name": "Libya",
			    "dialCode": "+218",
			    "isoCode": "LY",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ly.svg"
			},
			{
			    "name": "Liechtenstein",
			    "dialCode": "+423",
			    "isoCode": "LI",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/li.svg"
			},
			{
			    "name": "Lithuania",
			    "dialCode": "+370",
			    "isoCode": "LT",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lt.svg"
			},
			{
			    "name": "Luxembourg",
			    "dialCode": "+352",
			    "isoCode": "LU",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lu.svg"
			},
			{
			    "name": "Macau",
			    "dialCode": "+853",
			    "isoCode": "MO",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mo.svg"
			},
			{
			    "name": "Madagascar",
			    "dialCode": "+261",
			    "isoCode": "MG",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mg.svg"
			},
			{
			    "name": "Malawi",
			    "dialCode": "+265",
			    "isoCode": "MW",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mw.svg"
			},
			{
			    "name": "Malaysia",
			    "dialCode": "+60",
			    "isoCode": "MY",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/my.svg"
			},
			{
			    "name": "Maldives",
			    "dialCode": "+960",
			    "isoCode": "MV",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mv.svg"
			},
			{
			    "name": "Mali",
			    "dialCode": "+223",
			    "isoCode": "ML",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ml.svg"
			},
			{
			    "name": "Malta",
			    "dialCode": "+356",
			    "isoCode": "MT",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mt.svg"
			},
			{
			    "name": "Marshall Islands",
			    "dialCode": "+692",
			    "isoCode": "MH",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mh.svg"
			},
			{
			    "name": "Martinique",
			    "dialCode": "+596",
			    "isoCode": "MQ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mq.svg"
			},
			{
			    "name": "Mauritania",
			    "dialCode": "+222",
			    "isoCode": "MR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mr.svg"
			},
			{
			    "name": "Mauritius",
			    "dialCode": "+230",
			    "isoCode": "MU",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mu.svg"
			},
			{
			    "name": "Mayotte",
			    "dialCode": "+262",
			    "isoCode": "YT",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/yt.svg"
			},
			{
			    "name": "Mexico",
			    "dialCode": "+52",
			    "isoCode": "MX",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mx.svg"
			},
			{
			    "name": "Micronesia, Federated States of Micronesia",
			    "dialCode": "+691",
			    "isoCode": "FM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/fm.svg"
			},
			{
			    "name": "Moldova",
			    "dialCode": "+373",
			    "isoCode": "MD",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/md.svg"
			},
			{
			    "name": "Monaco",
			    "dialCode": "+377",
			    "isoCode": "MC",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mc.svg"
			},
			{
			    "name": "Mongolia",
			    "dialCode": "+976",
			    "isoCode": "MN",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mn.svg"
			},
			{
			    "name": "Montenegro",
			    "dialCode": "+382",
			    "isoCode": "ME",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/me.svg"
			},
			{
			    "name": "Montserrat",
			    "dialCode": "+1664",
			    "isoCode": "MS",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ms.svg"
			},
			{
			    "name": "Morocco",
			    "dialCode": "+212",
			    "isoCode": "MA",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ma.svg"
			},
			{
			    "name": "Mozambique",
			    "dialCode": "+258",
			    "isoCode": "MZ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mz.svg"
			},
			{
			    "name": "Myanmar",
			    "dialCode": "+95",
			    "isoCode": "MM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mm.svg"
			},
			{
			    "name": "Namibia",
			    "dialCode": "+264",
			    "isoCode": "NA",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/na.svg"
			},
			{
			    "name": "Nauru",
			    "dialCode": "+674",
			    "isoCode": "NR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nr.svg"
			},
			{
			    "name": "Nepal",
			    "dialCode": "+977",
			    "isoCode": "NP",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/np.svg"
			},
			{
			    "name": "Netherlands",
			    "dialCode": "+31",
			    "isoCode": "NL",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nl.svg"
			},
			{
			    "name": "Netherlands Antilles",
			    "dialCode": "+599",
			    "isoCode": "AN",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/an.svg"
			},
			{
			    "name": "New Caledonia",
			    "dialCode": "+687",
			    "isoCode": "NC",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nc.svg"
			},
			{
			    "name": "New Zealand",
			    "dialCode": "+64",
			    "isoCode": "NZ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nz.svg"
			},
			{
			    "name": "Nicaragua",
			    "dialCode": "+505",
			    "isoCode": "NI",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ni.svg"
			},
			{
			    "name": "Niger",
			    "dialCode": "+227",
			    "isoCode": "NE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ne.svg"
			},
			{
			    "name": "Nigeria",
			    "dialCode": "+234",
			    "isoCode": "NG",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ng.svg"
			},
			{
			    "name": "Niue",
			    "dialCode": "+683",
			    "isoCode": "NU",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nu.svg"
			},
			{
			    "name": "Norfolk Island",
			    "dialCode": "+672",
			    "isoCode": "NF",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/nf.svg"
			},
			{
			    "name": "North Macedonia",
			    "dialCode": "+389",
			    "isoCode": "MK",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mk.svg"
			},
			{
			    "name": "Northern Mariana Islands",
			    "dialCode": "+1670",
			    "isoCode": "MP",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mp.svg"
			},
			{
			    "name": "Norway",
			    "dialCode": "+47",
			    "isoCode": "NO",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/no.svg"
			},
			{
			    "name": "Oman",
			    "dialCode": "+968",
			    "isoCode": "OM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/om.svg"
			},
			{
			    "name": "Pakistan",
			    "dialCode": "+92",
			    "isoCode": "PK",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pk.svg"
			},
			{
			    "name": "Palau",
			    "dialCode": "+680",
			    "isoCode": "PW",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pw.svg"
			},
			{
			    "name": "Palestine",
			    "dialCode": "+970",
			    "isoCode": "PS",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ps.svg"
			},
			{
			    "name": "Panama",
			    "dialCode": "+507",
			    "isoCode": "PA",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pa.svg"
			},
			{
			    "name": "Papua New Guinea",
			    "dialCode": "+675",
			    "isoCode": "PG",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pg.svg"
			},
			{
			    "name": "Paraguay",
			    "dialCode": "+595",
			    "isoCode": "PY",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/py.svg"
			},
			{
			    "name": "Peru",
			    "dialCode": "+51",
			    "isoCode": "PE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pe.svg"
			},
			{
			    "name": "Philippines",
			    "dialCode": "+63",
			    "isoCode": "PH",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ph.svg"
			},
			{
			    "name": "Pitcairn",
			    "dialCode": "+872",
			    "isoCode": "PN",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pn.svg"
			},
			{
			    "name": "Poland",
			    "dialCode": "+48",
			    "isoCode": "PL",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pl.svg"
			},
			{
			    "name": "Portugal",
			    "dialCode": "+351",
			    "isoCode": "PT",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pt.svg"
			},
			{
			    "name": "Puerto Rico",
			    "dialCode": "+1939",
			    "isoCode": "PR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pr.svg"
			},
			{
			    "name": "Qatar",
			    "dialCode": "+974",
			    "isoCode": "QA",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/qa.svg"
			},
			{
			    "name": "Reunion",
			    "dialCode": "+262",
			    "isoCode": "RE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/re.svg"
			},
			{
			    "name": "Romania",
			    "dialCode": "+40",
			    "isoCode": "RO",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ro.svg"
			},
			{
			    "name": "Russia",
			    "dialCode": "+7",
			    "isoCode": "RU",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ru.svg"
			},
			{
			    "name": "Rwanda",
			    "dialCode": "+250",
			    "isoCode": "RW",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/rw.svg"
			},
			{
			    "name": "Saint Barthelemy",
			    "dialCode": "+590",
			    "isoCode": "BL",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/bl.svg"
			},
			{
			    "name": "Saint Helena, Ascension and Tristan Da Cunha",
			    "dialCode": "+290",
			    "isoCode": "SH",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sh.svg"
			},
			{
			    "name": "Saint Kitts and Nevis",
			    "dialCode": "+1869",
			    "isoCode": "KN",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/kn.svg"
			},
			{
			    "name": "Saint Lucia",
			    "dialCode": "+1758",
			    "isoCode": "LC",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lc.svg"
			},
			{
			    "name": "Saint Martin",
			    "dialCode": "+590",
			    "isoCode": "MF",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/mf.svg"
			},
			{
			    "name": "Saint Pierre and Miquelon",
			    "dialCode": "+508",
			    "isoCode": "PM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/pm.svg"
			},
			{
			    "name": "Saint Vincent and the Grenadines",
			    "dialCode": "+1784",
			    "isoCode": "VC",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/vc.svg"
			},
			{
			    "name": "Samoa",
			    "dialCode": "+685",
			    "isoCode": "WS",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ws.svg"
			},
			{
			    "name": "San Marino",
			    "dialCode": "+378",
			    "isoCode": "SM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sm.svg"
			},
			{
			    "name": "Sao Tome and Principe",
			    "dialCode": "+239",
			    "isoCode": "ST",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/st.svg"
			},
			{
			    "name": "Saudi Arabia",
			    "dialCode": "+966",
			    "isoCode": "SA",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sa.svg"
			},
			{
			    "name": "Senegal",
			    "dialCode": "+221",
			    "isoCode": "SN",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sn.svg"
			},
			{
			    "name": "Serbia",
			    "dialCode": "+381",
			    "isoCode": "RS",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/rs.svg"
			},
			{
			    "name": "Seychelles",
			    "dialCode": "+248",
			    "isoCode": "SC",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sc.svg"
			},
			{
			    "name": "Sierra Leone",
			    "dialCode": "+232",
			    "isoCode": "SL",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sl.svg"
			},
			{
			    "name": "Singapore",
			    "dialCode": "+65",
			    "isoCode": "SG",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sg.svg"
			},
			{
			    "name": "Sint Maarten",
			    "dialCode": "+1721",
			    "isoCode": "SX",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sx.svg"
			},
			{
			    "name": "Slovakia",
			    "dialCode": "+421",
			    "isoCode": "SK",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sk.svg"
			},
			{
			    "name": "Slovenia",
			    "dialCode": "+386",
			    "isoCode": "SI",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/si.svg"
			},
			{
			    "name": "Solomon Islands",
			    "dialCode": "+677",
			    "isoCode": "SB",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sb.svg"
			},
			{
			    "name": "Somalia",
			    "dialCode": "+252",
			    "isoCode": "SO",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/so.svg"
			},
			{
			    "name": "South Africa",
			    "dialCode": "+27",
			    "isoCode": "ZA",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/za.svg"
			},
			{
			    "name": "South Georgia and the South Sandwich Islands",
			    "dialCode": "+500",
			    "isoCode": "GS",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gs.svg"
			},
			{
			    "name": "South Sudan",
			    "dialCode": "+211",
			    "isoCode": "SS",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ss.svg"
			},
			{
			    "name": "Spain",
			    "dialCode": "+34",
			    "isoCode": "ES",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/es.svg"
			},
			{
			    "name": "Sri Lanka",
			    "dialCode": "+94",
			    "isoCode": "LK",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/lk.svg"
			},
			{
			    "name": "Sudan",
			    "dialCode": "+249",
			    "isoCode": "SD",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sd.svg"
			},
			{
			    "name": "Suriname",
			    "dialCode": "+597",
			    "isoCode": "SR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sr.svg"
			},
			{
			    "name": "Svalbard and Jan Mayen",
			    "dialCode": "+47",
			    "isoCode": "SJ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sj.svg"
			},
			{
			    "name": "Sweden",
			    "dialCode": "+46",
			    "isoCode": "SE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/se.svg"
			},
			{
			    "name": "Switzerland",
			    "dialCode": "+41",
			    "isoCode": "CH",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ch.svg"
			},
			{
			    "name": "Syrian Arab Republic",
			    "dialCode": "+963",
			    "isoCode": "SY",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/sy.svg"
			},
			{
			    "name": "Taiwan",
			    "dialCode": "+886",
			    "isoCode": "TW",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tw.svg"
			},
			{
			    "name": "Tajikistan",
			    "dialCode": "+992",
			    "isoCode": "TJ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tj.svg"
			},
			{
			    "name": "Tanzania, United Republic of Tanzania",
			    "dialCode": "+255",
			    "isoCode": "TZ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tz.svg"
			},
			{
			    "name": "Thailand",
			    "dialCode": "+66",
			    "isoCode": "TH",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/th.svg"
			},
			{
			    "name": "Timor-Leste",
			    "dialCode": "+670",
			    "isoCode": "TL",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tl.svg"
			},
			{
			    "name": "Togo",
			    "dialCode": "+228",
			    "isoCode": "TG",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tg.svg"
			},
			{
			    "name": "Tokelau",
			    "dialCode": "+690",
			    "isoCode": "TK",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tk.svg"
			},
			{
			    "name": "Tonga",
			    "dialCode": "+676",
			    "isoCode": "TO",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/to.svg"
			},
			{
			    "name": "Trinidad and Tobago",
			    "dialCode": "+1868",
			    "isoCode": "TT",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tt.svg"
			},
			{
			    "name": "Tunisia",
			    "dialCode": "+216",
			    "isoCode": "TN",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tn.svg"
			},
			{
			    "name": "Turkey",
			    "dialCode": "+90",
			    "isoCode": "TR",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tr.svg"
			},
			{
			    "name": "Turkmenistan",
			    "dialCode": "+993",
			    "isoCode": "TM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tm.svg"
			},
			{
			    "name": "Turks and Caicos Islands",
			    "dialCode": "+1649",
			    "isoCode": "TC",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tc.svg"
			},
			{
			    "name": "Tuvalu",
			    "dialCode": "+688",
			    "isoCode": "TV",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/tv.svg"
			},
			{
			    "name": "Uganda",
			    "dialCode": "+256",
			    "isoCode": "UG",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ug.svg"
			},
			{
			    "name": "Ukraine",
			    "dialCode": "+380",
			    "isoCode": "UA",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ua.svg"
			},
			{
			    "name": "United Arab Emirates",
			    "dialCode": "+971",
			    "isoCode": "AE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ae.svg"
			},
			{
			    "name": "United Kingdom",
			    "dialCode": "+44",
			    "isoCode": "GB",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/gb.svg"
			},
			{
			    "name": "United States",
			    "dialCode": "+1",
			    "isoCode": "US",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/us.svg"
			},
			{
			    "name": "United States Minor Outlying Islands",
			    "dialCode": "+246",
			    "isoCode": "UMI",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/umi.svg"
			},
			{
			    "name": "Uruguay",
			    "dialCode": "+598",
			    "isoCode": "UY",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/uy.svg"
			},
			{
			    "name": "Uzbekistan",
			    "dialCode": "+998",
			    "isoCode": "UZ",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/uz.svg"
			},
			{
			    "name": "Vanuatu",
			    "dialCode": "+678",
			    "isoCode": "VU",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/vu.svg"
			},
			{
			    "name": "Venezuela, Bolivarian Republic of Venezuela",
			    "dialCode": "+58",
			    "isoCode": "VE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ve.svg"
			},
			{
			    "name": "Vietnam",
			    "dialCode": "+84",
			    "isoCode": "VN",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/vn.svg"
			},
			{
			    "name": "Virgin Islands, British",
			    "dialCode": "+1284",
			    "isoCode": "VG",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/vg.svg"
			},
			{
			    "name": "Virgin Islands, U.S.",
			    "dialCode": "+1340",
			    "isoCode": "VI",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/vi.svg"
			},
			{
			    "name": "Wallis and Futuna",
			    "dialCode": "+681",
			    "isoCode": "WF",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/wf.svg"
			},
			{
			    "name": "Yemen",
			    "dialCode": "+967",
			    "isoCode": "YE",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/ye.svg"
			},
			{
			    "name": "Zambia",
			    "dialCode": "+260",
			    "isoCode": "ZM",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/zm.svg"
			},
			{
			    "name": "Zimbabwe",
			    "dialCode": "+263",
			    "isoCode": "ZW",
			    "flag": "https://cdn.kcak11.com/CountryFlags/countries/zw.svg"
			}
	];

	return {
		SalaryPeriod,
		SalaryPeriodBonus,
		SalaryPeriodShort,
		SalaryFormat,
		SalaryType,
		SalaryTypeSimple,
		SalaryCurrency,
		SalaryPeriodDistance,

		InstructorCategory,
		InstructorTheory,

		OfferKind,
		OfferAgreement,
		OfferType,
		OfferTime,
		OfferSystem,
		OfferSchedule,
		OfferScheduleGroup,
		OfferTeam,

		labelOfferAgreement,
		labelOfferType,
		labelOfferKind,
		labelSalaryPeriod,
		labelSalaryPeriodDistance,
		labelCitizenship,
		labelLanguage,
		labelRequirementsLanguage,
		labelDocumentType,
		labelRequirementsExperience,
		labelSalaryPeriodShort,

		TransportType,
		TransportDirection,

		RequirementsLanguage,
		RequirementsExperience,

		AccommodationBed,
		AccommodationPeriod,
		AccommodationType,
		AccommodationPerson,
		AccommodationFacilities,
		citizenship,
		languagesList,

		DriverLicence,

		Months,
		Years,

		LibraryGet,

		StatesGet,
		CitiesGet,

		digitCountry,
		documentType
	}
}
