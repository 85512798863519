<template>
     <div v-if="isAdmin">
          <ui-button label="Edytuj meta informacje" @click="display = true" style="position:fixed; bottom:1rem; left:1rem;"></ui-button>
          <Dialog header="Ustawienia nagłówka" modal v-model:visible="display" v-bind="$attrs" :style="{'width': '600px', 'max-width': '100%'}">

               <div style="width:600px; max-width:100%">
                    <div class="mb-20 d-flex">
                         <UiTextFlag v-for="locale in localeTranslation" :key="locale.code" :flag="locale.code" @click="setActive(locale.code)" :style="{opacity: locale.code === lang ? 1 : .3, cursor: 'pointer'}"></UiTextFlag>
                    </div>

                    <input-text v-model="form.title" label="Tytuł strony (title)">
                         <template #label>
                              <div class="d-flex justify-content-between w-100">
                                   <span>Title (tytuł strony)</span>
                                   <span>Liczba znaków: {{ form.title.length }}</span>
                              </div>
                         </template>
                    </input-text>
                    <span class="text-muted fs-12 mt--10 mb-20 d-block">Rekomendowana liczba znakow 50-60</span>
                    <input-textarea label="Opis strony (description)" v-model="form.description">
                         <template #label>
                              <div class="d-flex justify-content-between w-100">
                                   <span>Description (opis strony)</span>
                                   <span>Liczba znaków: {{ form.description.length }}</span>
                              </div>
                         </template>
                    </input-textarea>
                    <span class="text-muted fs-12 mt--10 mb-20 d-block">Opisy są ograniczone do około 155 znaków w przypadku wyszukiwania na komputerze i 120 znaków w przypadku wyszukiwania na urządzeniach mobilnych.</span>
                    <input-textarea label="Słowa kluczowe (description)" v-model="form.keywords"></input-textarea>
                    <input-text label="Link kanoniczny (canonical)" v-model="form.canonical"></input-text>
                    <input-text label="Ustawienia dla robotów (robots)" />
               </div>

               <div style="width:600px; max-width:100%;" v-if="form.title || form.description">
                    <span class="d-block fs-12 mb-10">Podgląd wyniku w wyszukiwarce Google:</span>
                    <div style="border:1px solid #eee; padding:20px; border-radius:6px; box-shadow:0 0 12px rgba(0,0,0,.1)">
                         <div style="color:rgb(32,33,36); font-size: 14px; font-family: Arial, Helvetica, sans-serif;">{{ url }}</div>
                         <div style="color:rgb(26,13,171); font-size: 20px; font-family: Arial, Helvetica, sans-serif; text-overflow: ellipsis; width:520px; overflow: hidden; white-space: nowrap;  height: 30px; margin:3px 0;">{{ form.title }}</div>
                         <div style="color:rgb(95,99,104); font-size: 14px; font-family: Arial, Helvetica, sans-serif;">{{ form.description }}</div>
                    </div>
               </div>
          
               <template #footer>
                    <ui-button label="Anuluj" icon="pi pi-times" @click="display = false" class="p-button-text"/>
                    <ui-button label="Zapisz" icon="pi pi-check" @click="save" />
               </template>
          </Dialog>
     </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import Dialog from 'primevue/dialog';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useTranslation } from "@/composables/useTranslation";
import { useUserStore } from "../../stores/useUserStore";
import { storeToRefs } from 'pinia';

const user_store = useUserStore();
const { user, isAdmin, isModerator } = storeToRefs(user_store);
const display = ref(false);
const router = useRouter();
const { onLoadLanguagesList } = useTranslation();
const localeTranslation = ref([]);
const uRoute = useRoute();

const form = ref({
     path: '',
     route: '',
     title: '',
     description: '',
     keywords: '',
     canonical: '',
     robots: ''
});

const url = computed(() => {
     return `${document.location.protocol}//${document.location.host}${router.currentRoute.value.fullPath}`;
});

watch(() => display.value, async () => {
     if (display.value) {
          if (isAdmin) {
               let route = router.currentRoute.value.name;
               let params = JSON.stringify(uRoute.params);
               params = params.toString();
               const request = await axios.get(`meta?default=false&route=${route}&params=${params}`);
               const { data } = request;
               form.value = data;
               form.value.route = route;
          }
     }
});

onMounted(async() => {
	localeTranslation.value = await onLoadLanguagesList();
});

const save = async () => {
     try {
          form.value.params = uRoute.params;
          await axios.put('admin/setting/meta', form.value);
          display.value = false;
     } catch(e) {
          console.error(e);
     }
}

const setActive = (key) => {
     lang.value = key;

     if (form.value[lang.value] === undefined) {
          form.value[lang.value] = { title: '',  description: '' }
     }
}

</script>