<template>
    <Component v-if="checkStatus()" :is="tag">
        <slot />
    </Component >
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useUserStore } from '../../../stores/useUserStore';
import { storeToRefs } from 'pinia';

const userStore = useUserStore();
const { isAdmin, isModerator, isCompany, isEmployee } = storeToRefs(userStore);

const userPermission = ref({
    admin: false,
    moderator: false,
    company: false,
    employee: false
});

const props = defineProps({
    gate: {
        type: Array,
        default: () => []
    },
    tag: {
        type: String,
        default: 'div'
    }
})

onMounted(() => {
    userPermission.value = {
        admin: isAdmin,
        moderator: isModerator,
        company: isCompany,
        employee: isEmployee
    }
});

const checkStatus = () => {
    const userObj = userPermission.value;
    const rolesArray = props.gate;

    for (const role of rolesArray) {
        if (userObj.hasOwnProperty(role) && userObj[role] === true) {
            return true;
        }
    }
    return false;
}

</script>