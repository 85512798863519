<template>
	<router-link v-if="to" :class="{'btn-icon': svg}" :to="to">
		<InlineSvg v-if="svg" :src="svg" height="20" />
		<slot />
	</router-link>
	<button v-bind="$attrs"  :class="{'btn-icon': svg}" v-else>
		<InlineSvg :src="svg" height="20" />
		<slot />
	</button>
</template>

<script>
	export default {
		name: "UiLink",
		props: {
			svg: String,
			to: [String, Object]
		},
		setup() {
		
		}
	}
</script>