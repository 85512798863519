<template>
	<main class="admin__area">
		<div class="content__main">
			<div class="content__main__ps">
				<div class="content__main__body" :style="`min-height:${size}px`">
					<div class="py-30">
						<router-view />
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
	import { onMounted, ref, computed, watch } from "vue";
	import { useWindowScroll } from '@vueuse/core'
	import { useWindowSize } from "@vueuse/core";

	export default {
		props: {
			button: String
		},
		setup() {
			const { y } = useWindowScroll();
			const size = ref(0);
			const { height } = useWindowSize();

			onMounted(() => {
				let footer = document.getElementById('footer');
				let title = document.getElementById('title');
				let header = document.getElementById('header');
				
				size.value = height.value - (footer.offsetHeight + title.offsetHeight + header.offsetHeight + 95);
			});
			
			const headerClass = computed(() => {
				return y.value > 5 ? true : false;
			})
			
			return { navigation, y, headerClass, size }
		}
	}
</script>