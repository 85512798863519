<template>
	<div class="input__field" :class="`${error ? 'input__field__error' : ''} input__field__${type.toLowerCase()} ${icon ? 'input__field__icon' : ''}`">
		<span class="p-float-label">
			<slot />
               <label for="inputtext"><Text :i="label" /></label>
		</span>

		<div v-if="error" :class="{'ml-40' : error && icon}">
			<InputError :error="error" />
		</div>
	</div>
</template>

<script>
	import {ref} from "vue";
	
	export default {
		props: {
			label: String,
			icon: String,
			error: [Object, String],
			help: {
				type: Boolean,
				default: false
			},
			helpText: String,
			required: {
				type: Boolean,
				default: false
			},
		},
		setup(props, {slots}) {
			let type = ref(null);
			if (typeof(slots.default()[0].type) == 'object') {
				type.value = slots.default() == undefined ? 'default' : slots.default()[0].type.name; 
			}
			else {
				type.value = 'default';
			}
			let hasSlotLabel = ref(false);
			
			if (slots.label) {
				hasSlotLabel.value = true;
			}
			
			return { hasSlotLabel, type }
		}
	}
</script>