<template>
	<div class="ui__megamenu">
		<div class="container">
			<div class="py-20">
				<div class="row">
					<div v-for="menu in categories" :key="menu.id" class="col-md-3 py-20">
						<div class="ui__megamenu__col">
							<h3><Text :i="menu.name" /></h3>
							<ul>
								<li v-for="submenu in menu.children" :key="submenu.id">
									<router-link @click="onCloseMegamenu" :to="`/${locale}/${submenu.route}`">
										<Text :i="submenu.name" />
									</router-link>
								</li>
							</ul>
							<GateArea class="mt-5" :gate="['admin', 'moderator']">
								+ podkategorię
							</GateArea>
						</div>
					</div>
					<GateArea class="col-md-3 py-20" :gate="['admin', 'moderator']">
						<div class="ui__megamenu__col">
							+ kategoria
						</div>
					</GateArea>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useCategoryStore } from "@/stores/useCategoryStore";
import {storeToRefs} from "pinia";
import { useI18n } from "vue-i18n";

const categoryStore = useCategoryStore();
const { categories } = storeToRefs(categoryStore);
const { locale } = useI18n();

const emit = defineEmits(['update:show_megamenu']);

const onCloseMegamenu = () => {
	emit('update:show_megamenu', false);
}
</script>
