import { inject } from 'vue';

export const PromptSymbol = Symbol();

export function usePrompts() {
	const Prompts = inject(PromptSymbol);
	if (!Prompts) {
		throw new Error('Problem z Prompt');
	}

	return Prompts;
}