import { inject } from 'vue';

export const BreadcrumbSymbol = Symbol();

export function useBreadcrumb() {
	const Breasdcrumb = inject(BreadcrumbSymbol);
	if (!Breasdcrumb) {
		throw new Error('Problem ze sciezka');
	}

	return Breasdcrumb;
}