<template>
	<div class="d-flex mb-15">
		<PrimeRadioButton v-bind="$attrs" />
		<div class="ml-10" v-if="label"><Text :i="label" /></div>
		<div class="ml-10"><slot /></div>
	</div>
</template>

<script>
	export default {
		props: {
			label: String
		}
	}
</script>