<template>
	<div class="ui__prompt" v-if="show">
		<div class="ui__prompt__overlay" @click="hideListener"></div>
		<ul>
			<li v-for="(item, index) in items" @click="onClickEvent(item.command)" :key="index">{{ item.label }}</li>
		</ul>
	</div>
</template>

<script>
	import { ref, onMounted, onBeforeUnmount } from 'vue';
	import PromptEventBus from "../../bus/PromptsEventBus";
	
	export default  {
		setup() {
			const show = ref(false);
			const items = ref([]);
			const option = ref({});
			
			function onClickEvent(command) {
				if (command !== undefined) {
					return command();
				}
			}
			
			const promptsListener = (options) => {
				option.value = options;
				items.value = options.items;
				show.value = true;
			}
			
			const hideListener = () => {
				show.value = false;
			}
			
			onMounted(() => {
				PromptEventBus.on('prompts', promptsListener);
				PromptEventBus.on('hide', hideListener);
			});
			onBeforeUnmount(() => {
				PromptEventBus.off('confirm', promptsListener);
				PromptEventBus.off('hide', hideListener);
			});
			
			return { onClickEvent, hideListener, show, items };
		}
	}
</script>